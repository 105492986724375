import MetadataFieldValue from "interfaces/common/MetadataFieldValue";
import ReportScheduleResponse from "interfaces/response/ReportScheduleResponse";
import { ReportScheduleStatusEnum } from "shared/enums/ReportScheduleStatus";
import { Type } from "shared/enums/TypeEnum";

export function addBlankValue(metadataFieldValues: MetadataFieldValue[], metadataFieldId: number): MetadataFieldValue[] {
  const blankValue: MetadataFieldValue = {
    metadataFieldId: metadataFieldId,
    type: Type.Empty,
    stringValue: "-",
    id: 0
  };
  const newValues = [...metadataFieldValues];
  newValues.unshift(blankValue);
  return newValues
};

export const createEmptyReportSchedule = (): ReportScheduleResponse => {
  return {
    id: 0,
    reportScheduleTypeId: 0,
    title: "",
    description: "",
    internalId: "",
    status: ReportScheduleStatusEnum.Open,
    pointsInTimeType: 0,
    pointsInTimeTypeText: "",
    schedules: [],
    metadataFieldValues: [],
    activitiesCount: 0,
    activitiesCountBaseline: 0,
    totalWorkHours: 0,
    totalWorkHoursBaseline: 0,
    actualWorkHoursAtCutoffCumulative: 0,
    earnedWorkHoursAtCutoffCumulative: 0,
    isProcessing: false,
    lastLiveOperationStatus: 0,
    lastBaselineOperationStatus: 0,
    lastOriginalBaselineOperationStatus: 0,
    lastCurrentOperationStatus: 0
  };
}