import { createAsyncThunk } from "@reduxjs/toolkit";
import { createServiceTokenAsync, deleteServiceTokenAsync, getServiceTokensAsync, updateServiceTokenAsync } from "apis/ServiceTokenApi";
import { ServiceTokenCreateRequest } from "interfaces/request/ServiceTokenCreateRequest";
import { ServiceTokenUpdateRequest } from "interfaces/request/ServiceTokenUpdateRequest";
import ServiceTokenResponse from "interfaces/response/ServiceTokenResponse";
import { RootState } from "store/store";

export const loadServiceTokensAsync = createAsyncThunk<any, void, { state: RootState }>(
  "serviceTokens/all",
  async () =>  await getServiceTokensAsync()
);

export const createTokenAsync = createAsyncThunk(
  "serviceTokens/create",
  async (request: ServiceTokenCreateRequest) => {
    return await createServiceTokenAsync(request);
  }
);

export const updateTokenAsync = createAsyncThunk<
  ServiceTokenResponse,
  { id: number; tokenUpdateRequest: ServiceTokenUpdateRequest }
>("serviceTokens/update", async (request) => {
  return await updateServiceTokenAsync(
    request.id,
    request.tokenUpdateRequest
  );
});

export const deleteTokenAsync = createAsyncThunk(
  "serviceTokens/delete",
  async (tokenId: number) => {
    return await deleteServiceTokenAsync(tokenId);
  }
);
