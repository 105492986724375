import HelpIcon from "components/common/icons/HelpIcon";
import { useState } from "react";
import InformationPopover from "./InformationPopover";

export default function Information() {
  const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false);

  const handleHidePopover = () => setIsPopoverVisible(false);

  const handleIconClick = () => setIsPopoverVisible(!isPopoverVisible);

  return (
    <div
      id="info-menu"
      className="select-none cursor-pointer navigation-icon"
      onClick={handleIconClick}
    >
      <div>
        <HelpIcon className={"navigation-icon-svg"} />
      </div>
      <InformationPopover
        visible={isPopoverVisible}
        onHiding={handleHidePopover}
        target="#info-menu"
      />
    </div>
  )
}