import { useEffect, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import LandingIlapLogo from "components/common/icons/LandingIlapLogo";
import SignInButton from "features/landing/button/SignInButton";
import { signIn } from "authentication/AuthenticationManager";
import { useIsAuthenticated } from "@azure/msal-react";
import { toastError } from "shared/utilities/ToastUtility";
import * as routePath from "shared/constants/RoutePathConstants";
import { displayLoadingPanel, hideLoadingPanel } from "components/common/LoadingPanel";
import 'features/landing/styles/LandingPage.css'
import { useAppDispatch } from "store/hooks";
import ApiVersionResponse from "interfaces/response/ApiVersionResponse";
import { loadApiVersionAsync } from "store/action/ApiVersionActions";
import { unwrapResult } from "@reduxjs/toolkit";

const defaultVersionNumber = "-.-.-.-";

export default function LandingPage() {
  const isAuthenticated = useIsAuthenticated();
  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  const [apiVersion, setApiVersion] = useState<string>(defaultVersionNumber);
  const [webVersion, setWebVersion] = useState<string>(defaultVersionNumber);

  useEffect(() => {
    const version = process.env.REACT_APP_VERSION;
    if (version){
      setWebVersion(version);
    }
  }, []);

  useEffect(() => {
    dispatch(loadApiVersionAsync())
      .then(unwrapResult)
      .then((response: ApiVersionResponse) => {
        if (response.version){
          setApiVersion(response.version);
        }
      })
      .catch(() => {
        // ignore error if api version could not be loaded
      });
  }, [dispatch]);

  const navigateToHomePage = useCallback(() => {
    navigate(routePath.REPORT_SCHEDULES);
  }, [navigate]);

  useEffect(() => {
    if (isAuthenticated) {
      navigateToHomePage();
    }
  }, [isAuthenticated, navigateToHomePage]);

  const handleSignInClick = () => {
    displayLoadingPanel();

    signIn()
      .catch(error => {
        toastError()
      })
      .finally(hideLoadingPanel);
  }

  return (
    <div className="overflow-auto h-screen flex font-poppins bg-landing">
      <div className="h-screen w-screen bg-illutration flex flex-col justify-between">
        <div className="mt-16 ml-16 mb-16 fhd:mt-20 2k:mt-[120px] fhd:ml-24 2k:ml-40">
          <div className="grid grid-cols-1">
            <div>
              <LandingIlapLogo />
            </div>
            <div className="mt-16 mb-16 fhd:mt-[198px] 2k:mt-[311px] fhd:ml-[120px] 2k:ml-[166px]">
              <p className="font-poppins text-[48px] 2k:text-[64px] leading-normal font-semibold text-left text-white">
                Collection and <span className="text-orange-200">analytics</span>
                <br />
                of schedule data
              </p>
              <p className="mt-[21px] 2k:mt-[16px] font-poppins text-18px text-left text-gray-7 mb-[17px]">
                Sign in with your business account
              </p>
              <SignInButton onClick={handleSignInClick}>
                Sign in
              </SignInButton>
            </div>
          </div>
        </div>
        <div className="flex justify-end pr-16 pb-14">
          <div className="text-white text-base text-right">
            <div>API version: {apiVersion}</div>
            <div>Web version: {webVersion}</div>
          </div>
        </div>
      </div>
    </div>

  );
}