import { Accordion } from "devextreme-react";
import { IAccordionOptions } from "devextreme-react/accordion";
import "./styles/IlapAccordion.css";

interface Props { }

export default function IlapAccordion(props: Props & IAccordionOptions) {
  return (
    <Accordion
      className="ilap-accordion"
      collapsible={true}
      multiple={true}
      repaintChangesOnly={true}
      {...props}
    />
  )
}
