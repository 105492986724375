import MetadataField from "interfaces/response/MetadataField";
import {
  deleteDataAsync,
  getDataAsync,
  postDataAsync,
  putDataAsync,
} from "./AnalyticsApi";
import MetadataFieldWithContentControlResponse from "interfaces/response/MetadataFieldWithContentControlResponse";

const routeUrl = `metadataField`;

export const getMetadataFieldsAsync = async () => {
  return await getDataAsync<MetadataField[]>(routeUrl);
};

export const getMetadataFieldsSlimAsync = async () => {
  return await getDataAsync<MetadataField[]>(`${routeUrl}s`);
};

export const getMetadataFieldAsync = async (metadataFieldId: number) => {
  const url = `${routeUrl}/${metadataFieldId}`;
  return await getDataAsync<MetadataField>(url, false);
};

export const updateMetadataFieldApiAsync = async (
  metadataField: MetadataField
) => {
  const url = `${routeUrl}/${metadataField.id}`;
  return await putDataAsync<MetadataField, MetadataField>(url, metadataField);
};

export const createMetadataFieldApiAsync = async (
  metadataField: MetadataField
) => {
  return await postDataAsync<MetadataField, MetadataField>(routeUrl, metadataField, false);
};

export const createMetadataFieldsApiAsync = async (
  fields: MetadataField[]
) => {
  const url = `${routeUrl}s/bulk`;
  return await postDataAsync<MetadataField[], MetadataField[]>(url, fields);
};

export const deleteMetadataFieldApiAsync = async (id: number) => {
  const url = `${routeUrl}/${id}`;
  return await deleteDataAsync(url, true, "Field could NOT be deleted. ");
};

export const getMetadataFieldsFromFlowAsync = async () => {
  const url = `${routeUrl}/IlapDataExchangeFields`;
  return await getDataAsync<MetadataFieldWithContentControlResponse[]>(url);
};
