import CrossIconButton from "components/common/buttons/icon-buttons/CrossIconButton";
import { TextArea } from "devextreme-react";
import { IlapPopup } from "ilap.common.webcomponents.test";
import "./styles/ErrorDisplayModal.css";

export interface ErrorDetails {
  lineNumber?: number,
  inputValue?: string,
  errorText: string,
}

interface Props {
  errors: string[],
  onHideDialog: () => void;
}

export default function ErrorDisplayModal(props: Props) {

  const errorCount = props.errors.length;
  const errors = props.errors.join("\n\n");

  return (
    <IlapPopup
      className="error-display-modal"
      height={"auto"}
      width={"600px"}
    >
      <div className="flex flex-col gap-3">
        <header className="flex justify-between items-center text-gray-9">
          <div className="font-poppins text-18px font-semibold truncate">Errors</div>
          <CrossIconButton onClick={props.onHideDialog} />
        </header>
        <div className="text-14px font-semibold text-red-4" >{errorCount} error(s) found.</div>
        <TextArea readOnly autoResizeEnabled maxHeight={"470px"} defaultValue={errors} className="errors"></TextArea>
      </div>
    </IlapPopup>
  );
}