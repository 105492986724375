import CopyButton from "components/common/buttons/icon-buttons/CopyButton";

interface Props {
  name: string;
  version: string
}

export default function VersionDropdownItem(props: Props) {
  const { name, version } = props;
  const versionText = `Version ${version}`;
  const textToBeCopied = `${name} - ${versionText}`;

  return (
    <div className="h-14 flex justify-between items-center font-poppins mx-4">
      <div className="">
        <div className="font-semibold text-sm">{name}</div>
        <div className="text-base">{versionText}</div>
      </div>
      <div>
        <CopyButton textToBeCopied={textToBeCopied} />
      </div>
    </div>
  );
}