import BatchOperationResponse from "interfaces/response/BatchOperationResponse"
import { BatchOperation } from "./BatchOperation";
import BatchOperationTitle from "./BatchOperationTitle";
import { useCallback } from "react";
import IlapAccordion from "components/common/controls/IlapAccordion";

interface Props {
  operations: BatchOperationResponse[]
}

export default function BatchOperationsAccordion(props: Props) {
  const { operations } = props;

  const titleRender = useCallback((data: BatchOperationResponse) => {
    return (
      <BatchOperationTitle batch={data} />
    )
  }, []);

  const itemRender = useCallback((data: BatchOperationResponse) => {
    return (
      <BatchOperation batch={data} />
    )
  }, []);


  return (
    <IlapAccordion
      dataSource={operations}
      defaultSelectedIndex={0}
      itemTitleRender={titleRender}
      itemRender={itemRender}
      noDataText="No imports found. Please refresh the page to see the latest data."
    >
    </IlapAccordion>
  )
}