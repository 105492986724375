import AddButton from "components/common/buttons/AddNewButton";
import IlapConfirmationDialog from "components/common/controls/IlapConfirmationDialog";
import { displayLoadingPanel, hideLoadingPanel } from "components/common/LoadingPanel";
import { Column } from "devextreme-react/data-grid";
import { IlapViewGrid } from "ilap.common.webcomponents.test";
import { ConfirmationDialogInfo } from "interfaces/common/ConfirmationDialogInfo";
import ServiceTokenCreateResponse from "interfaces/response/ServiceTokenCreateResponse";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { deleteTokenAsync, loadServiceTokensAsync, updateTokenAsync } from "store/action/ServiceTokenActions";
import { useAppDispatch } from "store/hooks";
import { RootState } from "store/store";
import ServiceTokenCreateModal from "./create/ServiceTokenCreateModal";
import ServiceTokenViewModal from "./create/ServiceTokenViewModal";
import "./styles/ServiceTokenGrid.css";

export default function ServiceTokenGrid() {
  const [isTokenCreateModalVisible, setIsTokenCreateModalVisible] = useState<boolean>(false);
  const [createdToken, setCreatedToken] = useState<ServiceTokenCreateResponse>();
  const [confirmationDialogInfo, setConfirmationDialogInfo] = useState<ConfirmationDialogInfo>();
  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const serviceTokens = useSelector((state: RootState) => state.serviceTokenData.serviceTokens);

  useEffect(() => {
    if (serviceTokens?.length === 0) {
      dispatch(loadServiceTokensAsync());
    }
  }, [dispatch, serviceTokens?.length]);

  const showTokenCreateModal = () => setIsTokenCreateModalVisible(true);
  const hideTokenCreateModal = () => setIsTokenCreateModalVisible(false);

  const showConfirmationDialog = () => setIsConfirmationDialogVisible(true);
  const hideConfirmationDialog = () => setIsConfirmationDialogVisible(false);

  const handleTokenCreateSuccess = (token: ServiceTokenCreateResponse) => {
    setCreatedToken(token);
  }

  const handleHideTokenViewer = () => {
    setCreatedToken(undefined);
  }

  const handleToggleServiceTokenStatus = useCallback((token: ServiceTokenCreateResponse) => {
    // set the opposite of current status
    dispatch(updateTokenAsync({ id: token.id, tokenUpdateRequest: { isActive: !token.isActive } }));
  }, [dispatch]);

  const handleDeleteToken = useCallback((token: ServiceTokenCreateResponse) => {
    displayLoadingPanel();
    dispatch(deleteTokenAsync(token.id))
      .then(hideLoadingPanel)
      .then(hideConfirmationDialog);
  }, [dispatch]);

  const showDeleteConfirmationModal = useCallback((token: ServiceTokenCreateResponse) => {
    setConfirmationDialogInfo({
      content: "Delete service token?",
      subContent: "Once deleted, this token will no longer be valid, and any services using it for authentication will lose access.",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      isDeleteConfirmation: true,
      onConfirm: () => handleDeleteToken(token),
      onCancel: hideConfirmationDialog
    });

    showConfirmationDialog();

  }, [handleDeleteToken]);

  const rowOptions = useRef({
    visible: true,
    items: [
      {
        text: "", // "text" parameter has to be present, but textFn will be used
        textFn: (e: any) => e.data.isActive ? "Deactivate" : "Activate",
        onClick: (e: any) => handleToggleServiceTokenStatus(e.data),
        visible: true
      },
      {
        text: "Delete",
        onClick: (e: any) => showDeleteConfirmationModal(e.data),
        visible: true
      }
    ]
  });

  return (
    <div>
      <div className="mb-[21px] font-poppins text-[32px] text-dark-blue-1 font-semibold leading-normal flex">
        <div>Access tokens</div>
      </div>
      <div className="font-poppins bg-white border-light-gray border rounded-lg w-[400px] px-6 py-4 mb-4">
        <div className="font-semibold text-18px leading-[27px] text-gray-9 mb-5">Generate new token</div>
        <div className="font-normal text-base leading-6 text-gray-7 mb-4">The access token allows external systems to securely populate data in ILAP Analytics.</div>
        <div className="flex justify-end">
          <AddButton className="px-px py-4 border-none" text="Generate new token" onClick={showTokenCreateModal} />
        </div>
      </div>
      <IlapViewGrid
        dataSource={serviceTokens}
        className="service-token-grid h-[calc(100vh-380px)]"
        columnMinWidth={100}
        rowOperationConfig={rowOptions.current}
      >
        <Column
          caption={"ID"}
          dataField={"id"}
          alignment="left"
          width={140}
        />
        <Column
          caption={"Title"}
          dataField={"name"}
        />
        <Column
          caption={"Identifier"}
          dataField={"identifier"}
          width={360}
        />
        <Column
          caption={"Expiry"}
          dataField={"expiry"}
          dataType={"datetime"}
        />
        <Column
          caption={"Is Active"}
          dataField={"isActive"}
          dataType={"string"}
          customizeText={(cellData: any) => cellData.value ? "Yes" : "No"}
          width={140}
        />
      </IlapViewGrid>
      {isTokenCreateModalVisible && <ServiceTokenCreateModal onTokenCreateSuccess={handleTokenCreateSuccess} onHideDialog={hideTokenCreateModal} />}
      {createdToken && <ServiceTokenViewModal serviceToken={createdToken} onHideDialog={handleHideTokenViewer} />}
      {isConfirmationDialogVisible && confirmationDialogInfo &&
        <IlapConfirmationDialog
          height="181px"
          width="456px"

          content={confirmationDialogInfo.content}
          subContent={confirmationDialogInfo.subContent}
          cancelButtonText={confirmationDialogInfo.cancelButtonText}
          confirmButtonText={confirmationDialogInfo.confirmButtonText}

          onConfirm={confirmationDialogInfo.onConfirm}
          onCancel={confirmationDialogInfo.onCancel}

          isDeleteConfirm={confirmationDialogInfo.isDeleteConfirmation}
        />
      }
    </div>
  )
}