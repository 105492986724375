import { createSlice } from "@reduxjs/toolkit";

export interface LoadingPanelState {
  isLoading: boolean;
  loadingPanelCounter: number;
  style?: string;
}

const initialState: LoadingPanelState = {
  isLoading: false,
  loadingPanelCounter: 0,
  style: undefined
};

export const loadingPanelSlice = createSlice({
  name: "LoadingPanelSlice",
  initialState,
  reducers: {
    makeLoadingPanelHideForce: () => initialState,
    makeLoadingPanelVisible: (state, { payload }: { payload: string }) => {
      state.loadingPanelCounter++;
      if (state.loadingPanelCounter > 0) {
        state.isLoading = true;
        state.style = payload;
      }      
    },        
    makeLoadingPanelHide: (state) => {
      if (state.loadingPanelCounter > 0) {
        state.loadingPanelCounter--;
      }
      if (state.loadingPanelCounter === 0) {
        state.isLoading = false;
      }
    },
  },
});

export default loadingPanelSlice.reducer;
export const {
  makeLoadingPanelVisible,
  makeLoadingPanelHide,  
  makeLoadingPanelHideForce,
} = loadingPanelSlice.actions;
