// svg Source: "static/images/add-icon.svg"

export default function AddIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5 9.07146H9.07143V15.5001H6.92857V9.07146H0.5V6.9286H6.92857V0.5H9.07143V6.9286H15.5V9.07146Z" fill="white" />
    </svg>

  );
}
