import BackgroundJobResponse from "interfaces/response/BackgroundJobResponse";
import ReportScheduleResponse from "interfaces/response/ReportScheduleResponse";
import { ReportScheduleJobCode } from "shared/enums/ReportScheduleJobCode";
import { ReportScheduleOperationStatus } from "shared/enums/ReportScheduleOperationStatus";
import { getDefaultLocaleFormattedDateTime, getDurationSince } from "./DateTimeUtility";

const backgroundJobDisplayNames: Record<ReportScheduleJobCode, string> = {
  [ReportScheduleJobCode.CreateData]: "Create data",
  [ReportScheduleJobCode.GenerateReportActivities]: "Generate report activities",
  [ReportScheduleJobCode.GenerateReportMetadata]: "Generate report metadata",
  [ReportScheduleJobCode.PeriodizeReportSchedule]: "Periodize report schedule",
  [ReportScheduleJobCode.CalculateActuals]: "Calculate actuals",
  [ReportScheduleJobCode.SetExtendedProperties]: "Set extended properties",
  [ReportScheduleJobCode.MoveAndClearIlapFileContent]: "Cleanup operation",
  [ReportScheduleJobCode.RemoveOrphanReportActivities]: "Remove orphan report activities",
  [ReportScheduleJobCode.RevertActuals]: "Revert actuals"
};

export const BatchOperationUtility = {
  getBackgroundJobName: (jobCode: ReportScheduleJobCode): string => {
    return backgroundJobDisplayNames[jobCode];
  },
  getBackgroundJobStatus: (job: BackgroundJobResponse): string => {
    switch (job.status) {
      case ReportScheduleOperationStatus.Successful:
        return `Completed at ${job.finishedAt && getDefaultLocaleFormattedDateTime(job.finishedAt)}`
      case ReportScheduleOperationStatus.Failed:
        return `Failed at ${job.finishedAt && getDefaultLocaleFormattedDateTime(job.finishedAt)}`
      case ReportScheduleOperationStatus.Deleted:
        return `Deleted at ${job.finishedAt && getDefaultLocaleFormattedDateTime(job.finishedAt)}`
      case ReportScheduleOperationStatus.Running:
        return `Started at ${job.startedAt && getDefaultLocaleFormattedDateTime(job.startedAt)} - Running for ${job.startedAt && getDurationSince(job.startedAt)}`;
      default:
        return ``;
    }
  },
  getLastCompletedBatchStatus: (reportSchedule: ReportScheduleResponse): ReportScheduleOperationStatus => {
    if (reportSchedule.lastLiveOperationStatus === ReportScheduleOperationStatus.Failed
      || reportSchedule.lastBaselineOperationStatus === ReportScheduleOperationStatus.Failed
      || reportSchedule.lastOriginalBaselineOperationStatus === ReportScheduleOperationStatus.Failed
      || reportSchedule.lastCurrentOperationStatus === ReportScheduleOperationStatus.Failed) {
      return ReportScheduleOperationStatus.Failed;
    }

    // we already checked if any of the operations failed, so if any of the operations are successful, the batch is successful
    // we will handle deleted batch when required
    if (reportSchedule.lastLiveOperationStatus === ReportScheduleOperationStatus.Successful
      || reportSchedule.lastBaselineOperationStatus === ReportScheduleOperationStatus.Successful
      || reportSchedule.lastOriginalBaselineOperationStatus === ReportScheduleOperationStatus.Successful
      || reportSchedule.lastCurrentOperationStatus === ReportScheduleOperationStatus.Successful) {
      return ReportScheduleOperationStatus.Successful;
    }
    
    return ReportScheduleOperationStatus.Never_Run;
  },
  getLastOperationStatusText: (reportSchedule: ReportScheduleResponse): string => {
    return `Last Live Import: ${ReportScheduleOperationStatus[reportSchedule.lastLiveOperationStatus]}
Last Baseline Import: ${ReportScheduleOperationStatus[reportSchedule.lastBaselineOperationStatus]}
Last Original Baseline Import: ${ReportScheduleOperationStatus[reportSchedule.lastOriginalBaselineOperationStatus]}
Last Current Import: ${ReportScheduleOperationStatus[reportSchedule.lastCurrentOperationStatus]}`;
  }
}