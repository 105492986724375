import Circle from "components/common/Circle";
import ReportScheduleResponse from "interfaces/response/ReportScheduleResponse";
import { useMemo } from "react";
import { IlapColorVariant } from "shared/enums/IlapColorVariant";
import { ReportScheduleOperationStatus } from "shared/enums/ReportScheduleOperationStatus";
import { BatchOperationUtility } from "shared/utilities/BatchOperationUtility";

export default function ReportScheduleStatusCellComponent(e: { data: { data: any } }) {
  const reportScheduleStatus = useMemo(() => {
    const reportSchedule = e.data.data as ReportScheduleResponse;

    const tooltipText = BatchOperationUtility.getLastOperationStatusText(reportSchedule);
    const lastOperationStatus = BatchOperationUtility.getLastCompletedBatchStatus(reportSchedule);

    switch (lastOperationStatus) {
      case ReportScheduleOperationStatus.Successful:
        return <Circle color={IlapColorVariant.Green5} tooltipText={tooltipText} />
      case ReportScheduleOperationStatus.Failed:
        return <Circle color={IlapColorVariant.Red} tooltipText={tooltipText} />
      default:
        return <div>-</div>
    }
  }, [e.data.data]);

  return reportScheduleStatus;
}
