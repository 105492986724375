import { IlapSvgProps } from "interfaces/common/IlapSvgProps";

export default function RoundTickIcon(props: IlapSvgProps) {
  return (
    <svg className={props.className} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="21" height="21" rx="10.5" fill={props.fill ?? "#009C7C"} />
      <g clipPath="url(#clip0_9564_4245)">
        <path d="M8.99993 13.8L6.19993 11L5.2666 11.9333L8.99993 15.6667L16.9999 7.66666L16.0666 6.73333L8.99993 13.8Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_9564_4245">
          <rect width="16" height="16" fill="white" transform="translate(3 3)" />
        </clipPath>
      </defs>
    </svg>

  )
}