import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { useEffect, useMemo, useState } from "react";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import {
  createReportSchedule,
} from "store/action/ReportScheduleActions";
import { Type } from "shared/enums/TypeEnum";
import { isNullOrEmpty, isNullOrZero } from "shared/utilities/CommonUtility";
import { PlanningObjectTypes } from "shared/enums/PlanningObjectTypesEnum";
import { ValidationRequirement } from "shared/enums/ValidationRequirementEnum";
import MetadataField from "interfaces/response/MetadataField";
import ReportScheduleRequest from "interfaces/request/ReportScheduleRequest";
import ReportScheduleResponse from "interfaces/response/ReportScheduleResponse";
import { unwrapResult } from "@reduxjs/toolkit";
import MetadataFieldValue from "interfaces/common/MetadataFieldValue";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { REPORT_SCHEDULES } from "shared/constants/RoutePathConstants";
import { loadSingleReportScheduleTypeAsync } from "store/action/ReportScheduleTypeActions";
import ReportScheduleType from "interfaces/response/ReportScheduleType";
import ReportScheduleMetadataFieldValue from "interfaces/response/ReportScheduleMetadataFieldValue";
import { IlapButtonType, IlapButton, IlapPopup } from "ilap.common.webcomponents.test";
import '../../../components/common/controls/styles/PromineoSelect.css';
import "./styles/ReportSchedulesCreateModal.css";
import { useNavigate } from "react-router";
import IlapConfirmationDialog from "components/common/controls/IlapConfirmationDialog";
import { addBlankValue, createEmptyReportSchedule } from "../ReportScheduleUtility";
import { Popup } from "devextreme-react";
import StepperProgressBar from "components/common/stepper/StepperProgressBar";
import StepperProgressItem from "components/common/stepper/StepperProgressItem";
import ReportScheduleGeneralInfoForm from "./forms/ReportScheduleGeneralInfoForm";
import ReportScheduleFieldValueForm from "./forms/ReportScheduleFieldValueForm";
import { ConfirmationDialogInfo } from "interfaces/common/ConfirmationDialogInfo";

interface Props {
  mode: PromineoModalMode;
  onHideDialog: () => void;
}

const emptyReportSchedule: ReportScheduleResponse = createEmptyReportSchedule();

const totalSteps = 2; // [1, 2] -> total number of steps in the Report Schedule Creation Process

enum Steps {
  GENERAL_INFO = 1,
  SCHEDULE_FIELDS
}

export default function ReportSchedulesCreateModal(props: Props) {
  const dispatch = useDispatch<AppDispatch>();
  let navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(Steps.GENERAL_INFO);
  const [infoValidationEnabled, setInfoValidationEnabled] = useState<boolean>(false);

  const [reportScheduleMetadataFieldValueSource, setReportScheduleMetadataFieldValueSource] = useState<ReportScheduleMetadataFieldValue[]>([]);
  const [isFieldValueEntered, setIsFieldValueEntered] = useState<boolean>(false);
  const [metadataFields, setMetadataFields] = useState<MetadataField[]>([]);
  const [reportScheduleInput, setReportScheduleInput] = useState<ReportScheduleResponse>(emptyReportSchedule);

  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState<boolean>(false);
  const [confirmationDialogInfo, setConfirmationDialogInfo] = useState<ConfirmationDialogInfo>();

  const progressItems: StepperProgressItem[] = [
    {
      text: "General information",
      icon: "1"
    },
    {
      text: "Schedule fields",
      icon: "2"
    }
  ];

  useEffect(() => {
    // Avoid loading metadata for un-initialized reportScheduleTypeId.
    // Also, avoid loading metadata if we are in the first step, it reduces expensive api calls.
    if (currentStep === Steps.GENERAL_INFO || reportScheduleInput.reportScheduleTypeId === emptyReportSchedule.reportScheduleTypeId) {
      return;
    }

    displayLoadingPanel();
    dispatch(
      loadSingleReportScheduleTypeAsync({
        reportScheduleTypeId: reportScheduleInput.reportScheduleTypeId,
      })
    )
      .then(unwrapResult)
      .then((reportScheduleType: ReportScheduleType) => {
        setMetadataFields(reportScheduleType?.metadataFields);
      })
      .finally(hideLoadingPanel);
  }, [dispatch, reportScheduleInput.reportScheduleTypeId, currentStep]);

  useEffect(() => {

    const reportScheduleMetadataFieldValue: ReportScheduleMetadataFieldValue[] = [];

    for (
      let metadataFieldIndex = 0;
      metadataFieldIndex < metadataFields?.length;
      metadataFieldIndex++
    ) {
      let metadataField = metadataFields[metadataFieldIndex];

      if (metadataField.planningObjectTypes === PlanningObjectTypes.Schedule && !metadataField.receivedFromSchedule) {
        reportScheduleMetadataFieldValue.push({
          metadataFieldId: metadataField.id,
          name: metadataField.name,
          displayDropdown: metadataField.validationRequirement === ValidationRequirement.ValueInList || metadataField.validationRequirement === ValidationRequirement.ValueInListOrBlank,
          dropdownSource: metadataField.validationRequirement === ValidationRequirement.ValueInListOrBlank ?
            addBlankValue(metadataField.metadataFieldValues, metadataField.id) : metadataField.metadataFieldValues,
          isRequired: metadataField.validationRequirement === ValidationRequirement.ValueInList || metadataField.validationRequirement === ValidationRequirement.NonBlankValue
        });
      }
    }

    reportScheduleInput.metadataFieldValues.forEach((fieldValues) => {
      const metadataFieldValueIndex =
        reportScheduleMetadataFieldValue.findIndex(
          (metadata: ReportScheduleMetadataFieldValue) =>
            metadata.metadataFieldId === fieldValues.metadataFieldId
        );

      if (metadataFieldValueIndex > -1) {
        const reportScheduleMetadata =
          reportScheduleMetadataFieldValue[metadataFieldValueIndex];

        reportScheduleMetadata.value = fieldValues.stringValue;
        reportScheduleMetadata.id = fieldValues.id;
      }
    });
    setReportScheduleMetadataFieldValueSource(reportScheduleMetadataFieldValue);
  }, [metadataFields, reportScheduleInput.metadataFieldValues]);

  const getReportScheduleRequestModelFromInput = (): ReportScheduleRequest => {
    const reportScheduleMetadataFieldValues: MetadataFieldValue[] = [];

    reportScheduleMetadataFieldValueSource.forEach((metadataFieldValue) => {
      reportScheduleMetadataFieldValues.push({
        id: 0,
        type: Type.String,
        metadataFieldId: metadataFieldValue.metadataFieldId,
        stringValue:
          metadataFieldValue.value !== null ? metadataFieldValue.value! : "",
      });
    });

    const reportScheduleRequest: ReportScheduleRequest = {
      id: reportScheduleInput.id,
      title: reportScheduleInput.title,
      internalId: reportScheduleInput.internalId,
      description: reportScheduleInput.description,
      pointsInTimeType: reportScheduleInput.pointsInTimeType,
      reportScheduleTypeId: reportScheduleInput.reportScheduleTypeId,
      metadataFieldValues: reportScheduleMetadataFieldValues,
    };

    return reportScheduleRequest;
  };

  const handleCreateClick = () => {

    if (!checkIfRequiredFieldsAreFilled()) return;

    const reportScheduleRequest = getReportScheduleRequestModelFromInput();

    displayLoadingPanel();
    dispatch(createReportSchedule(reportScheduleRequest))
      .then(unwrapResult)
      .then((response: ReportScheduleResponse) => {
        setReportScheduleInput(response);
        navigate(`${REPORT_SCHEDULES}/${response.id}/schedules`);
      })
      .finally(hideLoadingPanel);
  };

  const changeReportScheduleType = (value: number) => {
    setReportScheduleInput({
      ...reportScheduleInput,
      reportScheduleTypeId: value,
      metadataFieldValues: [],
    });

    setIsFieldValueEntered(false); // since we are resetting the fields, the values are also gone

    hideConfirmationDialog(); // if the "schedule type change" confirmation dialog is visible, we close it
  }

  const handleReportScheduleTypeChange = (value: number) => {
    if (reportScheduleInput.reportScheduleTypeId && reportScheduleInput.reportScheduleTypeId !== value && isFieldValueEntered){
      setConfirmationDialogInfo({
        content: "Change report schedule type",
        subContent: "Changing the schedule type will reset the schedule fields and discard any entered values.",
        confirmButtonText: "Change schedule type",
        cancelButtonText: "Cancel",
        onConfirm: () => changeReportScheduleType(value),
        onCancel: hideConfirmationDialog
      });
      
      setIsConfirmationDialogVisible(true);

      return;
    }

    changeReportScheduleType(value);
  };

  const handleDropdownSelectionChange = (selectedItem: MetadataFieldValue) => {
    handleMetadataFieldValueChange(
      selectedItem.metadataFieldId,
      (selectedItem.stringValue === "-") ? "" : selectedItem.stringValue,
      selectedItem.id
    );
  };

  const handleMetadataFieldValueChange = (
    metadataFieldId: number,
    value: string,
    id?: number
  ) => {
    const existingIndex = reportScheduleMetadataFieldValueSource.findIndex(
      (fieldValue: ReportScheduleMetadataFieldValue) =>
        fieldValue.metadataFieldId === metadataFieldId
    );

    if (existingIndex > -1) {
      const modifiedreportScheduleMetadataFieldValue = [
        ...reportScheduleMetadataFieldValueSource,
      ];

      if (
        modifiedreportScheduleMetadataFieldValue[existingIndex].id !== id ||
        modifiedreportScheduleMetadataFieldValue[existingIndex].value !== value
      ) {
        modifiedreportScheduleMetadataFieldValue[existingIndex].id = id;
        modifiedreportScheduleMetadataFieldValue[existingIndex].value = value;
        modifiedreportScheduleMetadataFieldValue[existingIndex].isMissingRequired = false;

        setReportScheduleMetadataFieldValueSource(modifiedreportScheduleMetadataFieldValue);
        setIsFieldValueEntered(true);
      }
    }
  };

  const checkIfGeneralInfoIsMissing = () => {
    return isNullOrEmpty(reportScheduleInput.title) ||
      isNullOrEmpty(reportScheduleInput.internalId) ||
      isNullOrZero(reportScheduleInput.reportScheduleTypeId) ||
      isNullOrZero(reportScheduleInput.pointsInTimeType);
  }

  const checkIfRequiredFieldsAreFilled = () => {
    let isRequiredFieldsAreFilled = true;

    if (checkIfGeneralInfoIsMissing()) 
    {
      setInfoValidationEnabled(true);
      isRequiredFieldsAreFilled = false;
    }
    
    const modifiedreportScheduleMetadataFieldValue = [
      ...reportScheduleMetadataFieldValueSource,
    ];

    for (var element of modifiedreportScheduleMetadataFieldValue) {
      if (element.isRequired && isNullOrEmpty(element.value)) {
        element.isMissingRequired = true;
        setReportScheduleMetadataFieldValueSource(modifiedreportScheduleMetadataFieldValue);
        isRequiredFieldsAreFilled = false;
      }
    }
    return isRequiredFieldsAreFilled;
  };

  const hasInput = () => {
    if (
      reportScheduleInput.reportScheduleTypeId !== 0 ||
      reportScheduleInput.pointsInTimeType !== 0 ||
      reportScheduleInput.title?.length !== 0 ||
      reportScheduleInput.internalId?.length !== 0 ||
      reportScheduleInput.description?.length !== 0
    ) {
      return true;
    } else return false;
  };

  const handleCancelClick = () => {
    if (hasInput()) {
      setConfirmationDialogInfo({
        content: "Cancel new report schedule",
        subContent: "The values you have entered for the new report schedule will not be saved.",
        confirmButtonText: "Confirm",
        cancelButtonText: "Continue editing",
        onConfirm: handleConfirmClicked,
        onCancel: hideConfirmationDialog
      });

      setIsConfirmationDialogVisible(true);
    }
    else {
      props.onHideDialog();
    }
  };

  const handleConfirmClicked = () => {
    props.onHideDialog();
  };

  const hideConfirmationDialog = () => {
    setIsConfirmationDialogVisible(false);
  }

  const handleValueChange = (fieldName: string, value: any) => {
    setReportScheduleInput({
      ...reportScheduleInput,
      [fieldName]: value,
    });
  }

  const previousButtonText = useMemo(() => currentStep == Steps.GENERAL_INFO ? "Cancel" : "Back", [currentStep]);
  const nextButtonText = useMemo(() => currentStep == Steps.SCHEDULE_FIELDS ? "Create" : "Next", [currentStep]);

  const handleNextClick = () => {
    if (currentStep === Steps.GENERAL_INFO){
      if (checkIfGeneralInfoIsMissing()){
        setInfoValidationEnabled(true);
        return;
      }

      setInfoValidationEnabled(false);
      setCurrentStep(Steps.SCHEDULE_FIELDS);
    }
    else{
      handleCreateClick();
    }
  };

  const handlePreviousClick = () => {
    if (currentStep === Steps.GENERAL_INFO){
      handleCancelClick();
    }
    else{
      setCurrentStep(Steps.GENERAL_INFO);
    }
  }

  const modalWrapperAttr = useMemo(() => {
    return {
      class: "ilap-report-schedule-create",
    };
  }, []);

  return (
    <IlapPopup
      wrapperAttr={modalWrapperAttr}
      height={"640px"}
      width={"728px"}>
      <div className="flex flex-col justify-between h-full">
        <header>
          <div className="mb-5 flex items-center justify-between">
            <div className="flex items-center">
              <div className="font-poppins text-gray-9 text-[18px] font-semibold leading-normal">New report schedule</div>
            </div>
            <div className="flex items-center">
              <StepperProgressBar items={progressItems} current={currentStep} dividerWidth="small" />
            </div>
          </div>
          <hr className="mt-4 mb-4 border-light-gray" />
        </header>

        {currentStep === Steps.GENERAL_INFO &&
          <ReportScheduleGeneralInfoForm
            title={reportScheduleInput.title}
            internalId={reportScheduleInput.internalId}
            reportScheduleType={reportScheduleInput.reportScheduleTypeId}
            pointInTimeType={reportScheduleInput.pointsInTimeType}
            onValueChange={handleValueChange}
            onScheduleTypeChange={handleReportScheduleTypeChange}
            isValidationEnabled={infoValidationEnabled}
          />
        }

        {currentStep === Steps.SCHEDULE_FIELDS &&
          <ReportScheduleFieldValueForm
            fieldSource={reportScheduleMetadataFieldValueSource}
            onDropdownSelectionChange={handleDropdownSelectionChange}
            onFieldValueChange={handleMetadataFieldValueChange}
          />
        }

        <footer className="flex flex-col justify-end">
          <hr className="mt-4 mb-4 border-light-gray" />
          <div className="flex justify-end gap-4">
            <IlapButton variant={IlapButtonType.Secondary} className="px-4 rounded-lg font-medium" onClick={handlePreviousClick}>{previousButtonText}</IlapButton>
            <IlapButton className="px-4 font-medium" onClick={handleNextClick}>{nextButtonText}</IlapButton>
          </div>
        </footer>

        {isConfirmationDialogVisible && (
          <IlapConfirmationDialog
            height="181px"
            width="456px"

            content={confirmationDialogInfo?.content}
            subContent={confirmationDialogInfo?.subContent}
            cancelButtonText={confirmationDialogInfo?.cancelButtonText}
            confirmButtonText={confirmationDialogInfo?.confirmButtonText}

            onConfirm={confirmationDialogInfo?.onConfirm}
            onCancel={confirmationDialogInfo?.onCancel}
          />
        )}
      </div>
    </IlapPopup>
  );
}
