import { unwrapResult } from "@reduxjs/toolkit";
import RevertButton from "components/common/buttons/RevertButton";
import Circle from "components/common/Circle";
import IlapConfirmationDialog from "components/common/controls/IlapConfirmationDialog";
import IlapTabPanelSlim from "components/common/controls/IlapTabPanelSlim";
import PromineoGridRowOptionColumnTemplate from "components/common/grid/PromineoGridRowOptionColumnTemplate";
import InformationBar from "components/common/InformationBar";
import InfoWidget from "components/common/InfoWidget";
import {
  displayGridLoadingPanel,
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import PageTitle from "components/common/PageTitle";
import WidgetsWithSeparator from "components/common/WidgetsWithSeparator";
import { Column } from "devextreme-react/data-grid";
import { Item } from "devextreme-react/tab-panel";
import { IlapViewGrid } from "ilap.common.webcomponents.test";
import { ConfirmationDialogInfo } from "interfaces/common/ConfirmationDialogInfo";
import ReportScheduleRevertRequest from "interfaces/request/ReportScheduleRevertRequest";
import ReportScheduleStatusUpdateRequest from "interfaces/request/ReportScheduleUpdateRequest";
import ReportActivityResponse from "interfaces/response/ReportActivityResponse";
import ReportScheduleResponse from "interfaces/response/ReportScheduleResponse";
import ScheduleResponse from "interfaces/response/ScheduleResponse";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { REPORT_SCHEDULES } from "shared/constants/RoutePathConstants";
import { BatchOperationCategory } from "shared/enums/BatchOperationType";
import { IlapColorVariant } from "shared/enums/IlapColorVariant";
import { ReportScheduleOperationStatus } from "shared/enums/ReportScheduleOperationStatus";
import { ReportScheduleStatusEnum } from "shared/enums/ReportScheduleStatus";
import { RevisionType } from "shared/enums/RevisionTypeEnum";
import { BatchOperationUtility } from "shared/utilities/BatchOperationUtility";
import { getCellValueOrDefault, getRoundedLocaleNumber } from "shared/utilities/DataGridUtility";
import { DownloadFile as downloadFile } from "shared/utilities/FileDownloadUtility";
import { TextUtility } from "shared/utilities/TextUtility";
import { toastSuccess } from "shared/utilities/ToastUtility";
import { getRevisionTypeValuesAsync } from "store/action/DropdownValueActions";
import {
  changeReportScheduleRevisionTypeAsync,
  deleteScheduleAsync,
  loadReportActivitiesWithMetadataAsync,
  loadReportScheduleWithSchedulesAsync,
  revertToPreviousScheduleAsync,
  updateReportScheduleStatus
} from "store/action/ReportScheduleActions";
import { AppDispatch, RootState } from "store/store";
import BatchOperationModal from "./components/BatchOperationModal";
import ScheduleModal from "./report-schedule-revision-manage/ScheduleModal";
import ReportScheduleGridReferenceCellTemplate from "./ReportScheduleGridReferenceCellTemplate";
import "./styles/ReportScheduleRevisionGrid.css";
import "./styles/ReportScheduleTabPanel.css";
import ReportScheduleInformationView from "./view/ReportScheduleInformationView";

interface Props { }

export default function ReportScheduleRevisionGrid(props: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams();
  const navigate = useNavigate();
  const [selectedSchedule, setSelectedSchedule] =
    useState<null | ScheduleResponse>(null);
  const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);
  const [isRevertConfirmationDialogVisible, setIsRevertConfirmationDialogVisible] = useState(false);
  const [reportScheduleRevertRequest, setReportScheduleRevertRequest] = useState<ReportScheduleRevertRequest>({ reportScheduleId: -1, revisionType: -1 as RevisionType, revisionTypeText: "" });
  const [confirmationDialogInfo, setConfirmationDialogInfo] = useState<ConfirmationDialogInfo>();
  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState<boolean>(false);
  const [isReportScheduleClosed, setIsReportScheduleClosed] = useState<boolean | undefined>(undefined);

  const [isBatchOperationModalVisible, setIsBatchOperationModalVisible] = useState<boolean>(false);
  const [batchOperationCategory, setBatchOperationCategory] = useState<BatchOperationCategory>(BatchOperationCategory.Processing);
  const [lastCompletedBatchStatus, setLastCompletedBatchStatus] = useState<ReportScheduleOperationStatus>(ReportScheduleOperationStatus.Never_Run);

  const selectedScheduleWithSchedules = useSelector(
    (state: RootState) => {
      return state.reportScheduleData.selectedReportScheduleWithSchedules;
    }
  );

  useEffect(() => {
    if (selectedScheduleWithSchedules) {
      setIsReportScheduleClosed(selectedScheduleWithSchedules.status === ReportScheduleStatusEnum.Closed)
    }

  }, [selectedScheduleWithSchedules]);

  useEffect(() => {
    displayGridLoadingPanel();
    dispatch(getRevisionTypeValuesAsync())
      .finally(hideLoadingPanel);
  }, [dispatch]);

  const allRevisionTypes = useSelector(
    (store: RootState) => store.dropdownData.revisionTypes
  );

  useEffect(() => {
    if (params.id) {
      displayGridLoadingPanel();
      dispatch(loadReportScheduleWithSchedulesAsync(Number(params.id)))
        .then(unwrapResult)
        .then((rs: ReportScheduleResponse) => {
          const lastOperationStatus = BatchOperationUtility.getLastCompletedBatchStatus(rs);
          setLastCompletedBatchStatus(lastOperationStatus);
        })
        .finally(hideLoadingPanel);
    }
  }, [params, dispatch]);

  const updateRevisionReference = useCallback((data: any, newValue: number) => {
    displayGridLoadingPanel();
    dispatch(
      changeReportScheduleRevisionTypeAsync({
        revisionType: newValue,
        scheduleId: data.data.id,
      })
    )
      .then(unwrapResult)
      .finally(hideLoadingPanel);
  }, [dispatch]);

  const handleScheduleDelete = (schedule: ScheduleResponse) => {
    displayGridLoadingPanel();
    dispatch(deleteScheduleAsync(schedule.id))
      .then(unwrapResult)
      .then(() => {
        toastSuccess("Deleted schedule successfully.");
        setIsScheduleModalVisible(false);
        setSelectedSchedule(null);
      })
      .finally(hideLoadingPanel);
  };

  const handleRevertConfirm = () => {
    displayLoadingPanel();
    dispatch(revertToPreviousScheduleAsync(reportScheduleRevertRequest))
      .finally(() => {
        setIsRevertConfirmationDialogVisible(false);
        hideLoadingPanel();
      });
  }

  const showRevertOption = useCallback((schedule: ScheduleResponse) => {
    if (schedule?.revisionType === null || schedule?.revisionType === undefined) {
      return false;
    }
    return true;
  }, []);

  const handleRevertSchedule = useCallback((schedule: ScheduleResponse) => {
    setIsRevertConfirmationDialogVisible(true);
    let reportScheduleRevisionType = -1;
    let reportScheduleRevisionTypeText = "";

    if (showRevertOption(schedule)) {
      reportScheduleRevisionType = schedule?.revisionType!;
      reportScheduleRevisionTypeText = RevisionType[reportScheduleRevisionType]
    }
    const revertRequest: ReportScheduleRevertRequest = {
      reportScheduleId: selectedScheduleWithSchedules?.id ? selectedScheduleWithSchedules?.id : -1,
      revisionType: reportScheduleRevisionType,
      revisionTypeText: reportScheduleRevisionTypeText
    }
    setReportScheduleRevertRequest(revertRequest);
  }, [selectedScheduleWithSchedules, showRevertOption]);

  const initialWidgets = useMemo(() => [
    <InfoWidget label="Internal ID"
      data={selectedScheduleWithSchedules?.internalId}
      truncate={true}
      minWidth="120px"
    />,
    <InfoWidget label="Schedule level" data={selectedScheduleWithSchedules?.reportScheduleType?.planningLevelText} />,
    <InfoWidget label="Schedule type"
      data={selectedScheduleWithSchedules?.reportScheduleType?.title}
      truncate={true}
      minWidth="140px"
    />,
    <InfoWidget label="Points in time type" data={selectedScheduleWithSchedules?.pointsInTimeTypeText} />,
    <InfoWidget label="Description"
      data={selectedScheduleWithSchedules?.description}
      truncate={true}
      minWidth="200px"
    />
  ], [selectedScheduleWithSchedules]);

  const handleReportScheduleEdit = () => {
    navigate(`${REPORT_SCHEDULES}/schedules/edit/${Number(params.id)}`);
  };

  const showConfirmationDialog = () => setIsConfirmationDialogVisible(true);
  const hideConfirmationDialog = () => setIsConfirmationDialogVisible(false);

  const handleReportActivitiesExport = () => {
    if (params.id) {
      displayLoadingPanel();
      dispatch(loadReportActivitiesWithMetadataAsync(Number(params.id)))
        .then(unwrapResult)
        .then((data: ReportActivityResponse[]) => {
          const jsonData = JSON.stringify(data);
          downloadFile(jsonData, 'application/json', `ReportSchedule_${params.id}_ReportActivities_Export.json`);
        })
        .finally(hideLoadingPanel)
        .finally(hideConfirmationDialog);
    }
  }

  const handleReportActivitiesExportClick = () => {
    setConfirmationDialogInfo({
      content: "Export all activities",
      subContent: "All activities will be exported as JSON format.",
      confirmButtonText: "Export",
      cancelButtonText: "Cancel",
      onConfirm: handleReportActivitiesExport,
      onCancel: hideConfirmationDialog
    });

    showConfirmationDialog();
  }

  const handleReportScheduleStatusChange = () => {
    if (!params.id || isReportScheduleClosed === undefined) {
      return;
    }

    const newStatus = isReportScheduleClosed
      ? ReportScheduleStatusEnum.Open
      : ReportScheduleStatusEnum.Closed;

    const request: ReportScheduleStatusUpdateRequest = {
      status: newStatus
    };

    displayLoadingPanel();
    dispatch(updateReportScheduleStatus({
      reportScheduleId: Number(params.id),
      reportScheduleRequest: request
    }))
      .then(unwrapResult)
      .then(() => {
        navigate(`/report-schedules/${params.id}/schedules`)
      })
      .finally(hideLoadingPanel)
      .finally(hideConfirmationDialog);
  }

  const handleReportScheduleStatusChangeClick = useCallback(() => {
    if (isReportScheduleClosed === undefined) {
      return;
    }

    let content = "";
    let subContent = "";

    // We allow titles to use 2-lines at most for this particular modal. This 15 character threshold 
    // is an approximation to check whether the title will fit in a single line. We set the modal height based on that.
    // We do not use a monospace font, so can not calculate exact characters we will have in a single line.
    const height = (selectedScheduleWithSchedules?.title?.length ?? 0) < 15 ? "187px" : "208px";;

    if (isReportScheduleClosed) {
      content = `Reopen report schedule - ${selectedScheduleWithSchedules?.title}`;
      subContent = "The report schedule will be reopened and changes can be made.";
    }
    else {
      content = `Close report schedule - ${selectedScheduleWithSchedules?.title}`;
      subContent = "The report schedule will be closed and no changes can be made.";
    }

    setConfirmationDialogInfo({
      height: height,
      content: content,
      subContent: subContent,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      onConfirm: handleReportScheduleStatusChange,
      onCancel: hideConfirmationDialog
    });

    showConfirmationDialog();
  }, [selectedScheduleWithSchedules, isReportScheduleClosed]);

  const showProcessingOperationModal = useCallback(() => {
    setBatchOperationCategory(BatchOperationCategory.Processing);
    setIsBatchOperationModalVisible(true);
  }, []);

  const showUnsuccessfulOperationModal = useCallback(() => {
    setBatchOperationCategory(BatchOperationCategory.Unsuccessful);
    setIsBatchOperationModalVisible(true);
  }, []);

  const kebabMenu = useMemo(() => {
    const items = [];
    
    if (lastCompletedBatchStatus === ReportScheduleOperationStatus.Failed) {
      items.push({
        text: "View unsuccessful imports",
        onClick: showUnsuccessfulOperationModal,
      });
    }

    if (selectedScheduleWithSchedules?.isProcessing === true) {
      items.push({
        text: "View processing imports",
        onClick: showProcessingOperationModal,
      });
    }

    items.push(
      {
        text: "Export all activities",
        onClick: handleReportActivitiesExportClick
      },
      {
        text: isReportScheduleClosed ? "Reopen report schedule" : "Close report schedule",
        onClick: handleReportScheduleStatusChangeClick
      }
    );

    return <PromineoGridRowOptionColumnTemplate
      displayDefault={true}
      defaultOptionConfig={{
        hideOpen: true,
        hideView: true,
        modifyOptionText: "Edit",
        onModify: handleReportScheduleEdit,
        useIlapOptionDropdown: true
      }}
      items={items}
    />
  }, [isReportScheduleClosed, lastCompletedBatchStatus, selectedScheduleWithSchedules?.isProcessing,  handleReportScheduleStatusChangeClick, handleReportActivitiesExportClick, handleReportScheduleEdit, showUnsuccessfulOperationModal, showProcessingOperationModal]);

  const titleLabel = useMemo(() => {
    return isReportScheduleClosed ? "Closed" : undefined;
  }, [isReportScheduleClosed]);

  const hideBatchOperationModal = () => setIsBatchOperationModalVisible(false);

  const pageTitle = useMemo(() => {
    let status = undefined;

    const tooltipText = selectedScheduleWithSchedules && BatchOperationUtility.getLastOperationStatusText(selectedScheduleWithSchedules);

    switch (lastCompletedBatchStatus) {
      case ReportScheduleOperationStatus.Successful:
        status = <Circle color={IlapColorVariant.Green5} tooltipText={tooltipText ?? undefined} />;
        break;
      case ReportScheduleOperationStatus.Failed:
        status = <Circle color={IlapColorVariant.Red} tooltipText={tooltipText ?? undefined} />;
        break;
    }

    return <PageTitle
      id={selectedScheduleWithSchedules?.id}
      title={selectedScheduleWithSchedules?.title}
      label={titleLabel}
      menu={kebabMenu}
      status={status}
      isProcessing={selectedScheduleWithSchedules?.isProcessing}
      onProcessingClick={showProcessingOperationModal}
    />
  }, [selectedScheduleWithSchedules, titleLabel, kebabMenu, lastCompletedBatchStatus, showProcessingOperationModal]);

  const widgets = useMemo(() => {
    return <WidgetsWithSeparator widgets={initialWidgets} />
  }, [initialWidgets]);

  const toolbarConfig = useMemo(() => {
    return {
      dislpayToolbar: true,
      dislplaySearchPanel: true,
    }
  }, []);

  const renderRevertCell = useCallback((data: any) => {
    return (
      data.data.revisionTypeText == "Live" ?

        <div className="w-50 pl-2 pr-2 justify-between items-center	justify-items-center flex">
          <div className={`inline`}>{data.data.revisionType === 0 ? data.data.revisionTypeText : "-"}</div>
          {selectedScheduleWithSchedules?.hasPreviousLiveSchedule && data.data.revisionType === 0 && !isReportScheduleClosed ?
            <RevertButton
              onClick={() => handleRevertSchedule(data.data)}
            /> : <></>
          }
        </div>
        :
        <div className="pr-4">
          {isReportScheduleClosed ?
            <div className="pl-2">{data.data.revisionType !== undefined ? allRevisionTypes[data.data.revisionType].name : '-'}</div>
            : <ReportScheduleGridReferenceCellTemplate
              revisionTypes={allRevisionTypes}
              allowedRevisionTypes={data.data.allowedRevisionTypes}
              value={data.data.revisionType}
              title={data.key.description}
              onReferenceValueChange={(val) =>
                updateRevisionReference(data, val)
              }
            />}
        </div>
    );
  }, [selectedScheduleWithSchedules?.hasPreviousLiveSchedule, allRevisionTypes, isReportScheduleClosed, handleRevertSchedule, updateRevisionReference]);

  const informationSection = useMemo(() => {
    if (lastCompletedBatchStatus === ReportScheduleOperationStatus.Failed) {
      return (
        <div className="mt-3">
          <InformationBar
            text="This report schedule has unsuccessful imports."
            action={{
              text: "View unsuccessful imports",
              onClick: showUnsuccessfulOperationModal,
            }}
          />
        </div>
      );
    }

    return <></>

  }, [lastCompletedBatchStatus, showUnsuccessfulOperationModal]);

  const heightCss = lastCompletedBatchStatus === ReportScheduleOperationStatus.Failed ? "h-[calc(100vh-311px)]" : "h-[calc(100vh-249px)]";

  const gridCss = `${heightCss} ${selectedScheduleWithSchedules?.schedules.length === 0 ? "no-schedule-yet" : ""} report-schedule-revision-grid`;

  return (
    <div className="mx-14">
      <div className="mb-5 mt-px font-poppins text-[18px] text-dark-blue-1 font-semibold leading-normal flex">
        {pageTitle}
      </div>
      <div>{widgets}</div>
      <div>{informationSection}</div>

      <IlapTabPanelSlim className="ilap-tab-panel-slim report-schedule-tab-panel">
        <Item title={"Schedules"}>
          <IlapViewGrid
            className={gridCss}
            dataSource={selectedScheduleWithSchedules?.schedules}
            toolbarConfig={toolbarConfig}
            columnMinWidth={50}
          >
            <Column
              caption={"Title"}
              dataField="description"
              alignment="left"
              allowSorting={false}
              width={"24%"}
            />
            <Column
              allowSorting={true}
              caption={"Revision"}
              dataField="revisionTypeText"
              alignment="left"
              minWidth={100}
            />

            <Column
              cssClass={"reference-column"}
              caption={"Reference"}
              dataField="revisionType"
              width={250}
              allowFiltering={false}
              alignment="left"
              cellRender={renderRevertCell}
              dataType={"datetime"}
            />
            <Column
              allowSorting={true}
              sortOrder={"desc"}
              caption={"Submitted timestamp"}
              dataField="submittedDate"
              allowSearch={false}
              width={200}
              alignment="left"
              dataType={"datetime"}
              customizeText={getCellValueOrDefault}
            />
            <Column
              caption={"Cut-off date"}
              dataField="cutoffDate"
              allowSearch={false}
              alignment="left"
              dataType={"datetime"}
              width={200}
              customizeText={getCellValueOrDefault}
            />
            <Column
              caption={"# Act"}
              dataField="activitiesCount"
              alignment="left"
              customizeText={getRoundedLocaleNumber}
            />
            <Column
              caption={"Total hours"}
              dataField="totalHours"
              alignment="left"
              customizeText={getRoundedLocaleNumber}
            />
            <Column
              caption={"Actual hours"}
              dataField="actualHours"
              alignment="left"
              customizeText={getRoundedLocaleNumber}
            />
            <Column
              caption={"Earned hours"}
              dataField="earnedHours"
              alignment="left"
              customizeText={getRoundedLocaleNumber}
            />
          </IlapViewGrid>
        </Item>
        <Item title={"Report schedule info"}>
          {params.id &&
            <div className={heightCss}>
              <ReportScheduleInformationView reportScheduleId={Number(params.id)} />
            </div>
          }
        </Item>
      </IlapTabPanelSlim>

      {isScheduleModalVisible && selectedSchedule && (
        <ScheduleModal
          schedule={selectedSchedule}
          onClose={() => setIsScheduleModalVisible(false)}
          onDelete={handleScheduleDelete}
        />
      )}

      {isRevertConfirmationDialogVisible && (
        <IlapConfirmationDialog
          height="181px"
          width="360px"
          onConfirm={handleRevertConfirm}
          onCancel={() => setIsRevertConfirmationDialogVisible(false)}
          content="Are you sure?"
          subContent={`Do you want to revert to previous ${TextUtility.truncate(reportScheduleRevertRequest.revisionTypeText, 35)} revision?`}
          cancelButtonText="No"
          confirmButtonText="Yes"
        />
      )}

      {isConfirmationDialogVisible &&
        <IlapConfirmationDialog
          height={confirmationDialogInfo?.height ?? "181px"}
          width="456px"

          content={confirmationDialogInfo?.content}
          subContent={confirmationDialogInfo?.subContent}
          cancelButtonText={confirmationDialogInfo?.cancelButtonText}
          confirmButtonText={confirmationDialogInfo?.confirmButtonText}

          onConfirm={confirmationDialogInfo?.onConfirm}
          onCancel={confirmationDialogInfo?.onCancel}
        />
      }

      {isBatchOperationModalVisible && (
        <BatchOperationModal
          reportScheduleId={Number(params.id)}
          reportScheduleTitle={selectedScheduleWithSchedules?.title ?? ""}
          category={batchOperationCategory}
          onHideDialog={hideBatchOperationModal}
        />
      )}

    </div>
  );
}
