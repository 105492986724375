import RoundTickIcon from "components/common/icons/GreenTickIcon";
import RoundIcon from "components/common/icons/RoundIcon";
import BackgroundJobResponse from "interfaces/response/BackgroundJobResponse"
import { useMemo } from "react";
import { ReportScheduleOperationStatus } from "shared/enums/ReportScheduleOperationStatus";
import { BatchOperationUtility } from "shared/utilities/BatchOperationUtility";

interface Props {
  jobIndex: number,
  job: BackgroundJobResponse
}

export default function BackgroundJob(props: Props) {
  const { jobIndex, job } = props;

  const jobIndexIcon = useMemo(() => {
    switch(job.status) {
      case ReportScheduleOperationStatus.Successful:
        return <RoundTickIcon />;
      case ReportScheduleOperationStatus.Failed:
        return <RoundIcon className="bg-red" />
      case ReportScheduleOperationStatus.Running:
        return <RoundIcon className="bg-blue-500" text={jobIndex} />;
      default:
        return <RoundIcon className="bg-gray-50" text={jobIndex} />;
    }
  }, [jobIndex, job.status]);

  return (
    <div className="flex items-center gap-x-[9px] text-black ml-9">
      <div>{jobIndexIcon}</div>
      <div className="w-[280px] text-base">{BatchOperationUtility.getBackgroundJobName(job.jobCode)}</div>
      <div className="text-base">{BatchOperationUtility.getBackgroundJobStatus(job)}</div>
    </div>
  )
}