export enum ReportScheduleJobCode {
  CreateData = 10,
  GenerateReportActivities = 20,
  GenerateReportMetadata = 30,
  PeriodizeReportSchedule = 40,
  CalculateActuals = 50,
  SetExtendedProperties = 60,
  MoveAndClearIlapFileContent = 70,
  RemoveOrphanReportActivities = 80,
  RevertActuals = 90
}