import LabelWithAnnotation from "components/common/LabelWithAnnotation";
import ReportScheduleMetadataFieldValue from "interfaces/response/ReportScheduleMetadataFieldValue";

interface Props {
  fieldValue: ReportScheduleMetadataFieldValue
}

export default function ReportScheduleField(props: Props) {

  const { fieldValue } = props;

  return (
    <div className="truncate">
      <LabelWithAnnotation label = {fieldValue.name} />
      <div className="font-poppins ml-2 mt-3 truncate" title={fieldValue.value}>{fieldValue.value ? fieldValue.value : "-"}</div>
    </div>
  )
}