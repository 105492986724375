import { unwrapResult } from "@reduxjs/toolkit";
import { IlapButton, IlapButtonType, IlapTextBox } from "ilap.common.webcomponents.test";
import IlapConfirmationDialog from "components/common/controls/IlapConfirmationDialog";
import { PlanningObjectTypes } from "shared/enums/PlanningObjectTypesEnum";
import { displayLoadingPanel, displayGridLoadingPanel, hideLoadingPanel } from "components/common/LoadingPanel";
import ScheduleResponse from "interfaces/response/ScheduleResponse";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, NavigateOptions } from "react-router-dom";
import { REPORT_SCHEDULES } from "shared/constants/RoutePathConstants";
import { getRevisionTypeValuesAsync } from "store/action/DropdownValueActions";
import MetadataField from "interfaces/response/MetadataField";
import {
    loadReportScheduleWithSchedulesAsync,
    loadSingleReportScheduleAsync,
    updateReportSchedule,
    deleteReportSchedule,
} from "store/action/ReportScheduleActions";
import { ValidationRequirement } from "shared/enums/ValidationRequirementEnum";
import { loadSingleReportScheduleTypeAsync } from "store/action/ReportScheduleTypeActions";
import { AppDispatch, RootState } from "store/store";
import ReportScheduleType from "interfaces/response/ReportScheduleType";
import "./styles/ReportScheduleRevisionGrid.css";
import "./styles/ReportScheduleTabPanel.css";
import { RevisionType } from "shared/enums/RevisionTypeEnum";
import ReportScheduleResponse from "interfaces/response/ReportScheduleResponse";
import ReportScheduleMetadataFieldValue from "interfaces/response/ReportScheduleMetadataFieldValue";
import MetadataFieldValue from "interfaces/common/MetadataFieldValue";
import ReportScheduleRequest from "interfaces/request/ReportScheduleRequest";
import { Type } from "shared/enums/TypeEnum";
import { isNullOrEmpty } from "shared/utilities/CommonUtility";
import "../../components/common/styles/PageHeaderTitle.css";
import PageTitle from "components/common/PageTitle";
import WidgetsWithSeparator from "components/common/WidgetsWithSeparator";
import InfoWidget from "components/common/InfoWidget";
import { toastSuccess } from "shared/utilities/ToastUtility";
import ValidatedContent from "components/common/ValidatedContent";
import { addBlankValue, createEmptyReportSchedule } from "./ReportScheduleUtility";
import NavigationBlocker from "features/common/NavigationBlocker";
import { TextUtility } from "shared/utilities/TextUtility";
import IlapTabPanelSlim from "components/common/controls/IlapTabPanelSlim";
import { Item } from "devextreme-react/tab-panel";
import ReportScheduleInformationEdit from "./edit/ReportScheduleInformationEdit";

const emptyReportSchedule: ReportScheduleResponse = createEmptyReportSchedule();

interface Props { }

export default function ReportSchedulesEditSchedule(props: Props) {
    const dispatch = useDispatch<AppDispatch>();
    const params = useParams();
    const navigate = useNavigate();
    const [isDeleteConfirmationDialogVisible, setIsDeleteConfirmationDialogVisible] = useState(false);
    const [isCancelConfirmationDialogVisible, setIsCancelConfirmationDialogVisible] = useState(false);
    const [isSaveConfirmationDialogVisible, setIsSaveConfirmationDialogVisible] = useState(false);
    const [reportScheduleMetadataFieldValueSource, setReportScheduleMetadataFieldValueSource] = useState<ReportScheduleMetadataFieldValue[]>([]);
    const [additionalFieldValues, setAdditionalFieldValues] = useState<ReportScheduleMetadataFieldValue[]>([]);
    const [selectedReportSchedule, setSelectedReportSchedule] = useState<ReportScheduleResponse>(emptyReportSchedule);
    const [reportScheduleInput, setReportScheduleInput] = useState<ReportScheduleResponse>(emptyReportSchedule);
    const [selectedSchedules, setSelectedSchedules] = useState<ScheduleResponse[]>([]);
    const [metadataFields, setMetadataFields] = useState<MetadataField[]>([]);
    const [isInternalIdMissing, setIsInternalIdMissing] = useState(false);
    const [isTitleMissing, setIsTitleMissing] = useState(false);
    const [isModified, setIsModified] = useState(false);
    const [isNavBlockerActive, setIsNavBlockerActive] = useState<boolean>(true);
    const [isConfirmationInputMatched, setIsConfirmationInputMatched] = useState<boolean | undefined>(undefined);

    const selectedScheduleWithSchedules = useSelector(
        (state: RootState) => {
            return state.reportScheduleData.selectedReportScheduleWithSchedules;
        }
    );

    useEffect(() => {
        dispatch(
            loadSingleReportScheduleAsync({ reportScheduleId: Number(params.id) })
        )
            .then(unwrapResult)
            .then((reportSchedule: ReportScheduleResponse) => {
                setSelectedReportSchedule(reportSchedule);
                setReportScheduleInput(reportSchedule);
                setSelectedSchedules(reportSchedule.schedules);
            });

    }, []);

    useEffect(() => {
        // Avoid loading metadata for un-initialized reportScheduleTypeId
        if (reportScheduleInput.reportScheduleTypeId === emptyReportSchedule.reportScheduleTypeId) {
            return;
        }
        displayGridLoadingPanel();
        dispatch(
            loadSingleReportScheduleTypeAsync({
                reportScheduleTypeId: reportScheduleInput.reportScheduleTypeId,
            })
        )
            .then(unwrapResult)
            .then((reportScheduleType: ReportScheduleType) => {
                setMetadataFields(reportScheduleType?.metadataFields);
            })
            .finally(hideLoadingPanel);
    }, [dispatch, reportScheduleInput.reportScheduleTypeId]);

    useEffect(() => {
        const reportScheduleMetadataFieldValue: ReportScheduleMetadataFieldValue[] = [];

        for (let metadataFieldIndex = 0; metadataFieldIndex < metadataFields?.length; metadataFieldIndex++) {
            let metadataField = metadataFields[metadataFieldIndex];

            if (metadataField.planningObjectTypes === PlanningObjectTypes.Schedule) {
                reportScheduleMetadataFieldValue.push({
                    metadataFieldId: metadataField.id,
                    name: metadataField.name,
                    isReadOnly: metadataField.receivedFromSchedule,
                    id: 0,
                    displayDropdown: metadataField.validationRequirement === ValidationRequirement.ValueInList || metadataField.validationRequirement === ValidationRequirement.ValueInListOrBlank,
                    dropdownSource: metadataField.validationRequirement === ValidationRequirement.ValueInListOrBlank ?
                        addBlankValue(metadataField.metadataFieldValues, metadataField.id) : metadataField.metadataFieldValues,
                    isRequired: metadataField.validationRequirement === ValidationRequirement.ValueInList || metadataField.validationRequirement === ValidationRequirement.NonBlankValue
                });
            }
        }
        reportScheduleInput.metadataFieldValues.forEach((fieldValues) => {
            const metadataFieldValueIndex =
                reportScheduleMetadataFieldValue.findIndex(
                    (metadata: ReportScheduleMetadataFieldValue) =>
                        metadata.metadataFieldId === fieldValues.metadataFieldId
                );

            if (metadataFieldValueIndex > -1) {
                const reportScheduleMetadata = reportScheduleMetadataFieldValue[metadataFieldValueIndex];
                if (reportScheduleMetadata.displayDropdown && !reportScheduleMetadata.isRequired && fieldValues.stringValue?.length === 0) {
                    reportScheduleMetadata.value = "-";
                    reportScheduleMetadata.id = 0;
                }
                else {
                    reportScheduleMetadata.value = fieldValues.stringValue;
                    reportScheduleMetadata.id = fieldValues.id;
                }
            }
        });
        setReportScheduleMetadataFieldValueSource(reportScheduleMetadataFieldValue);

        // set additional field values
        const additionalFieldValues = reportScheduleInput.metadataFieldValues
            .filter(value => !metadataFields?.some(field => field.id === value.metadataFieldId))
            .map(fieldValue => ({
                id: fieldValue.id,
                metadataFieldId: fieldValue.metadataFieldId,
                name: fieldValue.name ?? "Additional field",
                value: fieldValue.value,
                displayDropdown: false,
                dropdownSource: [],
                isReadOnly: true,
            }));

        setAdditionalFieldValues(additionalFieldValues);

    }, [selectedReportSchedule, metadataFields, reportScheduleInput.metadataFieldValues]);

    useEffect(() => {
        displayGridLoadingPanel();
        dispatch(getRevisionTypeValuesAsync())
            .finally(hideLoadingPanel);
    }, [dispatch]);

    useEffect(() => {
        if (params.id) {
            displayGridLoadingPanel();
            dispatch(loadReportScheduleWithSchedulesAsync(Number(params.id)))
                .then(unwrapResult)
                .finally(hideLoadingPanel);
        }
    }, [params, dispatch]);

    const initialWidgets = [
        <ValidatedContent showValidationMessage={isInternalIdMissing}>
            <InfoWidget label="Internal ID" data={
                <IlapTextBox
                    placeholder="Type..."
                    width={200}
                    value={reportScheduleInput?.internalId}
                    className={`pageHeaderTitle-widget-input`}
                    validationStatus={isInternalIdMissing ? "invalid" : "valid"}
                    onChange={({ event }: any) => handleValueChange("internalId", event.currentTarget.value)}
                />
            } />
        </ValidatedContent>,
        <InfoWidget label="Schedule level" data={reportScheduleInput?.reportScheduleType?.planningLevelText} />,
        <InfoWidget label="Schedule type"
            data={reportScheduleInput?.reportScheduleType?.title}
            truncate={true}
            minWidth="150px"
        />,
        <InfoWidget label="Points in time type" data={reportScheduleInput?.pointsInTimeTypeText} />,
        <div className="flex space-x-2 items-center w-full">
            <InfoWidget label="Description" width="100%" minWidth="200px" data={
                <IlapTextBox
                    placeholder="Type..."
                    width={"100%"}
                    value={reportScheduleInput?.description}
                    className="pageHeaderTitle-widget-input"
                    onChange={({ event }: any) => handleValueChange("description", event.currentTarget.value)}
                />
            } />
        </div>
    ];
    const checkIfRequiredFieldsAreFilled = () => {
        let isRequiredFieldsAreFilled = true;

        if (isNullOrEmpty(reportScheduleInput.internalId)) {
            setIsInternalIdMissing(true);
            isRequiredFieldsAreFilled = false;
        }
        if (isNullOrEmpty(reportScheduleInput.title)) {
            setIsTitleMissing(true);
            isRequiredFieldsAreFilled = false;
        }
        const modifiedReportScheduleMetadataFieldValue = [
            ...reportScheduleMetadataFieldValueSource,
        ];

        for (var element of modifiedReportScheduleMetadataFieldValue) {
            if (!element.isReadOnly && element.isRequired && isNullOrEmpty(element.value)) {
                element.isMissingRequired = true;
                setReportScheduleMetadataFieldValueSource(modifiedReportScheduleMetadataFieldValue);
                isRequiredFieldsAreFilled = false;
            }
        }
        return isRequiredFieldsAreFilled;
    };

    const handleDropdownSelectionChange = (selectedItem: MetadataFieldValue) => {
        handleMetadataFieldValueChange(
            selectedItem.metadataFieldId,
            (selectedItem.stringValue === "-") ? "" : selectedItem.stringValue,
            selectedItem.id
        );
    };

    const handleMetadataFieldValueChange = (metadataFieldId: number, value: string, id?: number) => {
        const existingIndex = reportScheduleMetadataFieldValueSource.findIndex(
            (fieldValue: ReportScheduleMetadataFieldValue) =>
                fieldValue.metadataFieldId === metadataFieldId
        );

        if (existingIndex > -1) {
            const modifiedreportScheduleMetadataFieldValue = [
                ...reportScheduleMetadataFieldValueSource,
            ];

            if (
                modifiedreportScheduleMetadataFieldValue[existingIndex].id !== id ||
                modifiedreportScheduleMetadataFieldValue[existingIndex].value !== value
            ) {
                if (modifiedreportScheduleMetadataFieldValue[existingIndex].id !== id || !modifiedreportScheduleMetadataFieldValue[existingIndex].displayDropdown) {
                    setIsModified(true);
                }
                modifiedreportScheduleMetadataFieldValue[existingIndex].id = id;
                modifiedreportScheduleMetadataFieldValue[existingIndex].value = value;
                modifiedreportScheduleMetadataFieldValue[existingIndex].isMissingRequired = false;
                setReportScheduleMetadataFieldValueSource(modifiedreportScheduleMetadataFieldValue);
            }
        }
    };

    const navigateToPage = (path: string, options?: NavigateOptions) => {
        setIsNavBlockerActive(false);
        navigate(path, options);
    }

    const handleConfirmationInputChanged = (input: string) => {
        if (TextUtility.isEqualIgnoreCase(input, selectedScheduleWithSchedules?.title ?? "")) {
            setIsConfirmationInputMatched(true);
        }
        else {
            setIsConfirmationInputMatched(false);
        }
    }

    const handleDeleteConfirm = () => {
        if (!isConfirmationInputMatched) {
            return;
        }

        displayLoadingPanel();
        dispatch(
            deleteReportSchedule({
                reportScheduleId: reportScheduleInput.id,
            })
        )
            .then((response: any) => {
                setIsDeleteConfirmationDialogVisible(false);
                if (response?.error == undefined) navigateToPage(REPORT_SCHEDULES, { replace: true });
            })
            .finally(hideLoadingPanel);
    };

    const handleDeleteCancel = () => {
        setIsConfirmationInputMatched(undefined);
        setIsDeleteConfirmationDialogVisible(false);
    }

    const getReportScheduleRequestModelFromInput = (): ReportScheduleRequest => {
        const reportScheduleMetadataFieldValues: MetadataFieldValue[] = [];

        reportScheduleMetadataFieldValueSource.forEach((metadataFieldValue) => {
            reportScheduleMetadataFieldValues.push({
                id: 0,
                type: Type.String,
                metadataFieldId: metadataFieldValue.metadataFieldId,
                stringValue:
                    metadataFieldValue.value !== null ? metadataFieldValue.value! : "",
            });
        });

        const reportScheduleRequest: ReportScheduleRequest = {
            id: reportScheduleInput.id,
            title: reportScheduleInput.title,
            internalId: reportScheduleInput.internalId,
            description: reportScheduleInput.description,
            pointsInTimeType: reportScheduleInput.pointsInTimeType,
            reportScheduleTypeId: reportScheduleInput.reportScheduleTypeId,
            metadataFieldValues: reportScheduleMetadataFieldValues,
            scheduleId: getScheduleId(RevisionType.Live),
            baselineScheduleId: getScheduleId(RevisionType.Baseline),
            originalBaselineScheduleId: getScheduleId(RevisionType.OriginalBaseline),
            currentScheduleId: getScheduleId(RevisionType.Current),
        };
        return reportScheduleRequest;
    };

    const getScheduleId = (revisionType: RevisionType) => {
        return selectedScheduleWithSchedules?.schedules?.find(sch => sch.revisionType == revisionType)?.id
    }

    const handleSaveChangesClick = () => {

        if (!checkIfRequiredFieldsAreFilled()) return;

        if (!isModified)
            toastSuccess("Saved, no new changes.");
        else
            setIsSaveConfirmationDialogVisible(true);
    };

    const handleSaveChangesConfirmedClick = () => {
        const reportScheduleRequest = getReportScheduleRequestModelFromInput();

        selectedSchedules?.forEach((schedule) => {
            if (schedule.revisionType === RevisionType.Live) {
                reportScheduleRequest.scheduleId = schedule.id;
            } else if (schedule.revisionType === RevisionType.Baseline) {
                reportScheduleRequest.baselineScheduleId = schedule.id;
            } else if (schedule.revisionType === RevisionType.OriginalBaseline) {
                reportScheduleRequest.originalBaselineScheduleId = schedule.id;
            } else if (schedule.revisionType === RevisionType.Current) {
                reportScheduleRequest.currentScheduleId = schedule.id;
            }
        });
        displayLoadingPanel();
        dispatch(
            updateReportSchedule({
                reportScheduleId: reportScheduleRequest.id,
                reportScheduleRequest: reportScheduleRequest,
            })
        )
            .then(unwrapResult)
            .then((response: ReportScheduleResponse) => {
                setReportScheduleInput(response);
            })
            .then(() => {
                setIsSaveConfirmationDialogVisible(false);
                navigateToPage(`${REPORT_SCHEDULES}/${reportScheduleRequest.id}/schedules`);
            })
            .finally(hideLoadingPanel);
    };

    const handleValueChange = (fieldName: string, value: any) => {
        setReportScheduleInput({
            ...reportScheduleInput,
            [fieldName]: value,
        });

        if (fieldName === "internalId") {
            setIsInternalIdMissing(false);
        }
        else if (fieldName === "title") {
            setIsTitleMissing(false);
        }
        setIsModified(true);
    }

    const handleCancelConfirmClick = () => {
        setIsCancelConfirmationDialogVisible(false);
        navigateToPage(`${REPORT_SCHEDULES}/${params.id}/schedules`);
    }

    const handleCancelClick = () => {
        if (isModified)
            setIsCancelConfirmationDialogVisible(true);
        else navigateToPage(`${REPORT_SCHEDULES}/${params.id}/schedules`);
    }

    const hasNavigationBlockerPreCondition = useCallback(() => {
        let shouldBlockNavigation = false;
        // We are calling the setState method to get the latest state of 'isNavBlockerActive', 
        // this can be stale due to React update cycle behavior
        setIsNavBlockerActive(prev => {
            shouldBlockNavigation = prev; // getting latest state
            return prev;
        });

        if (!shouldBlockNavigation) {
            return false;
        }

        return isModified;

    }, [isNavBlockerActive, isModified]);

    const handleAndDisableCopy = (e: any) => {
        e.preventDefault();
    };

    const getDeleteConfirmationSubContent = useCallback(() => {
        return (
            <div className="text-gray-9 leading-5" onCopy={(handleAndDisableCopy)}>
                <div>This action cannot be undone. You will permanently lose the report schedule and its related data:</div>
                <ul className="pt-6 list-disc ml-6">
                    <li>Imported planning schedules</li>
                    <li>Settings</li>
                    <li>Archived report schedules</li>
                </ul>
                <div className="pt-6 font-medium">Confirm by typing the name of the report schedule:</div>
                <div className="font-medium text-red truncate">{selectedScheduleWithSchedules?.title}</div>
            </div>
        )
    }, [selectedScheduleWithSchedules]);

    return (
        <div className="mx-14">
            <NavigationBlocker preCondition={hasNavigationBlockerPreCondition} warningMessage="The changes you’ve made to the report schedule will not be saved." />
            <div className="mb-3 -mt-0.5 font-poppins text-[18px] text-dark-blue-1 font-semibold leading-normal flex">
                <ValidatedContent showValidationMessage={isTitleMissing} wrapperWidth="100%">
                    <PageTitle id={selectedScheduleWithSchedules?.id}
                        titleClass="w-full"
                        title={
                            <IlapTextBox
                                placeholder="Type..."
                                width={"100%"}
                                value={reportScheduleInput?.title}
                                className={`pageHeaderTitle-input`}
                                validationStatus={isTitleMissing ? "invalid" : "valid"}
                                onChange={({ event }: any) => handleValueChange("title", event.currentTarget.value)}
                            />
                        } />
                </ValidatedContent>
            </div>
            <div>
                <WidgetsWithSeparator widgets={initialWidgets} />
            </div>
            <hr className="mt-[15px] mb-4 border-light-gray" />

            <div className="w-auto h-[calc(100vh-275px)]">
            <IlapTabPanelSlim
                    height={"96%"}
                    className="ilap-tab-panel-slim report-schedule-tab-panel">
                    <Item title={"Report schedule info"}>
                        {params.id && 
                        <ReportScheduleInformationEdit 
                            definedFieldValues={reportScheduleMetadataFieldValueSource}
                            additionalFieldValues={additionalFieldValues}
                            handleDropdownSelectionChange={handleDropdownSelectionChange}
                            handleMetadataFieldValueChange={handleMetadataFieldValueChange}
                        />}
                    </Item>
                </IlapTabPanelSlim>
            </div>
            <div className="mb-4 pt-4 border-t-light-gray border-t h-[82px] border-solid">
                <IlapButton
                    className="float-left"
                    text="Delete report schedule"
                    variant={IlapButtonType.Tertiary}
                    onClick={() => {
                        setIsDeleteConfirmationDialogVisible(true);
                    }}
                />
                <IlapButton
                    className="ml-3 float-right"
                    text="Save changes"
                    variant={IlapButtonType.Primary}
                    onClick={handleSaveChangesClick}
                />
                <IlapButton
                    className="float-right"
                    text="Cancel"
                    variant={IlapButtonType.Secondary}
                    onClick={handleCancelClick}
                /></div>

            {isDeleteConfirmationDialogVisible && (
                <IlapConfirmationDialog
                    isDeleteConfirm={true}
                    height={"375px"}
                    onConfirm={handleDeleteConfirm}
                    onCancel={handleDeleteCancel}
                    content="Delete report schedule?"
                    subContent={getDeleteConfirmationSubContent()}
                    showInput={true}
                    disablePaste={true}
                    onInputChanged={handleConfirmationInputChanged}
                    inputValidationStatus={isConfirmationInputMatched === false ? "invalid" : "valid"}
                    cancelButtonText="Cancel"
                    confirmButtonText="Delete permanently"
                    isConfirmButtonDisabled={!isConfirmationInputMatched}
                />
            )}

            {isCancelConfirmationDialogVisible && (
                <IlapConfirmationDialog
                    onConfirm={handleCancelConfirmClick}
                    onCancel={() => setIsCancelConfirmationDialogVisible(false)}
                    content="Cancel changes"
                    subContent={`The changes you’ve made to the report schedule will not be saved.`}
                    cancelButtonText="Continue editing"
                    confirmButtonText="Confirm"
                />
            )}

            {isSaveConfirmationDialogVisible && (
                <IlapConfirmationDialog
                    onConfirm={handleSaveChangesConfirmedClick}
                    onCancel={() => setIsSaveConfirmationDialogVisible(false)}
                    content="Save changes"
                    subContent={`You have made changes to ${selectedScheduleWithSchedules?.title}. Confirm to save changes.`}
                    cancelButtonText="Continue editing"
                    confirmButtonText="Confirm change"
                />
            )}

        </div>
    );
}
