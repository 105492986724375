import { createSlice } from "@reduxjs/toolkit";
import ApiVersionResponse from "interfaces/response/ApiVersionResponse";
import { loadApiVersionAsync } from "store/action/ApiVersionActions";

export interface ApiVersionState {
  apiVersion?: ApiVersionResponse
}

const initialState: ApiVersionState = {
  apiVersion: undefined
}

export const apiVersionSlice = createSlice({
  name: "apiVersionSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      loadApiVersionAsync.fulfilled,
      (state, { payload }: { payload: ApiVersionResponse }) => {
        state.apiVersion = payload;
      }
    );
  }
});