import AnalyticsIcon from "components/common/icons/AnalyticsIcon";
import Account from "features/account";
import Information from "features/account/Information";
import NavItemOption from "interfaces/NavItemOption";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as routePath from "shared/constants/RoutePathConstants";
import { AuthenticationRole } from "shared/enums/AuthenticationRole";
import { loadCurrentUserAuthProfileAsync } from "store/action/UserActions";
import { useAppDispatch } from "store/hooks";
import { RootState } from "store/store";
import MobileNavigation from "./MobileNavigation";
import NavigationItems from "./NavigationItems";

export default function NavigationBar() {
  const profile = useSelector((state: RootState) => state.userData.profile);

  const dispatch = useAppDispatch();
  
  useEffect(() => {
    if (!profile) {
      dispatch(loadCurrentUserAuthProfileAsync());
    }
  }, [dispatch, profile]);

  const navItems: NavItemOption[] = [
    {
      text: "Report schedules",
      route: routePath.REPORT_SCHEDULES,
      itemId: "report-schedules",
    },
    {
      text: "Schedule types",
      route: routePath.SCHEDULE_TYPES,
      itemId: "schedule-types",
    },
    {
      text: "Fields",
      route: routePath.FIELDS,
      itemId: "fields",
    },
    {
      text: "Admin",
      route: routePath.ADMIN,
      itemId: "admin",
      shouldBeVisible: () => checkIfUserRoleExists(AuthenticationRole.Administrator),
    }
  ];

  const location = useLocation();
  const [
    isMobileNavigationMenuIconVisible,
    setIsMobileNavigationMenuIconVisible,
  ] = useState(false);

  const handleMobileNavigationMenuClick = () => {
    setIsMobileNavigationMenuIconVisible(!isMobileNavigationMenuIconVisible);
  };

  const handleMobileNavigationPopOverHiding = () => {
    setIsMobileNavigationMenuIconVisible(false);
  };

  const checkIfUserRoleExists = useCallback((role: AuthenticationRole) => {
    return profile?.roles.includes(role) ?? false;
  }, [profile]);

  const visibleNavItems = navItems.filter((navItem) => !navItem.shouldBeVisible || navItem.shouldBeVisible());

  return (
    <div>
      <nav className="bg-dark-blue-1 text-white pl-9 pr-6 h-48px flex">
        <div className="flex-1 flex items-center justify-between">
          <div className=" inline md:hidden flex-shrink-0 items-center">
            <div
              onClick={handleMobileNavigationMenuClick}
              id="mobile-navigation"
              className={`${isMobileNavigationMenuIconVisible ? `bg-white` : `bg-grey-300`
                } p-2 rounded-md text-grey-700 hover:text-gray-2`}
            >
              <div
                className={`${isMobileNavigationMenuIconVisible ? `text-blue-900` : ``
                  }`}
              >
                <i className="dx-icon-menu"></i>
              </div>
              <MobileNavigation
                target="#mobile-navigation"
                visible={isMobileNavigationMenuIconVisible}
                onHiding={handleMobileNavigationPopOverHiding}
                navigationItems={visibleNavItems}
              />
            </div>
          </div>
          <div className="flex-shrink-0 w-auto">
            <AnalyticsIcon />
          </div>
          <div className="hidden md:block flex items-start w-full mx-4 h-full">
            <div className="float-left h-full">
              <NavigationItems navItems={visibleNavItems} />
            </div>
          </div>
          <div className="w-auto flex items-center">
            <div className="mr-[22px]">
              <Information />
            </div>
            <div>
              <Account />
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
