import TextButton from "./buttons/TextButton"
import LoadingIconSmall from "./icons/LoadingIconSmall"

interface Props{
  onProcessingClick: () => void,
  loadingIconCss?: string
}

export default function ProcessingLabel(props: Props) {
  return (
    <div className="flex items-center">
      <LoadingIconSmall additionalClass={props.loadingIconCss} />
      <TextButton text="Processing" onClick={props.onProcessingClick} />
    </div>
  )
}