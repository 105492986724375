import { displayLoadingPanel, hideLoadingPanel } from "components/common/LoadingPanel";
import AddToListButton from "components/common/buttons/AddToListButton";
import RemoveFromListButton from "components/common/buttons/RemoveFromListButton";
import DataGrid, { Column, Selection } from "devextreme-react/data-grid";
import { IlapSelectBox, IlapViewGrid } from "ilap.common.webcomponents.test";
import { MetadataFieldInfo } from "interfaces/common/MetadataFieldInfo";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PropertyState } from "shared/enums/PropertyStateEnum";
import { getValidationRequirementValuesAsync } from "store/action/DropdownValueActions";
import { AppDispatch, RootState } from "store/store";
import "./styles/FieldSelectorPanel.css";
import { toastSuccess } from "shared/utilities/ToastUtility";
import { ReceivedFrom } from "shared/enums/ReceivedFromEnum";
import { FieldTypeEnum } from "shared/enums/FieldTypeEnum";
import { FieldUtility } from "shared/utilities/FieldUtility";

interface Props {
  fieldsName: FieldTypeEnum,
  availableFields: MetadataFieldInfo[],
  selectedFields: MetadataFieldInfo[],
  gridHeight?: string,
  onAddFields: (fields: MetadataFieldInfo[]) => void;
  onRemoveFields: (fields: MetadataFieldInfo[]) => void;
  onContentControlChange: (field: MetadataFieldInfo, value: number) => void;
  onReceivedFromIlapScheduleChange?: (field: MetadataFieldInfo, value: ReceivedFrom) => void;
}

export type FieldSelectorPanelType = {
  validateContentControl: () => boolean;
}

interface ReceivedFromOption {
  value: ReceivedFrom,
  name: string
}

const FieldSelectorPanel = forwardRef<FieldSelectorPanelType, Props>((props, ref) => {
  const { fieldsName, availableFields, selectedFields, gridHeight, onAddFields, onRemoveFields, onContentControlChange, onReceivedFromIlapScheduleChange } = props;
  const isActivityField = (fieldsName === FieldTypeEnum.activity);
  const [isValidationEnabled, setIsValidationEnabled] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  useImperativeHandle(ref, () => ({
    validateContentControl
  }));

  const availableFieldsGridRef = useRef<DataGrid>(null);
  const selectedFieldsGridRef = useRef<DataGrid>(null);

  // Populate Content Control Values
  const contentControlValues = useSelector(
    (rootStore: RootState) => rootStore.dropdownData.validationRequirement
  );

  const receivedFromOptions : ReceivedFromOption[] = useMemo(() => {
    return [
      {
        value: ReceivedFrom.Manual,
        name: "Manual"
      },
      {
        value: ReceivedFrom.IlapSchedule,
        name: "ILAP schedule"
      }
    ]
  }, []);

  useEffect(() => {
    if (contentControlValues.length === 0) {
      displayLoadingPanel();
      dispatch(getValidationRequirementValuesAsync()).finally(hideLoadingPanel);
    }
  }, [dispatch, contentControlValues.length]);

  //#region Functions 
  const handleAddClick = () => {
    const selectedFields = availableFieldsGridRef.current?.instance.getSelectedRowsData() as MetadataFieldInfo[];

    if (selectedFields.length === 0) {
      toastSuccess("No fields selected");
      return;
    }
    // Set receivedFromSchedule to true for activity fields
    if(isActivityField){       
      selectedFields.forEach(f => {f.receivedFromSchedule = true});
    }

    onAddFields(selectedFields);
  }

  const handleRemoveClick = () => {
    const selectedFields = selectedFieldsGridRef.current?.instance.getSelectedRowsData() as MetadataFieldInfo[];

    if (selectedFields.length === 0) {
      toastSuccess("No fields selected");
      return;
    }

    onRemoveFields(selectedFields);
  }

  const handleContentControlChange = useCallback((field: MetadataFieldInfo, value: number) => {
    onContentControlChange(field, value);
  }, [onContentControlChange]);

  const validateContentControl = (): boolean => {
    const unSetFieldExists = selectedFields.some(field => field.validationRequirement === PropertyState.Unset || field.receivedFromSchedule === PropertyState.Unset);

    if (unSetFieldExists) {
      setIsValidationEnabled(true);
    }

    return !unSetFieldExists;
  }
  //#endregion

  const availableFieldsGridTitle = useMemo(() => {
    return <div className="font-semibold">Available {fieldsName} fields</div>;
  }, [fieldsName]);
  
  const selectedFieldsGridTitle = useMemo(() => {
    return <div className="font-semibold">Added {fieldsName} fields ({selectedFields.length})</div>;
  }, [fieldsName, selectedFields]);

  const availableFieldsGridCss = `available-fields-grid available-${fieldsName}-fields-grid`;
  const selectedFieldsGridCss = `selected-fields-grid selected-${fieldsName}-fields-grid`;

  const fieldTitleCellTemplate = (element: HTMLDivElement, info: { data: MetadataFieldInfo }) => {
    element.textContent = info.data.name;
    if (!info.data.isSaved) {
      element.style.color = "#3459DB";
    }
  }

  const contentControlComponent = useCallback((data: any) => {
    const field = data.data.data as MetadataFieldInfo;
    const validationStatus = (isValidationEnabled && field.validationRequirement === PropertyState.Unset) ? "invalid" : "valid";
    const items = FieldUtility.getValidationRequirementOptions(contentControlValues, field.type);

    return <div>
      <IlapSelectBox
        fieldName="Select"
        placeholder="Select"
        items={items}
        value={field.validationRequirement}
        displayExpr={"name"}
        valueExpr={"value"}
        validationStatus={validationStatus}
        onValueChange={(value: number) => handleContentControlChange(field, value)} />
    </div>
  }, [contentControlValues, isValidationEnabled, handleContentControlChange]);

  const receivedFromComponent = useCallback((data: any) => {
    const field = data.data.data as MetadataFieldInfo;

    const validationStatus = (isValidationEnabled && field.receivedFromSchedule === PropertyState.Unset) ? "invalid" : "valid";

    const value = field.receivedFromSchedule === PropertyState.Unset 
                  ? undefined 
                  : field.receivedFromSchedule ? ReceivedFrom.IlapSchedule : ReceivedFrom.Manual;

    return <div>
      <IlapSelectBox
        defaultValue={ReceivedFrom.Manual}
        items={receivedFromOptions}
        value={value}
        displayExpr={"name"}
        valueExpr={"value"}
        validationStatus={validationStatus}
        onValueChange={(value: ReceivedFrom) => onReceivedFromIlapScheduleChange?.(field, value)} />
    </div>
  }, [isValidationEnabled, receivedFromOptions, onReceivedFromIlapScheduleChange]);

  const toolbarConfig = useRef({
    dislplaySearchPanel: true
  });
  
  return (
    <div className="field-selector-panel flex gap-4">
      <div className="mb-4">
        <IlapViewGrid
          className={availableFieldsGridCss}
          dataSource={availableFields}
          height={gridHeight}
          ref={availableFieldsGridRef}
          toolbarConfig={toolbarConfig.current}
          additionalWidget={availableFieldsGridTitle}>
          <Selection mode="multiple" showCheckBoxesMode="always" />
          <Column caption="Title" dataField="name" />
          <Column caption="ILAP ID" dataField="ilapId" />
          <Column caption="Description" dataField="description" />
        </IlapViewGrid>
      </div>
      <div className="w-[114px]" >
        <div className="flex flex-col h-full gap-4 justify-center">
          <AddToListButton onClick={handleAddClick} />
          <RemoveFromListButton onClick={handleRemoveClick} />
        </div>
      </div>
      <div className="mb-4">
        <IlapViewGrid
          className={selectedFieldsGridCss}
          dataSource={selectedFields}
          height={gridHeight}
          ref={selectedFieldsGridRef}
          toolbarConfig={toolbarConfig.current}
          additionalWidget={selectedFieldsGridTitle}>
          <Selection mode="multiple" showCheckBoxesMode="always" />
          <Column caption="Title" dataField="name" cellTemplate={fieldTitleCellTemplate} />
          <Column caption="Content control" cellComponent={contentControlComponent} />
          {!isActivityField && <Column caption="Received from" cellComponent={receivedFromComponent} />}
        </IlapViewGrid>
      </div>
    </div>)
});

export default FieldSelectorPanel;