import { AddNewButton, IlapTabPanel, IlapTextBox } from "ilap.common.webcomponents.test";
import { Item } from "devextreme-react/tab-panel";
import TextWithCount from "components/common/TextWithCount";
import { useCallback, useRef } from "react";
import {
  displayGridLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import MetadataField from "interfaces/response/MetadataField";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlanningObjectTypes } from "shared/enums/PlanningObjectTypesEnum";
import { loadMetadataFieldsAsync } from "store/action/MetadataActions";
import { AppDispatch, RootState } from "store/store";
import "./styles/FieldsGrid.css";
import "./styles/FieldsTabPanel.css";
import FieldCreateModal from "./create/FieldCreateModal";
import FieldsGrid from "./FieldsGrid";
import DownloadButton from "components/common/buttons/DownloadButton";
import FieldsImportModal from "./import/FieldsImportModal";
import { DataGrid, TextBox } from "devextreme-react";
import { debounce } from "lodash";

const enum Tab {
  Schedule = 0,
  Activity = 1
}

export default function FieldsTabPanel() {

  const dispatch = useDispatch<AppDispatch>();
  const metadataFields = useSelector(
    (rootStore: RootState) => rootStore.metadataFieldData.metadataFields
  );
  
  useEffect(() => {
    if (metadataFields?.length === 0){
      displayGridLoadingPanel();            
      dispatch(loadMetadataFieldsAsync()).finally(hideLoadingPanel);
    }
  }, [dispatch, metadataFields?.length]);

  const [scheduleFields, setScheduleFields] = useState<MetadataField[]>([]);
  const [activityFields, setActivityFields] = useState<MetadataField[]>([]);

  const [isCreateModalVisible, setIsCreateModalVisible] = useState<boolean>(false);
  const [isImportFieldsModalVisible, setIsImportFieldsModalVisible] = useState<boolean>(false);

  // Refs used to implement search functionality from outside the data grids
  const scheduleFieldsRef = useRef<DataGrid>(null);
  const activityFieldsRef = useRef<DataGrid>(null);
  const searchBoxRef = useRef<TextBox>(null);
  const selectedTabRef = useRef<Tab>(Tab.Schedule)

  useEffect(() => {
    const activityFields: MetadataField[] = [];
    const scheduleFields: MetadataField[] = [];

    for (let metadataFieldIndex = 0; metadataFieldIndex < metadataFields?.length; metadataFieldIndex++) {
      let metadataField = metadataFields[metadataFieldIndex];

      if (metadataField.planningObjectTypes === PlanningObjectTypes.Activity) {
        activityFields.push(metadataField);
      } else if (metadataField.planningObjectTypes === PlanningObjectTypes.Schedule) {
        scheduleFields.push(metadataField);
      }
    }
    setActivityFields(activityFields);
    setScheduleFields(scheduleFields);
  }, [metadataFields])

  const scheduleFieldsTitleComponent = useCallback(() => {
    return <TextWithCount text="Schedule fields" count={scheduleFields.length} />
  }, [scheduleFields.length]);

  const activityFieldsTitleComponent = useCallback(() => {
    return <TextWithCount text="Activity fields" count={activityFields.length} />
  }, [activityFields.length]);

  const showFieldCreateModal = () => setIsCreateModalVisible(true);
  const hideFieldCreateModal = () => setIsCreateModalVisible(false);

  const showImportFieldsModal = () => setIsImportFieldsModalVisible(true);
  const hideImportFieldsModal = () => setIsImportFieldsModalVisible(false);

  const handleTabSelectionChange = useCallback((selectedIndex: number) => {
    // reset search box
    searchBoxRef.current?.instance.reset();

    if (selectedIndex === Tab.Activity) { // when switching to activity tab, clear schedule fields filter
      scheduleFieldsRef.current?.instance.clearFilter();
    }
    else if (selectedIndex === Tab.Schedule) { // when switching to schedule tab, clear activity fields filter
      activityFieldsRef.current?.instance.clearFilter();
    }

    selectedTabRef.current = selectedIndex;
  }, []);

  // The search function is debounced to prevent multiple search requests when the user types quickly
  const debouncedSearch = useRef(
    debounce((term) => {
      if (selectedTabRef.current === Tab.Schedule && scheduleFieldsRef.current) {
        scheduleFieldsRef.current.instance.searchByText(term);
      } else if (selectedTabRef.current === Tab.Activity && activityFieldsRef.current) {
        activityFieldsRef.current.instance.searchByText(term);
      }
    }, 500)
  ).current;

  const handleSearchInput = useCallback(({ event }: any) => {
    const searchTerm = event.currentTarget.value;
    debouncedSearch(searchTerm);
  }, [debouncedSearch]);

  return (
    <div className="mx-14 FieldsTabPanel">
      <div className="mb-[21px] font-poppins text-[18px] text-dark-blue-1 font-semibold leading-normal flex">
        <div className="mr-2">Fields</div>
      </div>
      <div>
        <div className="float-right flex">
          <TextBox
            ref={searchBoxRef}
            className="search-box mr-2"
            placeholder="Search.."
            onInput={handleSearchInput}
            mode="search"
          />
          <DownloadButton className="px-px py-4 mr-2" text="Import fields" onClick={showImportFieldsModal} />
          <AddNewButton className="px-px py-4 border-none" text="New" onClick={showFieldCreateModal} />
        </div>
        <IlapTabPanel className="Ilap-tab-panel FieldsGrid" onSelectedIndexChange={handleTabSelectionChange}>
          <Item tabRender={scheduleFieldsTitleComponent}>
            <FieldsGrid data={scheduleFields} ref={scheduleFieldsRef} />
          </Item>
          <Item tabRender={activityFieldsTitleComponent}>
            <FieldsGrid data={activityFields} ref={activityFieldsRef} />
          </Item>
        </IlapTabPanel>
      </div>
      {isCreateModalVisible && <FieldCreateModal onHideDialog={hideFieldCreateModal} />}
      {isImportFieldsModalVisible && <FieldsImportModal onHideDialog={hideImportFieldsModal} />}
    </div>
  );
}