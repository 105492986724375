import { getDataAsync, postDataAsync, putDataAsync, deleteDataAsync } from "./AnalyticsApi";
import ReportScheduleType from "interfaces/response/ReportScheduleType";
import ReportScheduleTypeRequest from "interfaces/request/ReportScheduleTypeRequest";

const routeUrl = "ReportScheduleType";

export const getReportScheduleTypesAsync = async () => {
  return await getDataAsync<ReportScheduleType[]>(routeUrl);
};

export const getReportScheduleTypesSlimAsync = async () => {
  return await getDataAsync<ReportScheduleType[]>(`${routeUrl}s`);
};

export const getReportScheduleTypeAsync = async (reportScheduleTypeId: number) => {
  // Get a single report schedule with filtered metadata.
  const url = `${routeUrl}/${reportScheduleTypeId}?planningObjectTypes=activity,schedule`;
  return await getDataAsync<ReportScheduleType>(url, false);
};

export const createReportScheduleTypeAsync = async(reportScheduleTypeRequest: ReportScheduleTypeRequest) => {
  const url = "ReportScheduleTypeWithMetadata";
  return await postDataAsync<ReportScheduleTypeRequest, ReportScheduleType>(url, reportScheduleTypeRequest);
}

export const updateReportScheduleTypeAsync = async (reportScheduleTypeId: number, reportScheduleTypeUpdateModel: ReportScheduleTypeRequest) => {
  const url = `${routeUrl}/${reportScheduleTypeId}`;
  return await putDataAsync<ReportScheduleTypeRequest, ReportScheduleType>(url, reportScheduleTypeUpdateModel);
};

export const deleteReportScheduleTypeAsync = async (reportScheduleTypeId: number) => {
  const url = `${routeUrl}/${reportScheduleTypeId}`;
  return await deleteDataAsync(url, true, "Schedule type could NOT be deleted. ");
};