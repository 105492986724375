import CopyIcon from "components/common/icons/CopyIcon";
import { useMemo, useState } from "react";

import { toastError } from "shared/utilities/ToastUtility";
import "./styles/CopyButton.css";

interface Props {
  textToBeCopied: string;
  showStatusOnRight?: boolean;
}

export default function CopyButton(props: Props) {
  const { textToBeCopied, showStatusOnRight } = props;

  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(textToBeCopied);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 5000); // Hide the copied message after 5 seconds
    } catch (err) {
      toastError();
    }
  };

  const copyStatus = useMemo(() => {
    const css = `font-poppins text-base text-blue-6 ${showStatusOnRight ? "ml-[6px]" : "mr-[6px]"}`;
    return <div className={css}>Copied!</div>
  }, [showStatusOnRight]);

  return (
    <div className="flex items-center">
      {isCopied && !showStatusOnRight && copyStatus}
      <button className="copy-button" onClick={handleCopy}>
        <CopyIcon className="copy-icon" />
      </button>
      {isCopied && showStatusOnRight && copyStatus}
    </div>
  );
}