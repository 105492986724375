import "./styles/LoadingIconSmall.css";

interface Props {  
  additionalClass?: string;  
}

export default function LoadingIconSmall(props : Props) {
  return (
    <svg
      className={`loading-icon-small ${props.additionalClass}`}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 8C15 8.91925 14.8189 9.8295 14.4672 10.6788C14.1154 11.5281 13.5998 12.2997 12.9497 12.9497C12.2997 13.5998 11.5281 14.1154 10.6788 14.4672C9.8295 14.8189 8.91925 15 8 15C7.08075 15 6.17049 14.8189 5.32122 14.4672C4.47194 14.1154 3.70026 13.5998 3.05025 12.9497C2.40024 12.2997 1.88463 11.5281 1.53284 10.6788C1.18106 9.8295 1 8.91925 1 8C1 7.08075 1.18106 6.17049 1.53284 5.32122C1.88463 4.47194 2.40024 3.70026 3.05025 3.05025C3.70026 2.40024 4.47194 1.88463 5.32122 1.53284C6.1705 1.18106 7.08075 1 8 1C8.91925 1 9.82951 1.18106 10.6788 1.53284C11.5281 1.88463 12.2997 2.40024 12.9497 3.05025C13.5998 3.70026 14.1154 4.47194 14.4672 5.32122C14.8189 6.1705 15 7.08075 15 8L15 8Z"
        stroke="url(#paint0_angular_9452_38857)"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient id="paint0_angular_9452_38857" x1="0" y1="0" x2="1" y2="0">
          <stop stopColor="white" />
          <stop offset="1" stopColor="#2D2D2D" />
        </linearGradient>
      </defs>
    </svg>
  );
}


