import ReportScheduleMetadataFieldValue from "interfaces/response/ReportScheduleMetadataFieldValue";

export const sortFieldsByRequirement = (metadataFields: ReportScheduleMetadataFieldValue[]): ReportScheduleMetadataFieldValue[] => {
  return metadataFields.sort((a, b) => {
    if (a.isRequired && !b.isRequired) {
      return -1;
    } else if (!a.isRequired && b.isRequired) {
      return 1;
    } else {
      return 0;
    }
  });
}