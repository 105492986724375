import { useEffect, useState } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { Offset, Position } from "devextreme-react/popover";
import IlapPopover from "components/common/controls/IlapPopover";
import ApiVersionResponse from "interfaces/response/ApiVersionResponse";
import { loadApiVersionAsync } from "store/action/ApiVersionActions";
import { useAppDispatch } from "store/hooks";
import VersionDropdownItem from "./VersionDropdownItem";
import HelpCenterItem from "./HelpCenterItem";

interface Props {
  target: string;
  visible: boolean;
  onHiding: () => void;
}

const defaultVersionNumber = "not available";

export default function InformationPopover(props: Props) {
  const dispatch = useAppDispatch();

  const [apiVersion, setApiVersion] = useState<string>(defaultVersionNumber);
  const [webVersion, setWebVersion] = useState<string>(defaultVersionNumber);

  useEffect(() => {
    const version = process.env.REACT_APP_VERSION;
    if (version) {
      setWebVersion(version);
    }
  }, []);

  useEffect(() => {
    dispatch(loadApiVersionAsync())
      .then(unwrapResult)
      .then((response: ApiVersionResponse) => {
        if (response.version) {
          setApiVersion(response.version);
        }
      })
      .catch(() => {
        // ignore error if api version could not be loaded
      });
  }, [dispatch]);

  const handleClickAndStopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const PopoverContent = () => {
    return (
      <div onClick={handleClickAndStopPropagation}>
        <VersionDropdownItem name="ILAP Analytics API" version={apiVersion} />
        <VersionDropdownItem name="ILAP Analytics Web" version={webVersion} />
        <HelpCenterItem />
      </div>
    );
  };

  return (
    <>
      <IlapPopover width={320} {...props} >
        <Position my={{ x: "right", y: "top" }}>
          <Offset x={30} y={-10} />
        </Position>
        <PopoverContent />
      </IlapPopover>
    </>
  );
}