import CopyButton from "components/common/buttons/icon-buttons/CopyButton";
import CopyIconSmall from "components/common/icons/CopyIconSmall";
import { IlapButton, IlapButtonType, IlapButtonWithIcon, IlapPopup } from "ilap.common.webcomponents.test";
import ServiceTokenCreateResponse from "interfaces/response/ServiceTokenCreateResponse";
import { useCallback } from "react";
import { toastError, toastSuccess } from "shared/utilities/ToastUtility";

interface Props {
  serviceToken: ServiceTokenCreateResponse;
  onHideDialog: () => void;
}

export default function ServiceTokenViewModal(props: Props) {
  // remove the unusable part of the token ("SharedAccessSignature ")
  const tokenToBeCopied = props.serviceToken.token.substring(22);

  const handleCopyToken = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(tokenToBeCopied);
      toastSuccess("Token copied to clipboard");
      
    } catch {
      toastError("Failed to copy token");
    }
  }, [tokenToBeCopied]);

  return (
    <IlapPopup
      height={"283px"}
      width={"728px"}
      visible={true}
    >
      <div className="h-full flex flex-col justify-between font-poppins">
        <div className="font-semibold text-18px leading-[27px] text-gray-9">Token generated successfully</div>
        
        <hr className="border-lighter-gray" />
        
        <div className="font-normal text-base text-gray-9">This key will only be displayed once. Make sure to save it on a secure location.</div>

        <hr className="border-lighter-gray" />

        <section className="flex items-center">
          <div className="w-[570px] px-2 py-1">
            <div className="text-sm font-semibold">ILAP Analytics token</div>
            <div className="text-base truncate">{tokenToBeCopied}</div>
          </div>
          
          <div>
            <CopyButton textToBeCopied={tokenToBeCopied} showStatusOnRight={true} />
          </div>
        </section>

        <hr className="border-lighter-gray" />

        <footer className="flex flex-col justify-end">
          <div className="flex justify-end gap-4">
            <IlapButton variant={IlapButtonType.Secondary} className="px-4 rounded-lg font-medium" onClick={props.onHideDialog}>Close</IlapButton>
            <IlapButtonWithIcon className="px-4 font-medium" text="Copy token" Icon={<CopyIconSmall />} onClick={handleCopyToken} />
          </div>
        </footer>
      </div>
    </IlapPopup>
  )
}