import { IButtonOptions } from "devextreme-react/button";
import { IlapButtonWithIcon } from "ilap.common.webcomponents.test";
import AddIcon from "../icons/AddIcon";

interface Props {
  text?: string;
}

export default function AddButton(props: Props & IButtonOptions) {
  return (
    <IlapButtonWithIcon
      {...props}
      text={props.text || "New"}
      Icon={<AddIcon />}
    />
  );
}
