import { createAsyncThunk } from "@reduxjs/toolkit";
import { getApiVersionAsync } from "apis/VersionApi";
import { RootState } from "store/store";

export const loadApiVersionAsync = createAsyncThunk<any, void, { state: RootState }>(
  "version/apiVersion",
  async (_, thunkApi) => {
    const { apiVersion } = thunkApi.getState().apiVersionData;

    return apiVersion ?? await getApiVersionAsync();
  }
);
