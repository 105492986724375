import { ReactNode } from "react";
import TooltipIcon from "./TooltipIcon";

interface Props {
  label: ReactNode | string;
  addAsteriskSymbol?: boolean;
  isOptional?: boolean;
  infoText?: string;
}

export default function LabelWithAnnotation(props: Props) {
  const { label, addAsteriskSymbol, isOptional, infoText } = props;

  return (
    <div className="flex text-xs leading-[18px] space-x-2 items-center">
      <div className="font-inter text-textGray flex">
        <div className="font-semibold text-sm">{label}{isOptional && <span className="font-normal">{" "}(optional)</span>}</div>
        {addAsteriskSymbol && (
          <div className="text-red inline">
            *
          </div>
        )}
      </div>
      {infoText && <TooltipIcon text={infoText} />}
    </div>
  )
}