import {
  getDataAsync,
  postDataAsync,
  putDataAsync,
  deleteDataAsync,
  queryGraphQLAsync,
} from "./AnalyticsApi";
import ReportScheduleRequest from "interfaces/request/ReportScheduleRequest";
import ReportScheduleResponse from "interfaces/response/ReportScheduleResponse";
import { ReportScheduleRevisionChangeRequest } from "interfaces/request/ReportScheduleRevisionChangeRequest";
import ReportScheduleRevertRequest from "interfaces/request/ReportScheduleRevertRequest";
import ReportScheduleStatusUpdateRequest from "interfaces/request/ReportScheduleUpdateRequest";
import { getIntrospectionQuery, getReportActivitiesByReportScheduleIdQuery } from "shared/utilities/AnalyticsApiUtility";
import GraphQLQueryResponse from "interfaces/response/GraphQLQueryResponse";
import GraphQLIntrospectionQueryResponse from "interfaces/response/GraphQLIntrospectionQueryResponse";
import BatchOperationResponse from "interfaces/response/BatchOperationResponse";

const routeUrl = "ReportSchedule";

export const getReportSchedulesAsync = async () => {
  return await getDataAsync<ReportScheduleResponse[]>(routeUrl);
};

export const getReportSchedulesSlimAsync = async () => {
  return await getDataAsync<ReportScheduleResponse[]>(`${routeUrl}s`);
};

export const getReportScheduleAsync = async (reportScheduleId: number) => {
  const url = `${routeUrl}/${reportScheduleId}`;
  return await getDataAsync<ReportScheduleResponse>(url);
};

export const getReportScheduleWithSchedulesAsync = async (
  reportScheduleId: number
) => {
  const url = `${routeUrl}/${reportScheduleId}/schedules`;
  return await getDataAsync<ReportScheduleResponse>(url);
};

export const getReportActivitiesWithMetadataAsync = async (
  reportScheduleId: number
) => {
  // We get all fields by introspection because we don't know what fields are available in the query beforehand
  const introspectionQuery = getIntrospectionQuery("ReportActivity");

  const introspectionResponse = await queryGraphQLAsync<string, GraphQLIntrospectionQueryResponse>(introspectionQuery);

  // Get all the scalar/enum fields that have a name
  const scalarAndEnumFields =  introspectionResponse.data?.__type?.fields?.filter(field => {
    return field.name && field.type && (field.type.kind === "SCALAR" || field.type.kind === "ENUM" || (field.type.kind === "NON_NULL" && field.type.ofType && (field.type.ofType.kind === "SCALAR" || field.type.ofType.kind === "ENUM")));
  }) ?? [];

  // Get the names of scalar fields
  const fieldNames = scalarAndEnumFields.map(field => field.name);

  // We build the query from the fields received
  const query = await getReportActivitiesByReportScheduleIdQuery(reportScheduleId, fieldNames);

  const response = await queryGraphQLAsync<string, GraphQLQueryResponse>(query);

  return response.data?.reportActivitiesByReportScheduleId;
};

export const createReportScheduleAsync = async (
  reportScheduleRequest: ReportScheduleRequest
) => {
  const url = `ReportScheduleWithMetadataFieldValue`;
  return await postDataAsync<ReportScheduleRequest, ReportScheduleResponse>(
    url,
    reportScheduleRequest
  );
};

export const updateReportScheduleAsync = async (
  reportScheduleId: number,
  reportScheduleUpdateModel: ReportScheduleRequest
) => {
  const url = `${routeUrl}/${reportScheduleId}`;
  return await putDataAsync<ReportScheduleRequest, ReportScheduleResponse>(
    url,
    reportScheduleUpdateModel
  );
};

export const updateReportScheduleStatusAsync = async (
  reportScheduleId: number,
  reportScheduleStatusUpdateModel: ReportScheduleStatusUpdateRequest
) => {
  const url = `${routeUrl}/${reportScheduleId}/Status`;
  return await putDataAsync<ReportScheduleStatusUpdateRequest, ReportScheduleResponse>(
    url,
    reportScheduleStatusUpdateModel
  );
};

export const deleteReportScheduleAsync = async (reportScheduleId: number) => {
  const url = `${routeUrl}/${reportScheduleId}`;
  return await deleteDataAsync(url);
};

export const changeReportScheduleRevisionAsync = async (
  reportScheduleRevisionChangeRequest: ReportScheduleRevisionChangeRequest
) => {
  const url = `ReportSchedule/Revision/Change`;
  return await postDataAsync<ReportScheduleRevisionChangeRequest, any>(
    url,
    reportScheduleRevisionChangeRequest
  );
};

export const revertScheduleAsync = async (reportScheduleRevertRequest: ReportScheduleRevertRequest) => {
  const url = `${routeUrl}/${reportScheduleRevertRequest.reportScheduleId}/${reportScheduleRevertRequest.revisionType}/Revert`;
  return await postDataAsync<any, any>(url, null);
}

export const deleteScheduleAsync = async (scheduleId: number) => {
  const url = `PlanningSchedule/${scheduleId}`;
  return await deleteDataAsync(url);
};

export const getProcessingReportScheduleBatchOperationsAsync = async (id: number) => {
  const url = `ReportSchedule/${id}/operations/processing`;
  return await getDataAsync<BatchOperationResponse[]>(url);
}

export const getFailedReportScheduleBatchOperationsAsync = async (id: number) => {
  const url = `ReportSchedule/${id}/operations/failed`;
  return await getDataAsync<BatchOperationResponse[]>(url);
}