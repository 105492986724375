export default function CheckIcon(){
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#AAD8CB" />
      <g clipPath="url(#clip0_9138_40245)">
        <path d="M9.50065 15.5013L6.00065 12.0013L4.83398 13.168L9.50065 17.8346L19.5007 7.83464L18.334 6.66797L9.50065 15.5013Z" fill="#00483A" />
      </g>
      <defs>
        <clipPath id="clip0_9138_40245">
          <rect width="20" height="20" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </svg>
  )
}