import { unwrapResult } from "@reduxjs/toolkit";
import CrossIconButton from "components/common/buttons/icon-buttons/CrossIconButton";
import { ScrollView } from "devextreme-react";
import { IlapPopup } from "ilap.common.webcomponents.test";
import BatchOperationResponse from "interfaces/response/BatchOperationResponse";
import { useEffect, useMemo, useState } from "react";
import { BatchOperationCategory } from "shared/enums/BatchOperationType";
import { loadReportScheduleFailedOperationsAsync, loadReportScheduleProcessingOperationsAsync } from "store/action/ReportScheduleActions";
import { useAppDispatch } from "store/hooks";
import BatchOperationsAccordion from "./BatchOperationsAccordion";
import { TextUtility } from "shared/utilities/TextUtility";

interface Props {
  reportScheduleId: number,
  reportScheduleTitle: string,
  category: BatchOperationCategory,
  onHideDialog: () => void;
}
export default function BatchOperationModal(props: Props) {
  const dispatch = useAppDispatch();

  const [batchOperations, setBatchOperations] = useState<BatchOperationResponse[]>([]);
  const [shouldLoadBatchData, setShouldLoadBatchData] = useState<boolean>(true);

  const title = useMemo(() => {
    return `${props.category} imports (${TextUtility.truncate(props.reportScheduleTitle, 45)})`;
  }, [props.category, props.reportScheduleTitle])

  useEffect(() => {
    // sanity check, we don't rely on the list being empty, because it can be empty often
    if (!shouldLoadBatchData){
      return;
    }

    if (props.category === BatchOperationCategory.Processing){
      dispatch(loadReportScheduleProcessingOperationsAsync(props.reportScheduleId))
      .then(unwrapResult)
      .then((response: BatchOperationResponse[]) => {
        setBatchOperations(response);
        setShouldLoadBatchData(false);
      });
    }
    else if (props.category === BatchOperationCategory.Unsuccessful){
      dispatch(loadReportScheduleFailedOperationsAsync(props.reportScheduleId))
      .then(unwrapResult)
      .then((response: BatchOperationResponse[]) => {
        setBatchOperations(response);
        setShouldLoadBatchData(false);
      });
    }
  }, [dispatch, props.reportScheduleId, props.category, shouldLoadBatchData])

  return (
    <IlapPopup
      height={"600px"}
      width={"936px"}
      visible={true}
    >
      <div className="flex flex-col gap-3">
        <header className="flex justify-between items-center text-gray-9">
          <div className="font-poppins text-18px font-semibold truncate">{title}</div>
          <CrossIconButton onClick={props.onHideDialog} />
        </header>
        <hr className="border-gray-400 mb-[5px]" />
        <ScrollView height={490} showScrollbar="always">
          <BatchOperationsAccordion operations={batchOperations} />
        </ScrollView>
      </div>
    </IlapPopup>
  )
}
