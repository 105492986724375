import RoundTickIcon from "components/common/icons/GreenTickIcon";
import LoadingIconSmall from "components/common/icons/LoadingIconSmall";
import HourglassIcon from "components/common/icons/HourglassIcon";
import RoundIcon from "components/common/icons/RoundIcon";
import BatchOperationResponse from "interfaces/response/BatchOperationResponse";
import { useMemo } from "react";
import { ReportScheduleOperationStatus } from "shared/enums/ReportScheduleOperationStatus";
import { RevisionType } from "shared/enums/RevisionTypeEnum";
import { getDefaultLocaleFormattedDateTime } from "shared/utilities/DateTimeUtility";
import { ReportScheduleOperationType } from "shared/enums/ReportScheduleOperationType";

interface Props {
  batch: BatchOperationResponse
}

export default function BatchOperationTitle(props: Props) {

  const { batch } = props;

  const batchIcon = useMemo(() => {
    switch (batch.status){
      case ReportScheduleOperationStatus.Successful:
        return <RoundTickIcon />
      case ReportScheduleOperationStatus.Failed:
        return <RoundIcon className="bg-red" />
      case ReportScheduleOperationStatus.Running:
        return <LoadingIconSmall additionalClass="ml-px mr-0.5" />
      default:
        return <HourglassIcon />
    }
  }, [batch.status]);

  const operation = useMemo(() => {
    switch (batch.operationType){
      case ReportScheduleOperationType.Revert:
        return `${RevisionType[batch.revisionType]} (Revert)`
      default:
        return RevisionType[batch.revisionType]
    }
  }, [batch.operationType, batch.revisionType])

  return (
    <div className="flex items-center text-base text-black">
      <div className="ml-3 w-6">{batchIcon}</div>
      <div className="w-[290px] ml-[5px]">{operation}</div>
      <div>{getDefaultLocaleFormattedDateTime(batch.created)}</div>
    </div>
  )
}