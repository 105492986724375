import CheckIcon from "components/common/icons/CheckIcon";
import CrossIcon from "components/common/icons/CrossIcon";
import WarningIcon from "components/common/icons/WarningIcon";
import { toast, ToastOptions } from "react-toastify";

const defaultErrorMessage = "Something went wrong. Please try again or contact the tenant admin.";

const toastCommonCss: React.CSSProperties = {
  color: "white",
  background: "var(--color-dark-blue-1)",
  paddingBottom: "18px",
  borderRadius: "8px",
  width: "264px",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: "400",
  lineHeight: "18px",
  letterSpacing: "0.02em",
  whiteSpace: "normal",
  wordWrap: "break-word",
  wordBreak: "break-word",
  hyphens: "auto",
  WebkitHyphens: "auto",
}

const successToastOption: ToastOptions = {
  icon: CheckIcon,    
  style: toastCommonCss
};

const errorToastOption: ToastOptions = {
  icon: CrossIcon,
  style: toastCommonCss
};

const warningToastOption: ToastOptions = {
  icon: WarningIcon,
  style: toastCommonCss
};

export const toastContainerOption: ToastOptions = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,    
  closeButton: false,
  rtl: false,
  progressStyle:{
    background:"var(--color-gray-400)",
    height: "1px",
    margin: "16px",      
    width: "232px" ,
    transform: "scaleX(1)",
    transformOrigin: "right"     
  },
  style: {
    marginRight: "20px",
    marginBottom: "52px",
  },
}

export const toastSuccess = (message: string) => {
  toast.success(message, successToastOption);
}

export const toastWarning = (message: string) => {
  toast.error(message, warningToastOption);
};

export const toastError = (message: string = defaultErrorMessage) => {
  toast.error(message, errorToastOption);
};