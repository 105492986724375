import LabelWithContent from "components/common/LabelWithContent";
import { IlapSelectBox, IlapTextBox } from "ilap.common.webcomponents.test";
import MetadataFieldValue from "interfaces/common/MetadataFieldValue";
import ReportScheduleMetadataFieldValue from "interfaces/response/ReportScheduleMetadataFieldValue";

interface Props {
  fieldValue: ReportScheduleMetadataFieldValue
  handleDropdownSelectionChange: (selectedItem: MetadataFieldValue) => void;
  handleMetadataFieldValueChange: (metadataFieldId: number, value: string, id?: number) => void;
}

export default function ReportScheduleFieldEditor(props: Props) {
  const { fieldValue } = props;

  const commonClass = `"w-[320px]"`;
  const errorClass = `${commonClass} border-red-4`;

  return (
    fieldValue.isReadOnly
      ? <LabelWithContent
        label={fieldValue.name}
        rootClass="truncate"
        content={
          <div className={"ilap-rs-view-value-text truncate"} title={fieldValue.value}>{fieldValue.value}</div>
        }
      />
      : <LabelWithContent
        label={fieldValue.name}
        rootClass="w-fit"
        isOptional={!fieldValue.isRequired}
        content={fieldValue.displayDropdown ? (
          <IlapSelectBox
            placeholder="Select"
            displayExpr={"stringValue"}
            valueExpr={"id"}
            value={fieldValue.id}
            items={fieldValue.dropdownSource}
            showValidationMessage={fieldValue.isMissingRequired}
            className={fieldValue.isMissingRequired ? errorClass : commonClass}
            onSelectionChanged={({ selectedItem }: { selectedItem: MetadataFieldValue }) => {
              props.handleDropdownSelectionChange(selectedItem);
            }}
          />
        ) : (
          <IlapTextBox
            placeholder="Type..."
            value={fieldValue.value}
            showValidationMessage={fieldValue.isMissingRequired}
            className={fieldValue.isMissingRequired ? errorClass : commonClass}
            onChange={({ event }: any) => {
              props.handleMetadataFieldValueChange(fieldValue.metadataFieldId, event?.currentTarget?.value, 0);
            }}
          />
        )}
      />
  )
}