import { DataType } from "interfaces/common/DataType";
import { MetadataFieldInfo } from "interfaces/common/MetadataFieldInfo";
import ReportScheduleTypeMetadataFieldRequest from "interfaces/request/ReportScheduleTypeMetadataFieldRequest";
import DropdownValuesResponse from "interfaces/response/DropdownValuesResponse";
import MetadataField from "interfaces/response/MetadataField";
import MetadataFieldWithContentControlResponse from "interfaces/response/MetadataFieldWithContentControlResponse";
import { FlowPlanningObjectTypes } from "shared/enums/FlowPlanningObjectTypesEnum";
import { PlanningObjectTypes } from "shared/enums/PlanningObjectTypesEnum";
import { PropertyState } from "shared/enums/PropertyStateEnum";
import { Type } from "shared/enums/TypeEnum";
import { ValidationRequirement } from "shared/enums/ValidationRequirementEnum";

export const FieldState = {
  Saved: true,
  Unsaved: false
}

export const FieldUtility = {
  createEmptyMetadataField: (): MetadataField => {
    return {
      id: 0,
      name: "",
      description: "",
      ilapId: "",
      iri: "",
      created: "",
      isVisible: true,
      metadataFieldValues: [],
      planningObjectTypes: PlanningObjectTypes.None,
      receivedFromSchedule: true,
      timesInUse: 0,
      type: 0,
      usedBy: "",
      validationRequirement: ValidationRequirement.None,
      validationRequirementText: "",
      numberOfValues: 0,
    };
  },
  convertToMetadataFieldInfo: (field: MetadataField, isSaved: boolean = false) : MetadataFieldInfo => {
    return {
      id: field.id,
      name: field.name,
      type: field.type,
      // not copying validationRequirement when saved status is false
      // because, validation requirement value 0 is there by default, but 0 also means "None"
      validationRequirement: isSaved ? field.validationRequirement : PropertyState.Unset, 
      ilapId: field.ilapId,
      description: field.description,
      isSaved: isSaved,
      // not copying receivedFromSchedule when saved status is false
      // because, receivedFromSchedule value 0 is there by default, but 0 also means "Received from Schedule"
      receivedFromSchedule: isSaved ? field.receivedFromSchedule : PropertyState.Unset
    };
  },
  convertToScheduleTypeMetadataFieldRequest: (field: MetadataFieldInfo, scheduleTypeId: number = 0): ReportScheduleTypeMetadataFieldRequest => {
    return {
      reportScheduleTypeId: scheduleTypeId, // default value 0 means the field is not part of any Schedule Type yet (needed during Schedule Type Creation)
      metadataFieldId: field.id,
      validationRequirement: field.validationRequirement as ValidationRequirement,
      receivedFromSchedule: field.receivedFromSchedule as boolean
    }
  },
  convertFieldsToScheduleTypeFieldRequestModels: (fields: MetadataFieldInfo[], scheduleTypeId: number = 0): ReportScheduleTypeMetadataFieldRequest[] => {
    return fields.map(field => FieldUtility.convertToScheduleTypeMetadataFieldRequest(field, scheduleTypeId));
  },
  markAsSaved: (field: MetadataFieldInfo): MetadataFieldInfo => {
    return {
      ...field,
      isSaved: true
    }
  },
  markAsUnsaved: (field: MetadataFieldInfo): MetadataFieldInfo => {
    return {
      ...field,
      validationRequirement: PropertyState.Unset,
      receivedFromSchedule: PropertyState.Unset,
      isSaved: false
    }
  },
  getDataTypeName: (type: Type): string => {
    switch(type){
      case Type.Double:
        return "Numeric";
      case Type.Boolean:
        return "Boolean";
      case Type.DateTime:
        return "Date/Time";
      default:
        return "String";
    }
  },
  getDataTypes: (): DataType[] => {
    return [
      {
        type: Type.String,
        name: "String (default)"
      },
      {
        type: Type.Double,
        name: "Numeric"
      },
      {
        type: Type.Boolean,
        name: "Boolean"
      },
      {
        type: Type.DateTime,
        name: "Date/Time"
      },
    ];
  },
  getPlanningObjectTypeFromFlowPlanningObjectType: (flowType: FlowPlanningObjectTypes) : PlanningObjectTypes => {
    switch(flowType){
      case FlowPlanningObjectTypes.Schedule:
        return PlanningObjectTypes.Schedule;
      case FlowPlanningObjectTypes.Activity:
        return PlanningObjectTypes.Activity;
      case FlowPlanningObjectTypes.Resource:
        return PlanningObjectTypes.Resource;
      case FlowPlanningObjectTypes.ResourceUsage:
        return PlanningObjectTypes.ResourceAssignment;
      default:
        return PlanningObjectTypes.None;
    }
  },
  createFieldRequestFromFieldFromFlow: (field: MetadataFieldWithContentControlResponse) : MetadataField => {
    const newField = FieldUtility.createEmptyMetadataField();

    newField.name = field.name;
    newField.ilapId = field.ilapId;
    newField.description = field.description ?? "";
    newField.type = field.dataType;
    newField.planningObjectTypes = FieldUtility.getPlanningObjectTypeFromFlowPlanningObjectType(field.planningObjectTypes);

    return newField;
  },
  getValidationRequirementOptions: (contentControlValues: DropdownValuesResponse[], type: Type) => {
    return type === Type.String 
    ? contentControlValues 
    : contentControlValues.filter(c => [ValidationRequirement.None, ValidationRequirement.NonBlankValue].includes(c.value));
  }
};