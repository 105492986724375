import { ServiceTokenCreateRequest } from "interfaces/request/ServiceTokenCreateRequest";
import { ServiceTokenUpdateRequest } from "interfaces/request/ServiceTokenUpdateRequest";
import ServiceTokenCreateResponse from "interfaces/response/ServiceTokenCreateResponse";
import ServiceTokenResponse from "interfaces/response/ServiceTokenResponse";
import { deleteDataAsync, getDataAsync, postDataAsync, putDataAsync } from "./AnalyticsApi";

const routeUrl = "Authentication/ServiceAccountTokens";

export const getServiceTokensAsync = async () => {
  return await getDataAsync<ServiceTokenResponse[]>(routeUrl);
};

export const createServiceTokenAsync = async (
  request: ServiceTokenCreateRequest
) => {
  return await postDataAsync<ServiceTokenCreateRequest, ServiceTokenCreateResponse>(routeUrl, request);
};

export const updateServiceTokenAsync = async (
  id: number, request: ServiceTokenUpdateRequest
) => {
  const url = `${routeUrl}/${id}`;
  return await putDataAsync<ServiceTokenUpdateRequest, ServiceTokenResponse>(url, request);
};

export const deleteServiceTokenAsync = async (
  id: number
) => {
  const url = `${routeUrl}/${id}`;
  return await deleteDataAsync(url);
};