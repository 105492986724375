import { IlapButton, IlapButtonType, IlapPopup } from "ilap.common.webcomponents.test";
import DataGrid, { Column } from "devextreme-react/data-grid";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { displayLoadingPanel, hideLoadingPanel } from "components/common/LoadingPanel";
import { importMetadataFieldsAsync, loadMetadataFieldsAsync, loadMetadataFieldsFromFlowAsync } from "store/action/MetadataActions";
import { Type } from "shared/enums/TypeEnum";
import { FlowPlanningObjectTypes } from "shared/enums/FlowPlanningObjectTypesEnum";
import "./styles/FieldsImportModal.css";
import MetadataFieldWithContentControlResponse from "interfaces/response/MetadataFieldWithContentControlResponse";
import { DataGridItem } from "interfaces/common/DataGridItem";
import { PlanningObjectTypes } from "shared/enums/PlanningObjectTypesEnum";
import { toastSuccess } from "shared/utilities/ToastUtility";
import { FieldUtility } from "shared/utilities/FieldUtility";
import { unwrapResult } from "@reduxjs/toolkit";
import IlapSelectableGrid from "components/common/grid/IlapSelectableViewGrid";
import ErrorDisplayModal from "features/common/ErrorDisplayModal";
import IlapConfirmationDialog from "components/common/controls/IlapConfirmationDialog";
import { ConfirmationDialogInfo } from "interfaces/common/ConfirmationDialogInfo";

interface Props {
  onHideDialog: () => void;
}

type FieldFromFlow = MetadataFieldWithContentControlResponse & DataGridItem;

export default function FieldsImportModal(props: Props) {
  const dispatch = useDispatch<AppDispatch>();

  const [fieldsToImport, setFieldsToImport] = useState<FieldFromFlow[]>([]);
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [isErrorModalVisible, setIsErrorModalVisible] = useState<boolean>(false);
  const [confirmationDialogInfo, setConfirmationDialogInfo] = useState<ConfirmationDialogInfo>();
  const [isConfirmationDialogVisible, setIsConfirmationModalVisible] = useState<boolean>(false);

  const fieldsInSystem = useSelector((rootStore: RootState) => rootStore.metadataFieldData.metadataFields);
  const fieldsFromFlow = useSelector((rootStore: RootState) => rootStore.metadataFieldData.metadataFieldsFromFlow);

  const gridRef = useRef<DataGrid>(null);

  useEffect(() => {
    if (!fieldsFromFlow?.length) {
      displayLoadingPanel();
      dispatch(loadMetadataFieldsFromFlowAsync()).finally(hideLoadingPanel);
    }
  }, [dispatch, fieldsFromFlow?.length]);

  useEffect(() => {
    if (!fieldsInSystem?.length) {
      displayLoadingPanel();
      dispatch(loadMetadataFieldsAsync()).finally(hideLoadingPanel);
    }
  }, [dispatch, fieldsInSystem?.length]);

  useEffect(() => {
    if (!fieldsFromFlow?.length || fieldsInSystem?.length === undefined) {
      return;
    }

    const fields: FieldFromFlow[] = [];

    for (const fieldFromFlow of fieldsFromFlow) {
      const existingFieldByName = fieldsInSystem.find(field => field.name === fieldFromFlow.name);
      const existingFieldByIlapId = fieldsInSystem.find(field => field.ilapId === fieldFromFlow.ilapId);

      const statuses: string[] = [];
      let disabled: boolean = false;
      let conflicts: boolean = false;

      // if no match for code + ILAP ID then they are available
      if (!existingFieldByName && !existingFieldByIlapId) {
        statuses.push("Available");
        disabled = false;
      }
      else {
        disabled = true;
        // if the ids are the same, then it is the same field, so we check for planning object type and data type
        if (existingFieldByName && existingFieldByIlapId && existingFieldByName.id === existingFieldByIlapId.id) {
          const existingPlanningObjectType = PlanningObjectTypes[existingFieldByName.planningObjectTypes];
          const flowPlanningObjectType = FlowPlanningObjectTypes[fieldFromFlow.planningObjectTypes];

          if (existingPlanningObjectType !== flowPlanningObjectType) {
            statuses.push(`Already used by ${existingPlanningObjectType}`);
            conflicts = true;
          }
          else if (existingFieldByName.type !== fieldFromFlow.dataType) {
            statuses.push("Mismatch in data type");
            conflicts = true;
          }
          else {
            statuses.push("Already imported");
          }
        }
        // if the code and ILAP ID matched with different fields, add status for each match
        else {
          if (existingFieldByName) {
            statuses.push("Field code already exists");
            conflicts = true;
          }

          if (existingFieldByIlapId) {
            statuses.push("ILAP ID already exists");
            conflicts = true;
          }
        }
      }

      const status = conflicts ? `Conflicts (${statuses.join(", ")})` : statuses.join(", ");

      fields.push({ ...fieldFromFlow, disabled: disabled, status });
    }

    setFieldsToImport(fields)

  }, [fieldsFromFlow, fieldsInSystem]);

  const showErrorModal = () => setIsErrorModalVisible(true);
  const hideErrorModal = () => setIsErrorModalVisible(false);

  const showConfirmationModal = () => setIsConfirmationModalVisible(true);
  const hideConfirmationModal = () => setIsConfirmationModalVisible(false);

  const handleImportFields = (selectedFields: FieldFromFlow[]) => {
    hideConfirmationModal();
    
    const fieldsToCreate = selectedFields.filter(field => !field.disabled).map(field => FieldUtility.createFieldRequestFromFieldFromFlow(field));

    displayLoadingPanel();
    dispatch(importMetadataFieldsAsync(fieldsToCreate))
      .then(unwrapResult)
      .then(props.onHideDialog)
      .catch((error: any) => {
        let validationErrors = error?.validationErrorDisplay
          ? error?.validationErrorDisplay
          : error?.message;

        setValidationErrors(validationErrors.split("\n"));
        showErrorModal();
      })
      .finally(hideLoadingPanel);
  }

  const handleImportFieldsClick = () => {
    const selectedFields = gridRef.current?.instance.getSelectedRowsData() as FieldFromFlow[];

    if (selectedFields.length === 0) {
      toastSuccess("No fields selected");
      return;
    }

    setConfirmationDialogInfo({
      content: "Import selected fields",
      subContent: <div>Would you like to proceed with the import of the <strong>{selectedFields.length}</strong> selected {selectedFields.length > 1 ?  "fields" : "field"}?</div>,
      confirmButtonText: "Confirm",
      cancelButtonText: "Cancel",
      onConfirm: () => handleImportFields(selectedFields),
      onCancel: hideConfirmationModal
    });

    showConfirmationModal();
  }

  const planningObjectTypeCell = useCallback((data: any) => typeof data?.value === "number" && data.value in FlowPlanningObjectTypes ? FlowPlanningObjectTypes[data.value] : "-", []);

  const dataTypeCell = useCallback((data: any) => typeof data?.value === "number" && data.value in Type ? Type[data.value] : "-", []);

  const gridTitle = <div className="font-semibold">Fields from ILAP Data Exchange</div>;

  const toolbarConfig = useRef({ dislplaySearchPanel: true });

  const isSelectable = useCallback((item: FieldFromFlow) => !item.disabled, []);

  return (
    <IlapPopup
      width={"89vw"}
      height={720}
    >
      <div className="flex flex-col justify-between h-full">
        <header>
          <div className="font-poppins text-gray-9 text-[18px] font-semibold leading-normal">Import fields from ILAP Data Exchange</div>
          <hr className="mt-4 mb-4 border-light-gray" />
          <div>Select the fields you want to import with the checkboxes on the left side in the table. Please note that rows appearing greyed out indicate fields that have already been imported, or have conflicts and therefore cannot be imported. For further information, refer to the details provided in the status column.</div>
          <hr className="mt-4 mb-4 border-light-gray" />
        </header>

        <section className="field-import-grid">
          <IlapSelectableGrid
            dataSource={fieldsToImport}
            height={488}
            toolbarConfig={toolbarConfig.current}
            additionalWidget={gridTitle}
            ref={gridRef}
            isSelectable={isSelectable}
          >
            <Column caption="Planning object type" dataField="planningObjectTypes" customizeText={planningObjectTypeCell} alignment={"left"} width={170} />
            <Column caption="Field code" dataField="name" width={"14%"} />
            <Column caption="Description" dataField="description" />
            <Column caption="Data type" dataField="dataType" customizeText={dataTypeCell} alignment={"left"} width={100} />
            <Column caption="ILAP ID" dataField="ilapId" width={340} />
            <Column caption="Status" dataField="status" />
          </IlapSelectableGrid>
        </section>

        <footer className="flex flex-col justify-end mt-4">
          <div className="flex justify-end gap-4">
            <IlapButton variant={IlapButtonType.Secondary} className="px-4 rounded-lg font-medium" onClick={props.onHideDialog}>Cancel</IlapButton>
            <IlapButton className="px-4 font-medium" onClick={handleImportFieldsClick}>Import</IlapButton>
          </div>
        </footer>
        {isConfirmationDialogVisible &&
          <IlapConfirmationDialog
            height="181px"
            width="456px"

            content={confirmationDialogInfo?.content}
            subContent={confirmationDialogInfo?.subContent}
            cancelButtonText={confirmationDialogInfo?.cancelButtonText}
            confirmButtonText={confirmationDialogInfo?.confirmButtonText}

            onConfirm={confirmationDialogInfo?.onConfirm}
            onCancel={confirmationDialogInfo?.onCancel}
          />
        }
        {isErrorModalVisible && <ErrorDisplayModal errors={validationErrors} onHideDialog={hideErrorModal} />}
      </div>
    </IlapPopup>
  );
}