import InfoWidget from "components/common/InfoWidget";
import PageTitle from "components/common/PageTitle";
import ValidatedContent from "components/common/ValidatedContent";
import WidgetsWithSeparator from "components/common/WidgetsWithSeparator";
import DataGrid, { Column, Selection, Editing } from "devextreme-react/data-grid";
import { AddNewButton, IlapButton, IlapButtonType, IlapSelectBox, IlapTextBox, IlapViewGrid } from "ilap.common.webcomponents.test";
import MetadataField from "interfaces/response/MetadataField";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { AppDispatch } from "store/store";
import "./styles/FieldEdit.css";
import { PlanningObjectTypes } from "shared/enums/PlanningObjectTypesEnum";
import { FieldValueInput } from "interfaces/common/FieldValueInput";
import { FieldValueUtility } from "shared/utilities/FieldValueUtility";
import { unwrapResult } from "@reduxjs/toolkit";
import { displayGridLoadingPanel, displayLoadingPanel ,hideLoadingPanel } from "components/common/LoadingPanel";
import { toastError, toastSuccess } from "shared/utilities/ToastUtility";
import { deleteMetadataFieldAsync, loadSingleMetadataFieldAsync, updateMetadataFieldAsync } from "store/action/MetadataActions";
import { FieldUtility } from "shared/utilities/FieldUtility";
import { FIELDS } from "shared/constants/RoutePathConstants";
import { ConfirmationDialogInfo } from "interfaces/common/ConfirmationDialogInfo";
import IlapConfirmationDialog from "components/common/controls/IlapConfirmationDialog";
import TrashCanButton from "components/common/buttons/TrashCanButton";
import { TextUtility } from "shared/utilities/TextUtility";
import FieldValueEditorModal from "./modal/FieldValueEditorModal";
import ErrorDisplayModal from "features/common/ErrorDisplayModal";
import { FieldValidationUtility } from "../common/utility/FieldValidationUtility";
import { IlapUtility } from "shared/utilities/IlapUtility";
import NavigationBlocker from "features/common/NavigationBlocker";
import { Type } from "shared/enums/TypeEnum";

interface PlanningObject {
  type: PlanningObjectTypes,
  name: string
}
export default function FieldEdit() {
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams();
  let navigate = useNavigate();

  const [metadataField, setMetadataField] = useState<MetadataField>(FieldUtility.createEmptyMetadataField());
  const [initialTitle, setInitialTitle] = useState<string>("");

  const [fieldValues, setFieldValues] = useState<FieldValueInput[]>([]);

  const [isTitleMissing, setIsTitleMissing] = useState<boolean>(false);
  const [isIlapIdMissing, setIsIlapIdMissing] = useState<boolean>(false);

  const [isModified, setIsModified] = useState<boolean>(false);
  const [confirmationDialogInfo, setConfirmationDialogInfo] = useState<ConfirmationDialogInfo>();
  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState<boolean>(false);

  const [isDeleteItemsEnabled, setIsDeleteItemsEnabled] = useState<boolean>(false);
  const [isFieldValueEditorModalVisible, setIsFieldValueEditorModalVisible] = useState<boolean>(false);

  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [isErrorModalVisible, setIsErrorModalVisible] = useState<boolean>(false);
  const [isNavBlockerActive, setIsNavBlockerActive] = useState<boolean>(true);

  const tableEditorRef = useRef<DataGrid>(null);

  useEffect(() => {
    if (params.id) {
      displayGridLoadingPanel();
      dispatch(loadSingleMetadataFieldAsync({ metadataFieldId: Number(params.id) }))
        .then(unwrapResult)
        .then((field: MetadataField) => {
          setMetadataField(field);
          setInitialTitle(field.name);
          setFieldValues(FieldValueUtility.convertMetadataFieldValuesToFieldValueInputs(field.metadataFieldValues));
        })
        .catch(() => {
          toastError("Failed to load metadata field.");
        })
        .finally(hideLoadingPanel);
    }
  }, [params?.id, dispatch]);

  const planningObjects: PlanningObject[] = [
    {
      type: PlanningObjectTypes.Schedule,
      name: PlanningObjectTypes[PlanningObjectTypes.Schedule]
    },
    {
      type: PlanningObjectTypes.Activity,
      name: PlanningObjectTypes[PlanningObjectTypes.Activity]
    }
    ];

  const dataTypes = FieldUtility.getDataTypes();

  const deactivateNavigationBlocker = () => setIsNavBlockerActive(false);

  const navigateToViewPage = () => {
    deactivateNavigationBlocker();
    navigate(`${FIELDS}/${params.id}`);
  }
  const navigateToGridPage = () => {
    deactivateNavigationBlocker();
    navigate(FIELDS);
  }

  const showConfirmationDialog = () => setIsConfirmationDialogVisible(true);
  const hideConfirmationDialog = () => setIsConfirmationDialogVisible(false);

  const showFieldValueEditor = () => setIsFieldValueEditorModalVisible(true);
  const hideFieldValueEditor = () => setIsFieldValueEditorModalVisible(false);

  const handleValueChange = (fieldName: string, value: string | number) => {
    if (typeof value === "string") {
      value = value.trim();
    }

    setMetadataField(prev => {
      return { ...prev, [fieldName]: value }
    });

    switch (fieldName) {
      case "name":
        setIsTitleMissing(false);
        break;
      case "ilapId":
        setIsIlapIdMissing(false);
        break;
    }

    setIsModified(true);
  }

  const titleEditor = useMemo(() => {
    return (
      <IlapTextBox
        placeholder="Type..."
        value={metadataField.name}
        className="title"
        width={"100%"}
        validationStatus={isTitleMissing ? "invalid" : "valid"}
        onChange={({ event }: any) => handleValueChange("name", event.currentTarget.value)}
      />
    )
  }, [metadataField.name, isTitleMissing]);

  const usedBySelector = useMemo(() => {
    return (
      <IlapSelectBox
        placeholder="Select"
        items={planningObjects}
        value={metadataField.planningObjectTypes}
        displayExpr={"name"}
        valueExpr={"type"}
        className="editor"
        width={"160px"}
        onValueChange={(type: number) => handleValueChange("planningObjectTypes", type)}
      />
    )
  }, [metadataField.planningObjectTypes]);

  const isIlapIdInvalid = useMemo(() => {
    const ilapId = metadataField.ilapId.trim();
    return ((ilapId && !IlapUtility.validateIlapId(ilapId))) === true;
  }, [metadataField.ilapId]);

  const ilapIdEditor = useMemo(() => {
    return (
      <ValidatedContent validationMessage="Valid ILAP Id is required" showValidationMessage={isIlapIdInvalid}>
        <IlapTextBox
          value={metadataField.ilapId}
          className="editor"
          validationStatus={(isIlapIdInvalid || isIlapIdMissing) ? "invalid" : "valid"}
          showValidationMessage={isIlapIdMissing}
          onChange={({ event }: any) => handleValueChange("ilapId", event.currentTarget.value)}
        />
      </ValidatedContent>
    )
  }, [metadataField.ilapId, isIlapIdMissing, isIlapIdInvalid]);

  const dataTypeSelector = useMemo(() => {
    return (
      <IlapSelectBox
        placeholder="Select"
        items={dataTypes}
        value={metadataField.type}
        displayExpr={"name"}
        valueExpr={"type"}
        className="editor"
        width={"160px"}
        onValueChange={(type: number) => handleValueChange("type", type)}
      />
    )
  }, [metadataField.type]);

  const descriptionEditor = useMemo(() => {
    return (
      <IlapTextBox
        placeholder="Type..."
        value={metadataField.description}
        className="editor"
        width={"100%"}
        onChange={({ event }: any) => handleValueChange("description", event.currentTarget.value)}
      />
    )
  }, [metadataField.description]);

  const handleSubmitFieldValues = (items: FieldValueInput[]) => {
    if (!fieldValues.length && !items.length) {
      return;
    }

    // merge them with original field values based on code
    const mergedItems = FieldValueUtility.mergeFieldValueInputs(metadataField.metadataFieldValues, items);

    setFieldValues([...mergedItems]);
    setIsModified(true);
  }

  const handleDeleteFieldValues = () => {
    const selectedValues = tableEditorRef.current?.instance.getSelectedRowKeys() as FieldValueInput[];
    setFieldValues(prev => prev.filter(fieldValue => !selectedValues.some(v => v.__KEY__ === fieldValue.__KEY__)));
    setIsModified(true);
  }

  const handleRowSelectionChange = () => {
    // Enable Delete Fields Button (Trash Can) if any row is selected in the Grids 
    tableEditorRef.current?.instance.getSelectedRowKeys().length
      ? setIsDeleteItemsEnabled(true)
      : setIsDeleteItemsEnabled(false);
  }

  const handleEditorCellUpdated = () => {
    setIsModified(true);
  }

  const checkIfPendingChangesExistInEditor = (): boolean => {
    return tableEditorRef.current?.instance.hasEditData() ? true : false;
  }

  const handleSaveEditorChanges = () => {
    tableEditorRef.current?.instance.saveEditData();
    setIsModified(true);
  }

  const handleDeleteField = () => {
    displayLoadingPanel();
    dispatch(
      deleteMetadataFieldAsync(Number(params.id))
    )
      .then(response => {
        if (response.meta.requestStatus === "fulfilled") {
          navigateToGridPage();
        }
      })
      .finally(hideLoadingPanel)
      .finally(hideConfirmationDialog);
  }

  const widgets = [
    <InfoWidget label="Used by" data={usedBySelector} />,
    <InfoWidget label="ILAP ID" data={ilapIdEditor} minWidth="400px" />,
    <InfoWidget label="Data type" data={dataTypeSelector} />,
    <InfoWidget label="Description" data={descriptionEditor} width="100%" minWidth="150px" />
  ];

  const valueCodeEditorComponent = useCallback((data: any) => {
    const fieldValue = data.data.data as FieldValueInput;
    const validationStatus = fieldValue.__ERROR__?.length ? "invalid" : "valid";
    return <IlapTextBox width={"100%"} defaultValue={fieldValue.code} validationStatus={validationStatus} />
  }, []);

  const descriptionEditorComponent = useCallback((data: any) => {
    const fieldValue = data.data.data as FieldValueInput;
    return <IlapTextBox width={"100%"} defaultValue={fieldValue.description} />
  }, []);

  const addFieldValueButtonComponent = useMemo(() => {
    return <AddNewButton text={"Add values"} onClick={showFieldValueEditor} />;
  }, []);

  const deleteFieldValuesButtonComponent = useMemo(() => {
    return <TrashCanButton disabled={!isDeleteItemsEnabled} onClick={handleDeleteFieldValues} />
  }, [isDeleteItemsEnabled]);

  const handleDeleteClick = () => {
    setConfirmationDialogInfo({
      content: "Delete field?",
      subContent: "This action cannot be undone. You will permanently loose the field and its content.",
      confirmButtonText: "Delete permanently",
      cancelButtonText: "Cancel",
      isDeleteConfirmation: true,
      onConfirm: handleDeleteField,
      onCancel: hideConfirmationDialog
    });

    showConfirmationDialog();
  }

  const handleCancelClick = () => {
    if (isModified) {
      setConfirmationDialogInfo({
        content: "Cancel changes",
        subContent: "The changes you’ve made to the field will not be saved.",
        confirmButtonText: "Confirm",
        cancelButtonText: "Continue editing",
        onConfirm: navigateToViewPage,
        onCancel: hideConfirmationDialog
      });

      showConfirmationDialog();

      return;
    }

    navigateToViewPage();
  }

  const validateRequiredGeneralInfo = (): boolean => {
    let isValid = true;

    if (!metadataField.name.trim()) {
      isValid = false;
      setIsTitleMissing(true);
    }
    if (!metadataField.ilapId.trim()) {
      isValid = false;
      setIsIlapIdMissing(true);
    }
    if (isIlapIdInvalid) isValid = false;

    return isValid;
  }

  const validateTableEditor = (): boolean => {
    const result = FieldValidationUtility.validateFieldValues(fieldValues);
    setFieldValues(result.data);
    return result.isValidationSuccessful;
  }

  const createMetadataFieldRequestModel = (fieldValues: FieldValueInput[]): MetadataField => {
    const fieldRequest = { ...metadataField };
    const mergedFieldValues = FieldValueUtility.mergeFieldValueInputs(metadataField.metadataFieldValues, fieldValues);

    fieldRequest.metadataFieldValues = FieldValueUtility.convertFieldValueInputsToMetadataFieldValues(fieldRequest.id, mergedFieldValues);
    return fieldRequest;
  }

  const handleUpdateField = () => {
    const fieldRequest = createMetadataFieldRequestModel(fieldValues);

    displayLoadingPanel();
    dispatch(updateMetadataFieldAsync(fieldRequest))
      .then(unwrapResult)
      .then(navigateToViewPage)
      .catch((error: any) => {
        if (error?.validationErrorDisplay) {
          setValidationErrors(error?.validationErrorDisplay.split("\n"));
          setIsErrorModalVisible(true);
        }
      })
      .finally(hideLoadingPanel)
      .finally(hideConfirmationDialog);
  }

  const handleSaveChangesClick = () => {
    const changesExist = checkIfPendingChangesExistInEditor();

    if (changesExist) {
      handleSaveEditorChanges();
    }

    if (changesExist || isModified) {
      // validate required fields
      if (!validateRequiredGeneralInfo()) {
        return;
      }

      if (!validateTableEditor()) {
        // If type is not 'String', we are not showing the field value editor.
        // Therefore the error highlight in the grid can not be seen. In this case we
        // display a toast to the user.
        if(metadataField.type !== Type.String){
          toastError("Value code validation failed");
        }

        return;
      }

      setConfirmationDialogInfo({
        content: "Save changes",
        subContent: (
          <div title={initialTitle}>
            <span>{"You have made changes to "}</span>
            <span className="break-words">{`${TextUtility.truncate(initialTitle, 50)}.`}</span>
            <span>{" Confirm to save changes."}</span>
          </div>
        ),
        confirmButtonText: "Confirm change",
        cancelButtonText: "Continue editing",
        onConfirm: handleUpdateField,
        onCancel: hideConfirmationDialog
      });

      showConfirmationDialog();
    }
    else {
      toastSuccess("Saved, no new changes.");
    }
  }

  const hasNavigationBlockerPreCondition = useCallback(() => {
    let shouldBlockNavigation = false;
    // We are calling the setState method to get the latest state of 'isNavBlockerActive', 
    // this can be stale due to React update cycle behavior
    setIsNavBlockerActive(prev => {
      shouldBlockNavigation = prev; // getting latest state
      return prev;
    });

    if (!shouldBlockNavigation){
      return false;
    }
    
    return isModified;

  }, [isNavBlockerActive, isModified]);

  return (
    <div className="field-edit mx-14">
      <NavigationBlocker preCondition={hasNavigationBlockerPreCondition} warningMessage="The changes you’ve made to the field will not be saved." /> 
      <div className="mb-3 -mt-0.5 font-poppins text-[18px] text-dark-blue-1 font-semibold leading-normal flex">
        <ValidatedContent wrapperWidth="100%" showValidationMessage={isTitleMissing}>
          <PageTitle title={titleEditor} titleClass="w-full" />
        </ValidatedContent>
      </div>
      <div>
        <WidgetsWithSeparator widgets={widgets} />
      </div>
      <hr className="mt-[15px] mb-[15px] border-light-gray" />
      {metadataField.type === Type.String ?
        <div>
          {/* Toolbar */}
          <div className="flex justify-end items-center gap-2 mb-[7px]">
            {deleteFieldValuesButtonComponent}
            {addFieldValueButtonComponent}
          </div>
          <IlapViewGrid
            ref={tableEditorRef}
            dataSource={fieldValues}
            className="h-[calc(100vh-312px)]"
            onSelectionChanged={handleRowSelectionChange}
            onSaved={handleEditorCellUpdated}
          >
            <Selection mode="multiple" showCheckBoxesMode="always" />
            <Editing mode={"cell"} allowUpdating={true} />
            <Column
              caption="Value code"
              dataField={"code"}
              cellComponent={valueCodeEditorComponent}
              allowFiltering={false}
              width={280}
            />
            <Column
              caption="Description (optional)"
              dataField="description"
              cellComponent={descriptionEditorComponent}
              allowFiltering={false}
            />
          </IlapViewGrid>
        </div>
        : <div className="h-[calc(100vh-272px)]"></div>
      }
      <div className="mb-4 mt-4 flex justify-between">
        <div>
          <IlapButton
            text="Delete field"
            variant={IlapButtonType.Tertiary}
            onClick={handleDeleteClick}
          />
        </div>
        <div className="flex gap-3">
          <IlapButton
            text="Cancel"
            variant={IlapButtonType.Secondary}
            onClick={handleCancelClick}
          />
          <IlapButton
            text="Save changes"
            variant={IlapButtonType.Primary}
            onClick={handleSaveChangesClick}
          />
        </div>
      </div>

      {isFieldValueEditorModalVisible &&
        <FieldValueEditorModal
          items={fieldValues}
          onHideModal={hideFieldValueEditor}
          onSubmit={handleSubmitFieldValues}
        />
      }

      {isConfirmationDialogVisible &&
        <IlapConfirmationDialog
          height="181px"
          width="456px"

          content={confirmationDialogInfo?.content}
          subContent={confirmationDialogInfo?.subContent}
          cancelButtonText={confirmationDialogInfo?.cancelButtonText}
          confirmButtonText={confirmationDialogInfo?.confirmButtonText}

          onConfirm={confirmationDialogInfo?.onConfirm}
          onCancel={confirmationDialogInfo?.onCancel}

          isDeleteConfirm={confirmationDialogInfo?.isDeleteConfirmation}
        />
      }
      {isErrorModalVisible && <ErrorDisplayModal errors={validationErrors} onHideDialog={() => setIsErrorModalVisible(false)} />}
    </div>
  );
}