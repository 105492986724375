import moment from "moment";

export const getFormattedDateForColumn = (date: string) => {
  return moment(date).format("MM/DD/YYYY");
};

export const getDefaultLocaleFormattedDate = (date: string) => {
  return new Date(date).toLocaleDateString();
}

export const getFormattedDateTimeForColumn = (date: string) => {
  return moment(date).format("h:m MM/DD/YYYY");
};

export const getDefaultLocaleFormattedDateTime = (date: string) => {
  return moment.utc(date).local().format("hh:mm A DD.MM.YYYY");
}

export const getDurationSince = (startTime: string) => {
  // Parse the given start time as UTC
  const start = moment.utc(startTime);

  // Get the current UTC time
  const now = moment.utc();

  // Calculate the difference in milliseconds
  const durationMs = now.diff(start);

  // Convert to duration to get hours and minutes
  const duration = moment.duration(durationMs);

  // Extract hours and minutes
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  return `${hours}h${minutes.toString().padStart(2, "0")}m`;
};