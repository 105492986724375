import IlapPopover from "components/common/controls/IlapPopover";
import { Offset, Position } from "devextreme-react/popover";
import NavItemOption from "interfaces/NavItemOption";
import { useLocation } from "react-router-dom";
import { checkIfNavItemIsActive } from "shared/utilities/RouteUtility";
import { getNavItemId } from "./NavigationItems";

interface Props {
  navItems: NavItemOption[];
  target: string;
  visible: boolean;
  onHiding: () => void;
  onNavigation: (routeToNavigate: string | undefined) => void;
}

export default function SubNavMenu(props: Props) {
  const location = useLocation();

  return (
    <>
      <IlapPopover
        width={"auto"}
        minWidth={160}
        height={"auto"}
        visible={props.visible}
        target={props.target}
        onHiding={props.onHiding}
      >
        <Position my={{ x: "right", y: "top" }}>
          <Offset x={80} y={-25} />
        </Position>
        <div className="py-2">
          {props.navItems.map((it) => {
            return (
              <div
                key={getNavItemId(it.itemId)}
                className={`font-poppins font-15px leading-22px hover:bg-lightBlue space-y-4 py-2.5 px-4 cursor-pointer ${
                  checkIfNavItemIsActive(location.pathname, it)
                    ? "font-bold"
                    : "font-medium"
                }`}
                onClick={(e) => {
                  props.onNavigation(it.route);
                  e.stopPropagation();
                }}
              >
                {it.text}
              </div>
            );
          })}
        </div>
      </IlapPopover>
    </>
  );
}
