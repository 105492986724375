import ProcessingLabel from "./ProcessingLabel";
import PromineoLabel from "./PromineoLabel";

interface Props {
  id?: string | number,
  title?: string | React.ReactElement,
  menu?: React.ReactElement,
  titleClass?: string,
  label?: string,
  status?: React.ReactElement,
  isProcessing?: boolean,
  onProcessingClick?: () => void
}

export default function PageTitle(props: Props) {
  const { id, title, menu, titleClass, label, isProcessing, onProcessingClick } = props;
  const titleTooltip = typeof title === 'string' ? title : undefined;

  return (
    <div className="flex items-center justify-between w-full">
      <div className="flex truncate items-center">
        {id && <div className="mr-2">{id}</div>}
        {title && <div className={`flex-initial ${titleClass} truncate`} title={titleTooltip}>{title}</div>}
        {label && <PromineoLabel label={label} className="ml-2 promineo-bordered-label" />}
        {props.status && <div className="ml-2">{props.status}</div>}
        {isProcessing && onProcessingClick && <div className="ml-3"><ProcessingLabel onProcessingClick={onProcessingClick} /></div>}
      </div>
      {menu && <div>{menu}</div>}
    </div>
  )
}