import { getCurrentAccountInfo, signOut } from "authentication/AuthenticationManager";
import Avatar from "components/common/Avatar";
import IlapPopover from "components/common/controls/IlapPopover";
import { Offset, Position } from "devextreme-react/popup";
import { IlapButton, IlapButtonType } from "ilap.common.webcomponents.test";

interface Props {
  target: string;
  visible: boolean;
  onHiding: () => void;
}

export default function AccountPopover(props: Props) {
  const currentAccountInfo = getCurrentAccountInfo();

  const nameParts = currentAccountInfo?.name?.split(" ") ?? ["hello"];

  const firstName = nameParts[0] ?? "";
  const lastName = nameParts[1] ?? "";

  const Content = () => {
    return (
      <div className="h-full flex flex-col justify-between px-4 py-5">
        <div className="flex font-poppins">
          <div className="flex-shrink-0 mr-3">
            <Avatar 
              firstName={firstName}
              lastName={lastName}
            />
          </div>
          <div className="truncate">
            <div className="text-sm font-medium">{currentAccountInfo?.name ?? ""}</div>
            <div className="text-xs font-normal">{currentAccountInfo?.username ?? ""}</div>
          </div>
        </div>
        <div>
          <IlapButton
            className="border-none"
            onClick={signOut}
            variant={IlapButtonType.Primary}
            width={180}
          >
            Sign out
          </IlapButton>
        </div>
      </div>
    );
  };

  return (
    <>
      <IlapPopover width={264} height={130} {...props}>
        <Position my={{ x: "right", y: "top" }}>
          <Offset x={30} y={-10} />
        </Position>
        <Content />
      </IlapPopover>
    </>
  );
}
