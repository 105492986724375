import InfoIconSmall from "./icons/InfoIconSmall";

interface Props {
  text?: string
}

export default function TooltipIcon(props: Props) {
  return (
    <div title={props.text}>
      <InfoIconSmall />
    </div>
  )
}