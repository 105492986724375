import BatchOperationResponse from "interfaces/response/BatchOperationResponse";
import BackgroundJob from "./BackgroundJob";
import BackgroundJobResponse from "interfaces/response/BackgroundJobResponse";

interface Props {
  batch: BatchOperationResponse;
}

export function BatchOperation(props: Props) {
  const { batch } = props;
  return (
    <div>
      <hr className="border-gray-400 ml-8 mb-1" />
      <div className="flex flex-col gap-y-2">
        {batch.backgroundJobs.map((job: BackgroundJobResponse, index: number) => {
          return <BackgroundJob key={index} jobIndex={index + 1} job={job} />
        })}
      </div>
    </div>
  )
}