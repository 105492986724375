import { createSlice } from "@reduxjs/toolkit";
import { UserAuthProfileResponse } from "interfaces/response/UserAuthProfileResponse";
import { loadCurrentUserAuthProfileAsync } from "store/action/UserActions";

export interface UserState {
  profile: UserAuthProfileResponse | undefined
}

const initialState: UserState = {
  profile: undefined
}

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      loadCurrentUserAuthProfileAsync.fulfilled,
      (state, { payload }: { payload: UserAuthProfileResponse }) => {
        state.profile = payload;
      }
    );
  }
});