import DataGrid, { Column } from "devextreme-react/data-grid";
import { IlapViewGrid } from "ilap.common.webcomponents.test";
import MetadataField from "interfaces/response/MetadataField";
import { forwardRef, useCallback } from "react";
import { useNavigate } from "react-router";
import { getCellValueOrDefault } from "shared/utilities/DataGridUtility";

interface Props {
  data?: MetadataField[];
}

const FieldsGrid = forwardRef<DataGrid, Props>((props, ref) => {
  let navigate = useNavigate();

  const navigateToViewPage = useCallback((id: number) => {
    navigate(`/fields/${id}`);
  }, [navigate]);
  
  const handleRowClick = useCallback((data: any) => {
    navigateToViewPage(data.data.id);
  }, [navigateToViewPage]);

  return (
    <IlapViewGrid 
      dataSource={props.data} className="h-[calc(100vh-208px)]" columnMinWidth={100}
      onRowClick={handleRowClick}
      ref={ref}
    >
      <Column caption={"Field ID"} dataField={"id"} width={140} alignment="left" />
      <Column caption={"Title"} dataField={"name"} minWidth={100} />
      <Column caption={"Description"} dataField={"description"} width={"30%"} />
      <Column caption={"ILAP ID"} dataField={"ilapId"} width={340} />
      <Column caption={"Date created"} dataField={"created"} width={160} dataType={"datetime"} allowSearch={false} customizeText={getCellValueOrDefault} />
      <Column caption={"Date updated"} dataField={"lastModified"} width={160} dataType={"datetime"} allowSearch={false} customizeText={getCellValueOrDefault} />
      <Column caption={"Updated by"} dataField={"lastModifierName"} width={200} />
    </IlapViewGrid>
  );
});

export default FieldsGrid;