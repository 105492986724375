import { createSlice } from "@reduxjs/toolkit";
import ServiceTokenResponse from "interfaces/response/ServiceTokenResponse";
import { toastSuccess } from "shared/utilities/ToastUtility";
import { createTokenAsync, deleteTokenAsync, loadServiceTokensAsync, updateTokenAsync } from "store/action/ServiceTokenActions";

export interface ServiceTokenState {
  serviceTokens: ServiceTokenResponse[]
}

const initialState: ServiceTokenState = {
  serviceTokens: []
}

const updateServiceTokenInState = (tokenId: number, updatedServiceToken: ServiceTokenResponse,state: ServiceTokenState) => {
  const existingTokenIndex = state.serviceTokens.findIndex((token: ServiceTokenResponse) => token.id === tokenId);

  if (existingTokenIndex > -1) {
    state.serviceTokens[existingTokenIndex] = updatedServiceToken;
  }
};

const deleteServiceTokenFromState = (tokenId: number, state: ServiceTokenState) => {
  state.serviceTokens = state.serviceTokens.filter((token) => token.id !== tokenId);
};

export const serviceTokenSlice = createSlice({
  name: "serviceTokenSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      loadServiceTokensAsync.fulfilled,
      (state, { payload }: { payload: ServiceTokenResponse[] }) => {
        state.serviceTokens = payload;
      }
    );
    builder.addCase(createTokenAsync.fulfilled, (state, action) => {
      const { token, ...serviceToken } = action.payload; // don't need token secret in state
      state.serviceTokens.unshift(serviceToken);
      toastSuccess("Created service token successfully.");
    });
    builder.addCase(updateTokenAsync.fulfilled, (state, action) => {
      updateServiceTokenInState(action.payload.id, action.payload, state);

      if (action.payload.isActive) {
        toastSuccess("Activated service token successfully");
      } else {
        toastSuccess("Deactivated service token successfully");
      }
    });

    builder.addCase(deleteTokenAsync.fulfilled, (state, action) => {
      const deletedTokenId = action.meta.arg;
      deleteServiceTokenFromState(deletedTokenId, state);
      toastSuccess("Deleted service token successfully");
    });
  }
});