interface Props {
  className: string;
}

export default function CopyIcon(props: Props) {
  return (
    <svg className={props.className} width="19" height="22" viewBox="0 0 19 22" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 0H2C0.9 0 0 0.9 0 2V16H2V2H14V0ZM17 4H6C4.9 4 4 4.9 4 6V20C4 21.1 4.9 22 6 22H17C18.1 22 19 21.1 19 20V6C19 4.9 18.1 4 17 4ZM17 20H6V6H17V20Z" />
    </svg>

  );
}