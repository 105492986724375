import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import "./styles/App.css";
import "./styles/Constants.css";
import "react-toastify/dist/ReactToastify.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoadingPanel from "components/common/LoadingPanel";
import { IPublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { AppRoutes } from "Routes";
import { toastContainerOption } from "shared/utilities/ToastUtility";

interface Props {
  msalInstance: IPublicClientApplication
}

function App(props: Props) {
  const router = createBrowserRouter([...AppRoutes]);

  return (
    <MsalProvider instance={props.msalInstance}>
      <div className="App">
        <div className="m-auto">
          <RouterProvider router={router} />
          <ToastContainer {...toastContainerOption} />
          <LoadingPanel />
        </div>
      </div>
    </MsalProvider>
  );
}

export default App;
