import ExternalLinkButton from "components/common/buttons/icon-buttons/ExternalLinkButton";

export default function HelpCenterItem(){
  return (
    <div className="h-14 flex justify-between items-center font-poppins mx-4">
      <div>
        <div className="text-base">ILAP Analytics help center</div>
        <div className="text-xs text-blue-600">Coming soon</div>
      </div>
      <div>
        <ExternalLinkButton />
      </div>
    </div>
  )
}