export const LANDING = "/";
export const HOME = "/home"
export const SIGN_IN = "/sign-in";
export const LOG_OUT = "/logout";
export const REPORT_SCHEDULES = "/report-schedules";
export const REPORT_SCHEDULE_REVISIONS = "/report-schedules/:id/schedules";
export const REPORT_SCHEDULES_EDIT_SCHEDULE = "/report-schedules/schedules/edit/:id";
export const SCHEDULE_TYPES = "/schedule-types";
export const SCHEDULE_TYPE_SINGLE_VIEW = "/schedule-types/:id";
export const SCHEDULE_TYPE_SINGLE_EDIT = "/schedule-types/:id/edit";
export const FIELDS = "/fields";
export const FIELD_SINGLE_VIEW = "/fields/:id";
export const FIELD_SINGLE_EDIT = "/fields/:id/edit";
export const ADMIN = "/admin"
export const TEST = "/test";