import ReportScheduleMetadataFieldValue from "interfaces/response/ReportScheduleMetadataFieldValue";
import ScrollView from "devextreme-react/scroll-view";
import "../styles/ReportScheduleView.css";
import ReportScheduleField from "./ReportScheduleField";
import { loadSingleReportScheduleAsync } from "store/action/ReportScheduleActions";
import { unwrapResult } from "@reduxjs/toolkit";
import { displayGridLoadingPanel, hideLoadingPanel } from "components/common/LoadingPanel";
import MetadataField from "interfaces/response/MetadataField";
import ReportScheduleResponse from "interfaces/response/ReportScheduleResponse";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { PlanningObjectTypes } from "shared/enums/PlanningObjectTypesEnum";
import { ValidationRequirement } from "shared/enums/ValidationRequirementEnum";
import { AppDispatch } from "store/store";
import { createEmptyReportSchedule } from "../ReportScheduleUtility";
import { sortFieldsByRequirement } from "shared/utilities/ScheduleTypeFieldUtility";

interface Props {
  reportScheduleId: number;
}

const emptyReportSchedule: ReportScheduleResponse = createEmptyReportSchedule();

export default function ReportScheduleInformationView(props: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const reportScheduleId = props.reportScheduleId;

  const [definedFieldValues, setDefinedFieldValues] = useState<ReportScheduleMetadataFieldValue[]>([]);
  const [additionalFieldValues, setAdditionalFieldValues] = useState<ReportScheduleMetadataFieldValue[]>([]);
  const [selectedReportSchedule, setSelectedReportSchedule] = useState<ReportScheduleResponse>(emptyReportSchedule);

  useEffect(() => {
    displayGridLoadingPanel();
    dispatch(loadSingleReportScheduleAsync({ reportScheduleId: reportScheduleId }))
      .then(unwrapResult)
      .then((reportSchedule: ReportScheduleResponse) => {
        setSelectedReportSchedule(reportSchedule);
      })
      .then(hideLoadingPanel);
  }, [dispatch, reportScheduleId]);

  useEffect(() => {
    const reportScheduleMetadataFieldValue: ReportScheduleMetadataFieldValue[] = [];

    // we load schedule type with metadata with report schedule
    // no need to load schedule type separately again
    var scheduleTypeFields = selectedReportSchedule?.reportScheduleType?.metadataFields;

    // --- populate defined fields
    if (scheduleTypeFields) {
      for (let metadataFieldIndex = 0; metadataFieldIndex < scheduleTypeFields?.length; metadataFieldIndex++) {
        let metadataField = scheduleTypeFields[metadataFieldIndex];

        if (metadataField.planningObjectTypes === PlanningObjectTypes.Schedule) {
          reportScheduleMetadataFieldValue.push({
            metadataFieldId: metadataField.id,
            name: metadataField.name,
            displayDropdown: metadataField.validationRequirement === ValidationRequirement.ValueInList,
            dropdownSource: metadataField.metadataFieldValues,
            isRequired: metadataField.validationRequirement === ValidationRequirement.ValueInList || metadataField.validationRequirement === ValidationRequirement.NonBlankValue
          });
        }
      }
    }

    selectedReportSchedule.metadataFieldValues.forEach((fieldValues) => {
      const metadataFieldValueIndex = reportScheduleMetadataFieldValue.findIndex(
        (metadata: ReportScheduleMetadataFieldValue) =>
          metadata.metadataFieldId === fieldValues.metadataFieldId
      );

      if (metadataFieldValueIndex > -1) {
        const reportScheduleMetadata = reportScheduleMetadataFieldValue[metadataFieldValueIndex];

        reportScheduleMetadata.value = fieldValues.stringValue;
        reportScheduleMetadata.id = fieldValues.id;
      }
    });

    var sortedFieldsByRequirement = sortFieldsByRequirement(reportScheduleMetadataFieldValue);

    setDefinedFieldValues(sortedFieldsByRequirement);


    // --- populate additional fields
    const additionalFieldValues = selectedReportSchedule.metadataFieldValues
      .filter(value => !scheduleTypeFields?.some(field => field.id === value.metadataFieldId))
      .map(fieldValue => ({
        id: fieldValue.id,
        metadataFieldId: fieldValue.metadataFieldId,
        name: fieldValue.name ?? "Additional field",
        value: fieldValue.value,
        displayDropdown: false,
        dropdownSource: [],
        isReadOnly: true,
      }));

    setAdditionalFieldValues(additionalFieldValues);
  }, [selectedReportSchedule]);

  const fieldsSectionCss = "flex h-[47%]";
  const fieldsGridCss = "ml-7 grid grid-col-1 gap-y-5";
  const fieldsSectionTitleCss = "font-poppins font-semibold text-15px w-60";

  return (
    <div className="text-gray-9 h-full flex flex-col gap-5">
      <div className={fieldsSectionCss}>
        <div className={fieldsSectionTitleCss}>Defined schedule fields</div>
        <div className="w-full">
          <ScrollView
            direction="vertical"
            showScrollbar="always"
            key={definedFieldValues.length}
          >
            <div className={fieldsGridCss}>
              {definedFieldValues.map((value, index) => (
                <ReportScheduleField key={index} fieldValue={value} />
              ))}
            </div>
          </ScrollView>
        </div>
      </div>

      <hr className="border-light-gray" />

      <div className={fieldsSectionCss}>
        <div className={fieldsSectionTitleCss}>Additional schedule fields</div>
        <div className="w-full">
          <ScrollView
            direction="vertical"
            showScrollbar="always"
            key={additionalFieldValues.length}
          >
            <div className={fieldsGridCss}>
              {additionalFieldValues.map((value, index) => (
                <ReportScheduleField key={index} fieldValue={value} />
              ))}
            </div>
          </ScrollView>
        </div>
      </div>
    </div>
  )
}