import { ScrollView } from "devextreme-react";
import ReportScheduleMetadataFieldValue from "interfaces/response/ReportScheduleMetadataFieldValue";
import MetadataFieldValue from "interfaces/common/MetadataFieldValue";
import ReportScheduleFieldEditor from "./ReportScheduleFieldEditor";
import { sortFieldsByRequirement } from "shared/utilities/ScheduleTypeFieldUtility";
import ReportScheduleField from "../view/ReportScheduleField";

interface Props {
  definedFieldValues: ReportScheduleMetadataFieldValue[];
  additionalFieldValues: ReportScheduleMetadataFieldValue[];
  handleDropdownSelectionChange: (selectedItem: MetadataFieldValue) => void;
  handleMetadataFieldValueChange: (metadataFieldId: number, value: string, id?: number) => void;
}

export default function ReportScheduleInformationEdit(props: Props) {
  const { definedFieldValues, additionalFieldValues } = props;

  const fieldsSectionCss = "flex h-[47%]";
  const fieldsGridCss = "ml-7 grid grid-col-1 gap-y-5";
  const fieldsSectionTitleCss = "font-poppins font-semibold text-15px w-60";

  var sortedDefinedFieldValues = sortFieldsByRequirement(definedFieldValues);

  return (
    <div className="text-gray-9 h-full flex flex-col gap-5">
      <div className={fieldsSectionCss}>
        <div className={fieldsSectionTitleCss}>Defined schedule fields</div>
        <div className="w-full">
          <ScrollView
            direction="vertical"
            showScrollbar="always"
            key={definedFieldValues.length}
          >
            <div className={fieldsGridCss}>
              {sortedDefinedFieldValues.map((value, index) => (
                <ReportScheduleFieldEditor
                  key={index}
                  fieldValue={value}
                  handleDropdownSelectionChange={props.handleDropdownSelectionChange}
                  handleMetadataFieldValueChange={props.handleMetadataFieldValueChange} />
              ))}
            </div>
          </ScrollView>
        </div>
      </div>

      <hr className="border-light-gray" />

      <div className={fieldsSectionCss}>
        <div className={fieldsSectionTitleCss}>Additional schedule fields</div>
        <div className="w-full">
          <ScrollView
            direction="vertical"
            showScrollbar="always"
            key={additionalFieldValues.length}
          >
            <div className={fieldsGridCss}>
              {additionalFieldValues.map((value, index) => ( // additional fields are read-only
                <ReportScheduleField key={index} fieldValue={value} />
              ))}
            </div>
          </ScrollView>
        </div>
      </div>
    </div>
  );
}