import PromineoLabel from "./PromineoLabel"
import useTruncate from "features/common/hooks/TruncateHook";

interface Props {
  data: string | React.ReactElement,
  label?: string
}

export default function GridCellWithLabel(props: Props){
  const { contentRef, isTruncated } = useTruncate({ data: props.data });

  const tooltip = typeof props.data === 'string' && isTruncated ? props.data : undefined;

  return (
    <div className="flex items-center w-25">
      <div ref={contentRef} title={tooltip} className="truncate">{props.data}</div>
      {props.label && <PromineoLabel label={props.label} className="ml-2 promineo-bordered-label" />}
    </div>
  )
}