import GridCellWithLabel from "components/common/GridCellWithLabel";
import { displayGridLoadingPanel, hideLoadingPanel } from "components/common/LoadingPanel";
import ProcessingLabel from "components/common/ProcessingLabel";
import { Column } from "devextreme-react/data-grid";
import { Breadcrumb, BreadcrumbItem, IlapViewGrid } from "ilap.common.webcomponents.test";
import ReportScheduleResponse from "interfaces/response/ReportScheduleResponse";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { REPORT_SCHEDULES } from "shared/constants/RoutePathConstants";
import { BatchOperationCategory } from "shared/enums/BatchOperationType";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import { ReportScheduleStatusEnum } from "shared/enums/ReportScheduleStatus";
import { getCellValueOrDefault, getRoundedLocaleNumber } from "shared/utilities/DataGridUtility";
import { loadReportSchedulesAsync } from "store/action/ReportScheduleActions";
import { resetSelectedReportSchduleWithSchedules } from "store/slice/ReportScheduleSlice";
import { AppDispatch, RootState } from "store/store";
import IlapFilterGroup from "../../components/common/controls/IlapFilterGroup";
import BatchOperationModal from "./components/BatchOperationModal";
import ReportScheduleStatusCellComponent from "./components/StatusCellComponent";
import ReportSchedulesCreateModal from "./create/ReportSchedulesCreateModal";

enum BreadcrumbSelectionKeys {
  schedules = "report-schedules",
}

enum ReportScheduleFilterIndex {
  Open = 0,
  Closed = 1,
  All = 2
}

export default function ReportSchedulesGrid() {
  const dispatch = useDispatch<AppDispatch>();
  let navigate = useNavigate();

  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [modalMode, setModalMode] = useState<PromineoModalMode>(PromineoModalMode.Create);

  const [filterIndex, setFilterIndex] = useState<number>(ReportScheduleFilterIndex.Open);
  const [filteredReportSchedules, setFilteredReportSchedules] = useState<ReportScheduleResponse[]>([]);
  const [openReportSchedulesCount, setOpenReportSchedulesCount] = useState<number>(0);
  const [closedReportSchedulesCount, setClosedReportSchedulesCount] = useState<number>(0);
  const [allReportSchedulesCount, setAllReportSchedulesCount] = useState<number>(0);
  const [isBatchOperationModalVisible, setIsBatchOperationModalVisible] = useState<boolean>(false);
  const [processingReportScheduleId, setProcessingReportScheduleId] = useState<number>(0);
  const [processingReportScheduleTitle, setProcessingReportScheduleTitle] = useState<string>("");

  const reportSchedules = useSelector((state: RootState) => state.reportScheduleData.reportSchedules);

  useEffect(() => {
    dispatch(resetSelectedReportSchduleWithSchedules());

    if (reportSchedules?.length === 0) {
      displayGridLoadingPanel();
      dispatch(loadReportSchedulesAsync()).finally(hideLoadingPanel);
    }

    const openSchedules = reportSchedules.filter(rs => rs.status === ReportScheduleStatusEnum.Open);
    const closedSchedules = reportSchedules.filter(rs => rs.status === ReportScheduleStatusEnum.Closed);

    setFilteredReportSchedules(openSchedules); // open schedules are shown by default

    setAllReportSchedulesCount(reportSchedules.length);
    setOpenReportSchedulesCount(openSchedules.length);
    setClosedReportSchedulesCount(closedSchedules.length);
  }, [dispatch, reportSchedules?.length]);

  const handleReportScheduleOpen = (data: any) => {
    // navigate takes an Options object as second parameter. By default the value of "replace" property for options is "false".
    // Normally a call to navigate will push a new entry into the history stack so the user can click the back button to get back to the page. 
    // If we pass replace: true to navigate then the current entry in the history stack will be replaced with the new one.
    // source: https://reach.tech/router/api/navigate
    navigate(`${REPORT_SCHEDULES}/${data.id}/schedules`);
  };

  const breadCrumbItems: BreadcrumbItem[] = [
    {
      key: BreadcrumbSelectionKeys.schedules,
      text: "Report schedules",
    },
  ];
  const handleAddNewReportScheduleClicked = () => {
    setModalMode(PromineoModalMode.Create);
    setIsCreateModalVisible(true);
  };
  const handleCancelClick = () => {
    setIsCreateModalVisible(false);
  };

  const filters = useMemo(() => [
    {
      name: `Open report schedules (${openReportSchedulesCount})`,
    },
    {
      name: `Closed report schedules (${closedReportSchedulesCount})`,
    },
    {
      name: `All report schedules (${allReportSchedulesCount})`
    }
  ], [openReportSchedulesCount, closedReportSchedulesCount, allReportSchedulesCount]);

  const handleFilterSelectionChange = (filterIndex: number) => {
    setFilterIndex(filterIndex);

    switch (filterIndex) {
      case ReportScheduleFilterIndex.Open:
        setFilteredReportSchedules(reportSchedules.filter(rs => rs.status === ReportScheduleStatusEnum.Open));
        break;

      case ReportScheduleFilterIndex.Closed:
        setFilteredReportSchedules(reportSchedules.filter(rs => rs.status === ReportScheduleStatusEnum.Closed));
        break;

      default:
        setFilteredReportSchedules([...reportSchedules]);
    }
  }

  const showBatchOperationModal = () => setIsBatchOperationModalVisible(true);
  const hideBatchOperationModal = () => {
    setIsBatchOperationModalVisible(false);
    setProcessingReportScheduleId(0); // reset processing reportScheduleId
    setProcessingReportScheduleTitle("");
  }

  const handleProcessingClick = useCallback(() => {
    showBatchOperationModal();
  }, []);

  // helper function to make sure when clicked on "Processing" in a grid row, the onRowClick handler is not invoked
  // we are also setting the report schedule id
  const handleProcessingClickOnGridRow = useCallback((e: React.MouseEvent, reportSchedule: ReportScheduleResponse) => {
    e?.stopPropagation();
    setProcessingReportScheduleId(reportSchedule.id);
    setProcessingReportScheduleTitle(reportSchedule.title ?? "");
  }, []);

  const titleComponent = useCallback((data: any) => {
    const rs = data.data.data as ReportScheduleResponse;

    if (filterIndex !== ReportScheduleFilterIndex.All || rs.status !== ReportScheduleStatusEnum.Closed) {
      return (
        <div className="flex">
          <GridCellWithLabel data={rs.title ?? ""} />
          {rs.isProcessing && <div onClick={(e: React.MouseEvent) => handleProcessingClickOnGridRow(e, rs)}><ProcessingLabel onProcessingClick={handleProcessingClick} /></div>}
        </div>
      )
    }

    return <GridCellWithLabel data={rs.title ?? ""} label="Closed" />
  }, [filterIndex, handleProcessingClick, handleProcessingClickOnGridRow]);

  const toolbarConfig = useMemo(() => {
    return {
      dislpayToolbar: true,
      dislplaySearchPanel: true,
      displayAddNewButton: true,
      addNewButtonText: "New",
      onClickAddNew: handleAddNewReportScheduleClicked,
    }
  }, []);

  const onRowClick = useCallback((data: any) => {
    handleReportScheduleOpen(data.data);
  }, []);

  return (
    <div className="mx-14">
      <div className="mb-[21px] font-poppins text-[18px] text-dark-blue-1 font-semibold leading-normal">
        <Breadcrumb items={breadCrumbItems} />
      </div>
      <IlapViewGrid
        // 166px is picked by calculating the dimension 
        // of the elements above and margin below.
        // To make sure the grid always has at least a 32px margin
        // from the bottom of the viewport
        className="h-[calc(100vh-166px)]"
        dataSource={filteredReportSchedules}
        onRowClick={onRowClick}
        toolbarConfig={toolbarConfig}
        additionalWidget={<IlapFilterGroup items={filters} selectedIndex={filterIndex} onSelectionChange={handleFilterSelectionChange} />}
        columnMinWidth={50}
      >
        <Column
          caption={"Status"}
          allowSearch={false}
          cellComponent={ReportScheduleStatusCellComponent}
          alignment="left"
          width={70}
        />
        <Column
          caption={"Report schedule ID"}
          dataField="id"
          allowSearch={false}
          alignment="left"
          width={110}
        />
        <Column
          caption={"Title"}
          dataField="title"
          alignment="left"
          cellComponent={titleComponent}
        />
        <Column
          caption={"Schedule level"}
          dataField="reportScheduleType.planningLevelText"
          alignment="left"
          width={110}
        />
        <Column
          caption={"Schedule type"}
          dataField="reportScheduleType.title"
          alignment="left"
          minWidth={110}
        />
        <Column
          caption={"Points in time type"}
          dataField="pointsInTimeTypeText"
          alignment="left"
          minWidth={90}
        />
        <Column
          caption={"Date created"}
          dataField="created"
          alignment="left"
          dataType={"datetime"}
          allowSearch={false}
          minWidth={100}
          customizeText={getCellValueOrDefault}
        />
        <Column
          caption={"Last data submission"}
          dataField="lastSubmitted"
          alignment="left"
          allowSearch={false}
          dataType={"datetime"}
          minWidth={130}
          customizeText={getCellValueOrDefault}
        />
        <Column
          caption={"# All Live Act"}
          dataField={"activitiesCount"}
          alignment="left"
          width={80}
          customizeText={getRoundedLocaleNumber}
        />
        <Column
          caption={"# All BL Act"}
          dataField={"activitiesCountBaseline"}
          alignment="left"
          width={90}
          customizeText={getRoundedLocaleNumber}
        />
        <Column
          caption={"Total hours Live"}
          dataField={"totalWorkHours"}
          alignment="left"
          width={90}
          customizeText={getRoundedLocaleNumber}
        />
        <Column
          caption={"Total hours BL"}
          dataField={"totalWorkHoursBaseline"}
          alignment="left"
          width={90}
          customizeText={getRoundedLocaleNumber}
        />
        <Column
          caption={"Actual hours"}
          dataField={"actualWorkHoursAtCutoffCumulative"}
          alignment="left"
          width={90}
          customizeText={getRoundedLocaleNumber}
        />
        <Column
          caption={"Earned hours"}
          dataField={"earnedWorkHoursAtCutoffCumulative"}
          alignment="left"
          width={110}
          customizeText={getRoundedLocaleNumber}
        />
      </IlapViewGrid>

      {isCreateModalVisible && (
        <ReportSchedulesCreateModal
          mode={modalMode}
          onHideDialog={handleCancelClick}
        />
      )}
      {isBatchOperationModalVisible && processingReportScheduleId && 
        <BatchOperationModal 
          reportScheduleId={processingReportScheduleId}
          reportScheduleTitle={processingReportScheduleTitle}
          onHideDialog={hideBatchOperationModal}
          category={BatchOperationCategory.Processing}
        />
      }
    </div>
  );
}
