import { createSlice } from "@reduxjs/toolkit";
import MetadataField from "interfaces/response/MetadataField";
import MetadataFieldWithContentControlResponse from "interfaces/response/MetadataFieldWithContentControlResponse";
import { toastSuccess } from "shared/utilities/ToastUtility";
import {
  createMetadataFieldAsync,
  createMetadataFieldsAsync,
  deleteMetadataFieldAsync,
  importMetadataFieldsAsync,
  loadMetadataFieldsAsync,
  loadMetadataFieldsFromFlowAsync,
  loadSingleMetadataFieldAsync,
  updateMetadataFieldAsync,
} from "store/action/MetadataActions";

export interface MetadataState {
  metadataFields: MetadataField[];
  metadataFieldsWithValues: MetadataField[];
  metadataFieldsFromFlow: MetadataFieldWithContentControlResponse[];
}

const initialState: MetadataState = {
  metadataFields: [],
  metadataFieldsWithValues: [],
  metadataFieldsFromFlow: []
};

const updateMetadataFieldInState = (state: MetadataState, field: MetadataField) => {
  const indexOfUpdatedEntity = state.metadataFields.findIndex((m) => m.id === field.id);
  if (indexOfUpdatedEntity >= 0) {
    state.metadataFields[indexOfUpdatedEntity] = field;
  }
}

const updateMetadataFieldWithValuesInState = (state: MetadataState, field: MetadataField) => {
  const indexOfUpdatedEntity = state.metadataFieldsWithValues.findIndex((m) => m.id === field.id);
  if (indexOfUpdatedEntity >= 0) {
    state.metadataFieldsWithValues[indexOfUpdatedEntity] = field;
  }
}

export const metadataSlice = createSlice({
  name: "metadata",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      loadMetadataFieldsAsync.fulfilled,
      (state, { payload }: { payload: MetadataField[] }) => {
        state.metadataFields = payload;
      }
    );
    builder.addCase(
      loadMetadataFieldsFromFlowAsync.pending,
      (state) => {
        state.metadataFieldsFromFlow = [];
      }
    );
    builder.addCase(
      loadMetadataFieldsFromFlowAsync.fulfilled,
      (state, { payload }: { payload: MetadataFieldWithContentControlResponse[] }) => {
        state.metadataFieldsFromFlow = payload;
      }
    );
    builder.addCase(
      loadSingleMetadataFieldAsync.fulfilled,
      (state, { payload }: { payload: MetadataField }) => {
        state.metadataFieldsWithValues.push(payload);
      }
    );
    builder.addCase(
      updateMetadataFieldAsync.fulfilled,
      (state, { payload }: { payload: MetadataField }) => {
        updateMetadataFieldInState(state, payload);
        updateMetadataFieldWithValuesInState(state, payload);
        toastSuccess("Updated Field successfully.");
      }
    );
    builder.addCase(
      createMetadataFieldAsync.fulfilled,
      (state, { payload }: { payload: MetadataField }) => {
        state.metadataFields.push(payload);
        toastSuccess("Created Field successfully.");
      }
    );
    builder.addCase(
      createMetadataFieldsAsync.fulfilled,
      (state, { payload }: { payload: MetadataField[] }) => {
        payload.forEach(field => state.metadataFields.push(field));
        toastSuccess(`Created ${payload?.length ?? 0} Field(s) successfully.`);
      }
    );
    builder.addCase(
      importMetadataFieldsAsync.fulfilled,
      (state, { payload }: { payload: MetadataField[] }) => {
        payload.forEach(field => state.metadataFields.push(field));
        toastSuccess(`Imported ${payload?.length ?? 0} Field(s) successfully.`);
      }
    );
    builder.addCase(deleteMetadataFieldAsync.fulfilled, (state, action) => {
      const id = action.meta.arg;
      const entitiesWithoutDeletedOne = state.metadataFields.filter(
        (mf) => mf.id !== id
      );
      state.metadataFields = entitiesWithoutDeletedOne;
      toastSuccess("Deleted Field successfully.");
    });
  }
});

export default metadataSlice.reducer;