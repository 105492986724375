import { IPublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
import { getTokenRequest, loginRequest } from "./msalConfig";
import { toastError } from "shared/utilities/ToastUtility";
import UserAccountInfo from "interfaces/response/UserAccountInfo";

let msalInstance : IPublicClientApplication;

export const injectMsalInstance = (instance: IPublicClientApplication) => {
    msalInstance = instance;
};

export const getTokenAsync = async () => {
    const activeAccount = msalInstance.getActiveAccount();
    return await msalInstance
        .acquireTokenSilent(getTokenRequest(activeAccount))
        .then(response => {
            return response.accessToken;
        })
        .catch(async (error) => {
            if (error instanceof InteractionRequiredAuthError){
                const tokenPopupResponse = await msalInstance
                .acquireTokenPopup(getTokenRequest(activeAccount));

                return tokenPopupResponse.accessToken;
            } else {
                toastError();
                return null;
            }
        });
};

export const signIn = () => {
    return msalInstance.loginPopup(loginRequest);
};

export const signOut = () => {
    msalInstance.logoutPopup();
};

export const getCurrentAccountInfo = () => {
    return msalInstance.getActiveAccount() as UserAccountInfo;
};