import { useState } from "react";
import AccountPopover from "./AccountPopover";
import AvatarIcon from "components/common/icons/AvatarIcon";
import "./styles/NavigationIcons.css";

export default function Account() {
  const [isAccountPopoverVisible, setIsAccountPopoverVisible] = useState(false);

  const handleAccountPopoverHiding = () => {
    setIsAccountPopoverVisible(false);
  };

  const handleAccountMenuClick = () => {
    setIsAccountPopoverVisible(!isAccountPopoverVisible);
  };

  return (
    <div
      id="account-menu"
      className="select-none cursor-pointer navigation-icon"
      onClick={handleAccountMenuClick}
    >
      <div>
        <AvatarIcon className={"navigation-icon-svg"} />
      </div>
      <AccountPopover
        visible={isAccountPopoverVisible}
        onHiding={handleAccountPopoverHiding}
        target="#account-menu"
      />
    </div>
  );
}