interface Props {
  text: string;
  className?: string;
  additionalClass?: string;
  onClick: () => void;
}

export default function TextButton(props: Props) {
  const { className, text, additionalClass, onClick } = props;

  const customClassName = className ?? "ml-1 underline hover:text-blue-700 text-sm";
  return (
    <button className={`${customClassName} focus:outline-none ${additionalClass} ` } onClick={onClick}>
        {text}
    </button>
  );
}
              