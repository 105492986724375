import { IPopoverOptions, Popover } from "devextreme-react/popover";
import { ReactNode } from "react";
import "./styles/IlapPopover.css"

interface Props {
  children?: ReactNode;
}

export default function IlapPopover(props: Props & IPopoverOptions) {
  const { className, ...rest } = props;

  const wrapperAttr = {
    class: "ilap-popover"
  };

  return (
    <Popover wrapperAttr={wrapperAttr} {...rest} className={`${className}`}></Popover>
  );
}