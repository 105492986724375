import CloseIcon from "./icons/Close-icon";

interface Props {
  firstName: string;
  lastName: string;
  size?: "small" | "large";
  sizeClass?: string;
  displayCrossIcon?: boolean;
}

export default function Avatar(props: Props) {
  const { firstName, lastName, size, displayCrossIcon } = props;

  const getNameInitials = () => {
    let nameInitials = `${firstName && firstName.length ? firstName[0] : ""}${
      lastName && lastName.length ? lastName[0] : ""
    }`;
    return nameInitials.toUpperCase();
  };

  const getSizeSpecificClass = () => {
    return `${size && size === "small" ? "h-6 w-6 text-2xs" : "h-35px w-35px"}`;
  };

  return (
    <div
      className={`${getSizeSpecificClass()} rounded-full flex justify-center items-center bg-gray-300 text-gray-700 font-medium font-inter`}
    >
      {displayCrossIcon ? <CloseIcon /> : getNameInitials()}
    </div>
  );
}
