import { IlapColorVariant } from "shared/enums/IlapColorVariant";

interface Props {
  color: IlapColorVariant;
  tooltipText?: string;
  className?: string;
}

export default function Circle(props: Props) {
  return (
    <div title={props.tooltipText} className={`h-3 w-3 rounded-full bg-${props.color} ${props.className}`} ></div>
  );
}