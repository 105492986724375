import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "store/store";
import {
  getReportScheduleTypeAsync,
  createReportScheduleTypeAsync,
  updateReportScheduleTypeAsync,
  deleteReportScheduleTypeAsync,
  getReportScheduleTypesSlimAsync
} from "apis/ReportScheduleTypesApi";
import ReportScheduleType from "interfaces/response/ReportScheduleType";
import ReportScheduleTypeRequest from "interfaces/request/ReportScheduleTypeRequest";

export const loadReportScheduleTypesAsync = createAsyncThunk(
  "reportScheduleTypes/loadReportScheduleTypes",
  async () => {
    return await getReportScheduleTypesSlimAsync();
  }
);

export const loadSingleReportScheduleTypeAsync = createAsyncThunk<
  any,
  { reportScheduleTypeId: number },
  { state: RootState }
>("reportScheduleTypes/loadSingleReportScheduleType", async (request, thunkAPI) => {
  const state = thunkAPI.getState();

  const existingReportScheduleTypeWithMetadataIndex =
    state.reportScheduleTypeData.reportScheduleTypesWithMetadataFields.findIndex(
      (reportScheduleType: ReportScheduleType) => reportScheduleType.id === request.reportScheduleTypeId
    );

  if (existingReportScheduleTypeWithMetadataIndex > -1) {
    return state.reportScheduleTypeData.reportScheduleTypesWithMetadataFields[existingReportScheduleTypeWithMetadataIndex];
  } else {
    return await getReportScheduleTypeAsync(request.reportScheduleTypeId);
  }
});

export const createReportScheduleType = createAsyncThunk(
  "reportScheduleTypes/createReportScheduleType",
  async (reportScheduleType: ReportScheduleTypeRequest) => {
    return await createReportScheduleTypeAsync(reportScheduleType);
  }
);

export const updateReportScheduleType = createAsyncThunk<
  ReportScheduleType,
  { reportScheduleTypeId: number; reportScheduleTypeRequest: ReportScheduleTypeRequest }
>(
  "reportScheduleTypes/updateReportScheduleType", async (request) => {
    return await updateReportScheduleTypeAsync(request.reportScheduleTypeId, request.reportScheduleTypeRequest);
});

export const deleteReportScheduleType = createAsyncThunk<any, {reportScheduleTypeId: number}, { state: RootState }>(
  "reportScheduleTypes/deleteReportScheduleType", 
  async (request) => {
    await deleteReportScheduleTypeAsync(request.reportScheduleTypeId);
  }
);
