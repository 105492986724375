import { IlapButton, IlapButtonType } from "ilap.common.webcomponents.test";
import InfoIcon from "./icons/InfoIcon";

interface Props {
  text: string;
  action?: {
    text: string;
    onClick: () => void;
  };  
}

export default function InformationBar(props: Props) {
  return (
    <div className="bg-blue50 rounded-lg h-[48px] flex items-center min-w-[650px]">
      <div className="flex justify-between px-4 w-full">
        <div className="flex gap-x-4 items-center">
          <InfoIcon />
          <span className="text-sm font-normal font-inter">{props.text}</span>
        </div>
        <div>
          <div>
            {!!props.action && (
              <IlapButton
                variant={IlapButtonType.Primary}
                text={props.action.text}
                onClick={props.action.onClick}
                className="border-none"
              />
            )}
          </div>          
        </div>
      </div>
    </div>
  );
}
