import { DateBox } from "devextreme-react";
import { IDateBoxOptions } from "devextreme-react/date-box";
import { useRef } from "react";
import "./styles/IlapDateBox.css";

interface Props {
  minimumDate?: Date;
  css?: string;
}

export default function IlapDateBox(props: Props & IDateBoxOptions) {
  const options = useRef({
    wrapperAttr: {
      class: "ilap-date-box-popup"
    }
  });

  return (
    <DateBox 
      dropDownOptions={options.current} 
      min={props.minimumDate} 
      className={`ilap-date-box ${props.css}`}
      type="datetime" 
      {...props} />
  )
}