import { unwrapResult } from "@reduxjs/toolkit";
import IlapDateBox from "components/common/controls/IlapDateBox";
import LabelWithContent from "components/common/LabelWithContent";
import { displayLoadingPanel, hideLoadingPanel } from "components/common/LoadingPanel";
import { Validator } from "devextreme-react";
import { RequiredRule } from "devextreme-react/data-grid";
import { IlapButton, IlapButtonType, IlapPopup, IlapTextBox } from "ilap.common.webcomponents.test";
import { ServiceTokenCreateRequest } from "interfaces/request/ServiceTokenCreateRequest";
import ServiceTokenCreateResponse from "interfaces/response/ServiceTokenCreateResponse";
import { useMemo, useRef, useState } from "react";
import { toastError } from "shared/utilities/ToastUtility";
import { createTokenAsync } from "store/action/ServiceTokenActions";
import { useAppDispatch } from "store/hooks";

interface Props {
  onTokenCreateSuccess: (token: ServiceTokenCreateResponse) => void;
  onHideDialog: () => void;
}

export default function ServiceTokenCreateModal(props: Props) {
  const [tokenTitle, setTokenTitle] = useState<string>("");
  const [expirationTime, setExpirationDate] = useState<Date>();
  const [isValidationEnabled, setIsValidationEnabled] = useState<boolean>(false);

  const dateValidatorRef = useRef<Validator>(null);

  const dispatch = useAppDispatch();

  const handleTitleChange = (value: string) => {
    setTokenTitle(value);
  }

  const handleExpirationDateChange = (value: Date) => {
    setExpirationDate(value);
  }

  const minimumExpirationDate = useMemo(() => {
    const date = new Date();
    date.setSeconds(0, 0); // Reset seconds and milliseconds to 0 (to avoid time range validation issue)
    return date;
  }, []);

  const isTitleValid = useMemo(() => !isValidationEnabled || tokenTitle.trim().length > 0, [isValidationEnabled, tokenTitle]);

  const handleGenerateToken = () => {
    const isDateValid = dateValidatorRef?.current?.instance?.validate()?.isValid && expirationTime;

    if (!tokenTitle.trim().length || !isDateValid) {
      setIsValidationEnabled(true);
      return;
    }

    // check if expiration date is in the future
    if (expirationTime && expirationTime < new Date()) {
      toastError("Expiration date must be in the future.");
      return;
    }

    const request: ServiceTokenCreateRequest = {
      name: tokenTitle,
      scope: 2, // read_write
      expiry: expirationTime // convert to UTC before sending
    };

    displayLoadingPanel();
    dispatch(createTokenAsync(request))
      .then(unwrapResult)
      .then((response: ServiceTokenCreateResponse) => {
        props.onTokenCreateSuccess(response);
        props.onHideDialog();
      })
      .then(hideLoadingPanel);
  }

  return (
    <IlapPopup
      height={"427px"}
      width={"728px"}
      visible={true}
    >
      <div className="h-full flex flex-col justify-between font-poppins">
        <header className="flex flex-col gap-4">
          <div className="font-semibold text-18px leading-[27px] text-gray-9">Generate new token</div>
          <hr className="border-lighter-gray" />
          <div className="font-normal text-base text-gray-9">The access token allows external systems to securely populate data in ILAP Analytics. Note that the token will only be displayed once at the time of creation, so be sure to copy and store it securely.</div>
        </header>

        <hr className="border-lighter-gray" />

        <section className="w-[328px]">
          <LabelWithContent
            label="Title"
            infoText="Give a descriptive name for the token, to help you keep track of it later. 1-100 characters."
            content={
              <IlapTextBox
                value={tokenTitle}
                placeholder="Enter a name for the token"
                validationStatus={isTitleValid ? "valid" : "invalid"}
                onChange={({ event }: any) => handleTitleChange(event.currentTarget.value)}
              />
            }
          />
          <LabelWithContent 
            label="Expiration date" 
            infoText="The token is valid until its expiration date."
            content={
            <IlapDateBox
              value={expirationTime}
              minimumDate={minimumExpirationDate}
              placeholder="Select expiration date & time"
              validationMessageMode="always"
              onValueChanged={({ value }) => handleExpirationDateChange(value)}
            >
              <Validator ref={dateValidatorRef}>
                <RequiredRule message="" />
              </Validator>
            </IlapDateBox>
          }
          />
        </section>

        <hr className="border-lighter-gray" />

        <footer className="flex flex-col justify-end">
          <div className="flex justify-end gap-4">
            <IlapButton variant={IlapButtonType.Secondary} className="px-4 rounded-lg font-medium" onClick={props.onHideDialog}>Cancel</IlapButton>
            <IlapButton className="px-4 font-medium" onClick={handleGenerateToken}>Generate</IlapButton>
          </div>
        </footer>
      </div>
    </IlapPopup>
  );
}