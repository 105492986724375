import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createMetadataFieldApiAsync,
  deleteMetadataFieldApiAsync,
  getMetadataFieldAsync,
  updateMetadataFieldApiAsync,
  getMetadataFieldsFromFlowAsync,
  createMetadataFieldsApiAsync,
  getMetadataFieldsSlimAsync,
} from "apis/MetadataApi";
import MetadataField from "interfaces/response/MetadataField";
import { RootState } from "store/store";

export const loadMetadataFieldsAsync = createAsyncThunk(
  "metadata/loadMetadataFields",
  async () => {
    return await getMetadataFieldsSlimAsync();
  }
);

export const loadSingleMetadataFieldAsync = createAsyncThunk
  <
    any,
    { metadataFieldId: number },
    { state: RootState }
  >("metadata/loadSingleMetadataField", async (request, thunkApi) => {
    const state = thunkApi.getState();

    const existingMetadataFieldIndex = state.metadataFieldData.metadataFieldsWithValues.findIndex((field: MetadataField) => field.id === request.metadataFieldId);

    if (existingMetadataFieldIndex > -1) {
      return state.metadataFieldData.metadataFieldsWithValues[existingMetadataFieldIndex];
    } else {
      return await getMetadataFieldAsync(request.metadataFieldId);
    }
  });

export const deleteMetadataFieldAsync = createAsyncThunk(
  "metadata/deleteMetadataFields",
  async (id: number) => {
    await deleteMetadataFieldApiAsync(id);
  }
);

export const updateMetadataFieldAsync = createAsyncThunk(
  "metadata/updateMetadataField",
  async (metadata: MetadataField, { rejectWithValue }) => {
    try {
      return await updateMetadataFieldApiAsync(metadata);
    }
    catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createMetadataFieldAsync = createAsyncThunk(
  "metadata/createMetadataField",
  async (metadata: MetadataField, { rejectWithValue }) => {
    try {
      return await createMetadataFieldApiAsync(metadata);
    }
    catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const loadMetadataFieldsFromFlowAsync = createAsyncThunk(
  "metadata/loadMetadataFieldsFromFlow",
  async () => {
    return await getMetadataFieldsFromFlowAsync();
  }
);

export const createMetadataFieldsAsync = createAsyncThunk(
  "metadata/createMetadataFields",
  async (fields: MetadataField[], { rejectWithValue }) => {
    try {
      return await createMetadataFieldsApiAsync(fields);
    }
    catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const importMetadataFieldsAsync = createAsyncThunk(
  "metadata/importMetadataFields",
  async (fields: MetadataField[], { rejectWithValue }) => {
    try {
      return await createMetadataFieldsApiAsync(fields);
    }
    catch (error) {
      return rejectWithValue(error);
    }
  }
);