import { IlapSvgProps } from "interfaces/common/IlapSvgProps";

export default function HourglassIcon(props: IlapSvgProps) {
  return (
    <svg className={props.className} width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5 21H13.5V17.625C13.5 16.3875 13.0594 15.3281 12.1781 14.4469C11.2969 13.5656 10.2375 13.125 9 13.125C7.7625 13.125 6.70313 13.5656 5.82188 14.4469C4.94063 15.3281 4.5 16.3875 4.5 17.625V21ZM9 10.875C10.2375 10.875 11.2969 10.4344 12.1781 9.55312C13.0594 8.67187 13.5 7.6125 13.5 6.375V3H4.5V6.375C4.5 7.6125 4.94063 8.67187 5.82188 9.55312C6.70313 10.4344 7.7625 10.875 9 10.875ZM0 23.25V21H2.25V17.625C2.25 16.4812 2.51737 15.4076 3.05212 14.4041C3.58612 13.4014 4.33125 12.6 5.2875 12C4.33125 11.4 3.58612 10.5983 3.05212 9.59475C2.51737 8.592 2.25 7.51875 2.25 6.375V3H0V0.75H18V3H15.75V6.375C15.75 7.51875 15.483 8.592 14.949 9.59475C14.4143 10.5983 13.6688 11.4 12.7125 12C13.6688 12.6 14.4143 13.4014 14.949 14.4041C15.483 15.4076 15.75 16.4812 15.75 17.625V21H18V23.25H0Z"
        fill={props.fill ?? "#434343"}
      />
    </svg>

  )
}