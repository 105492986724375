import PrivateRouteGuard from "components/route-guards/PrivateRouteGuard";
import PublicOnlyRouteGuard from "components/route-guards/PublicOnlyRouteGuard";
import Admin from "features/admin";
import LandingPage from "features/landing/LandingPage";
import Fields from "features/metadata";
import FieldEdit from "features/metadata/edit/FieldEdit";
import FieldView from "features/metadata/view/FieldView";
import ReportSchedules from "features/report-schedules";
import ReportScheduleRevisionGrid from "features/report-schedules/ReportScheduleRevisionGrid";
import ReportSchedulesEditSchedule from "features/report-schedules/ReportSchedulesEditSchedule";
import ReportScheduleTypes from "features/schedule-types";
import ScheduleTypeEdit from "features/schedule-types/edit/ScheduleTypeEdit";
import ScheduleTypeView from "features/schedule-types/view/ScheduleTypeView";
import { Navigate, RouteObject } from "react-router-dom";
import * as path from "shared/constants/RoutePathConstants";

export const AppRoutes: RouteObject[] = [
  {
    path: path.LANDING,
    element: (
      <PublicOnlyRouteGuard>
        <LandingPage />
      </PublicOnlyRouteGuard>
    )
  },
  {
    path: path.REPORT_SCHEDULES,
    element: (
      <PrivateRouteGuard>
        <ReportSchedules />
      </PrivateRouteGuard>
    )
  },
  {
    path: path.REPORT_SCHEDULE_REVISIONS,
    element: (
      <PrivateRouteGuard>
        <ReportScheduleRevisionGrid />
      </PrivateRouteGuard>
    )
  },
  {
    path: path.REPORT_SCHEDULES_EDIT_SCHEDULE,
    element: (
      <PrivateRouteGuard>
        <ReportSchedulesEditSchedule />
      </PrivateRouteGuard>
    )
  },
  {
    path: path.SCHEDULE_TYPES,
    element: (
      <PrivateRouteGuard>
        <ReportScheduleTypes />
      </PrivateRouteGuard>
    )
  },
  {
    path: path.SCHEDULE_TYPE_SINGLE_VIEW,
    element: (
      <PrivateRouteGuard>
        <ScheduleTypeView />
      </PrivateRouteGuard>
    )
  },
  {
    path: path.SCHEDULE_TYPE_SINGLE_EDIT,
    element: (
      <PrivateRouteGuard>
        <ScheduleTypeEdit />
      </PrivateRouteGuard>
    )
  },
  {
    path: path.FIELDS,
    element: (
      <PrivateRouteGuard>
        <Fields />
      </PrivateRouteGuard>
    )
  },
  {
    path: path.FIELD_SINGLE_VIEW,
    element: (
      <PrivateRouteGuard>
        <FieldView />
      </PrivateRouteGuard>
    )
  },
  {
    path: path.FIELD_SINGLE_EDIT,
    element: (
      <PrivateRouteGuard>
        <FieldEdit />
      </PrivateRouteGuard>
    )
  },
  {
    path: path.ADMIN,
    element: (
      <PrivateRouteGuard>
        <Admin />
      </PrivateRouteGuard>
    )
  },
  {
    path: '*',
    element: <Navigate replace to="/" />
  }
];